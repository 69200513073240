import React, {useState} from 'react';
//import PropTypes from 'prop-types';
import {
    Modal,
} from "react-bootstrap";

import { TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';


const useStyles1 = styled(theme => ({
    button: {
        margin: theme.spacing(1)
    },
    input: {
        display: "none"
    }
}));


const useStyles2 = styled(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    dense: {
        marginTop: theme.spacing(2)
    },
    menu: {
        width: 200
    }
}));

const CreateJobTitle = ({ onSubmit, show, onClose }) => {

    const classes1 = useStyles1();
    const classes2 = useStyles2();

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    let handleTitleChange = (e) => {
        console.log('title change')
        setTitle(e.target.value)
    }

    let handleDescriptionChange = (e) => {
        console.log('description change')
        setDescription(e.target.value)
    }

    let submitNewJob = () => {
        onSubmit(title, description)
    }


    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create New Job Title
          </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div>

                    <TextField
                        id="outlined-name"
                        label="Job Title"
                        className={classes2.textField}
                        value = {title}
                        onChange = {handleTitleChange}
                        // value={values2.name}
                        // onChange={handleChange2("name")}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        id="outlined-multiline-flexible"
                        label="Job Description"
                        value = {description}
                        onChange = {handleDescriptionChange}
                        multiline
                        rowsMax="4"
                        // value={values2.multiline}
                        // onChange={handleChange2("multiline")}
                        className={classes2.textField}
                        margin="normal"
                        variant="outlined"
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes1.button}
                        onClick = {submitNewJob}
                    >
                        Create Job Title
                    </Button>


                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>


        </Modal >

    )
}

CreateJobTitle.propTypes = {

}

export default CreateJobTitle
