import {get, post} from 'api/utils/requests'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})

//const userData = ls.get('ud') ? JSON.parse(ls.get('ud')) : ''
const company = ls.get('company')

const PPL_URL = `internal/system/people/`
const PPL_CREATE_URL = `internal/system/create/people`
const PPL_PERSONNEL_GET = `internal/personnel/`

export const getAccounts = async () => {
  let _url = `${PPL_URL}${company}`

  // if (company && JSON.parse(company).company.length > 0) {
  //   _url = `${PPL_URL}${JSON.parse(company).company[0]}`
  // }

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
      }
    })
    .catch(err => console.error(err))
}

export const postPeopleAccounts = async data => {
  return await post(data, PPL_CREATE_URL)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        if (r.status === 423) {
          return r
        } else {
          return false
        }
      }
    })
    .catch(err => console.error(err))
}
