import React, {useState, useEffect} from 'react'
import {getAccounts} from 'api/private/people'
import Table from 'react-bootstrap-table-next'
import PaginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit'
import AddNewPeopleForm from './AddNewPeopleForm'
import { Button } from '@mui/material';

const {SearchBar} = Search
var dayjs = require('dayjs')
const PeopleListPage = props => {
  const [show, setShow] = useState(false)
  const [data, setData] = useState({
    people: [],
    loading: false,
    selectedAccess: '',
    sortedData: [],
  })
  const [accessType] = useState([
    'Employee',
    'Administrator',
    'Training Manager',
    'Recruitment Head I',
    'Recruitment Head II',
    'Team Supervisor',
  ])

  const accessLevel = no => {
    switch (no) {
      case '2':
        return 'Employee'
      case '3':
        return 'Administrator'
      case '4':
        return 'Recruitment Head II'
      case '5':
        return 'Team Supervisor'
      case '6':
        return 'Recruitment Head I'
      default:
        return
    }
  }

  const capitalizeName = str => {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

  const listAccounts = async () => {
    const r = await getAccounts()
    setData({...data, loading: true})
    if (r.length > 0) {
      let dt = []
      r.map(d =>
        dt.push({
          id: d.id,
          name: capitalizeName(`${d.first_name} ${d.last_name}`.toLowerCase()),
          email: d.email,
          company: d.company,
          position: accessLevel(d.user_level),
          createdAt: dayjs(d.date_created).format('MMMM-DD-YYYY h:m:s a'),
        }),
      )
      setData({...data, people: dt, loading: false})
    }
  }

  useEffect(() => {
    listAccounts()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleAccessChange = async e => {
    const v = e.target.value
    const r = data.people
    setData({...data, loading: true})

    if (r.length > 0) {
      let sorted = await r.filter(function(el) {
        return v === el.position
      })

      if (v === '') {
        sorted = data.people
      }
      return setData({...data, sortedData: sorted, loading: false, selectedAccess: v})
    }
  }

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ]

  const columns = [
    {
      dataField: 'id',
      text: '-',
      sort: true,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'position',
      text: 'Position',
      sort: true,
    },
    {
      dataField: 'company',
      text: 'Company',
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Date Posted',
      sort: true,
    },
  ]

  return (
    <div>
      <div>
        <ToolkitProvider
          keyField="id"
          data={data.sortedData.length > 0 ? data.sortedData : data.selectedAccess ? [] : data.people}
          columns={columns}
          search
          defaultSorted={defaultSorted}
          pagination={PaginationFactory()}
        >
          {props => (
            <div>
              <SearchBar {...props.searchProps} />
              <div className="float-right text-right w-75">
                <select
                  className="form-control mx-2 w-25 d-inline-block"
                  name="type"
                  value={data.selectedAccess.length !== 0 ? data.selectedAccess : ''}
                  placeholder="Filter by Access level"
                  onChange={e => handleAccessChange(e)}
                >
                  <option value="">All</option>
                  {accessType && accessType.length > 0
                    ? accessType.map((d, k) => {
                        return (
                          <option key={k} value={d}>
                            {d}
                          </option>
                        )
                      })
                    : ''}
                </select>

                <Button variant="contained" className="w-25 d-inline-block" color="secondary" onClick={handleShow}>
                  Add new people
                </Button>
              </div>

              <hr />
              <Table
                defaultSorted={defaultSorted}
                className="w-100"
                pagination={PaginationFactory()}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <AddNewPeopleForm show={show} onClose={handleClose} />
    </div>
  )
}

PeopleListPage.propTypes = {}

export {PeopleListPage}
