import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../_metronic/_helpers'

const HeaderBannerChunk = ({icons, iconStyle, title, count}) => {
  return (
    <div className="d-flex align-items-center flex-grow-1 m-5 w-100">
      <div className="d-flex m-4">
        <span className={iconStyle}>
          <SVG src={toAbsoluteUrl(icons)} />
        </span>
      </div>
      <div className="d-flex flex-column align-items-end py-2 w-75">
        <div className="row">
          <span className="text-muted">{title}</span>
        </div>
        <div className="row mt-2">
          <span className="font-weight-bold">{count}</span>
        </div>
      </div>
    </div>
  )
}

HeaderBannerChunk.propTypes = {}

export default HeaderBannerChunk
