import React from 'react'
import {Box} from '@mui/material'
import {styled} from '@mui/material/styles'
// Components
import NewsEventsHero from './newsHero'
import NewsCalendar from './newsCalendar';
import { Helmet } from 'react-helmet';

const ContentStyle = styled('div')(({theme}) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}))

function MetaTags({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

export default function NewsPage({ metaData, newsPageData }) {
  return (
    <>
    <MetaTags title={metaData.title} description={metaData.description} keywords={metaData.keywords} />
    <Box id="move_top">
      <ContentStyle>
        <NewsEventsHero data={newsPageData?.newsHero} />
        <NewsCalendar data={newsPageData?.newsCalendar} />
      </ContentStyle>
    </Box>
    </>
  )
}