import React from 'react'
import 'style/_review.scss'

const ResumeEducation = ({dt}) => {
  return (
    <div className="form-groSp">
      <h2>Education Background</h2>
      <hr />
      <table>
        <tbody>
          <tr>
            <td width="200">Elementary:</td>
            <td className="font-weight-bold text-uppercase">{dt.elem}</td>
            <td className="pl-5" width="200">
              Year Completed:
            </td>
            <td className="font-weight-bold text-uppercase">{dt.elem_completed}</td>
          </tr>
          <tr>
            <td width="400">Elementary Academic Honors/ Award Received:</td>
            <td className="font-weight-bold text-uppercase">{dt.elem_awards}</td>
          </tr>
          <tr>
            <td width="200">High School:</td>
            <td className="font-weight-bold text-uppercase">{dt.hs}</td>
            <td className="pl-5" width="200">
              Year Completed:
            </td>
            <td className="font-weight-bold text-uppercase">{dt.hs_completed}</td>
          </tr>
          <tr>
            <td width="400">Highschool Academic Honors/ Award Received:</td>
            <td className="font-weight-bold text-uppercase">{dt.hs_awards}</td>
          </tr>
          {dt.college_isgrad ? (
            <>
              <tr>
                <td width="200">College:</td>
                <td className="font-weight-bold">{dt.college}</td>
                <td className="pl-5" width="200">
                  Course / Degree:
                </td>
                <td className="font-weight-bold text-uppercase">{dt.college_course}</td>
              </tr>
              <tr>
                <td width="200">Did you graduate?:</td>
                <td className="font-weight-bold text-uppercase">
                  {dt.college_isgrad.toLowerCase() === 'no' ? `No` : `Yes`}
                </td>
                <td className="pl-5" width="200">
                  Year Completed:
                </td>
                <td className="font-weight-bold text-uppercase">{dt.college_completed}</td>
              </tr>
              <tr>
                <td width="200">College Academic Honors/ Award Received::</td>
                <td className="font-weight-bold text-uppercase">{dt.college_awards}</td>
              </tr>
            </>
          ) : (
            <tr></tr>
          )}
          {dt.vocation_isChecked ? (
            <>
              <tr>
                <td width="200">Vocational:</td>
                <td className="font-weight-bold">{dt.vocation}</td>
                <td className="pl-5" width="200">
                  Course / Degree:
                </td>
                <td className="font-weight-bold text-uppercase">{dt.vocation_course}</td>
              </tr>
              <tr>
                <td className="pl-5" width="200">
                  Year Completed:
                </td>
                <td className="font-weight-bold text-uppercase">{dt.vocation_completed}</td>
              </tr>
            </>
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ResumeEducation
