import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';
import ImageUploadDialog from '../../../../image/ImageWithDialog';
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog';
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper';
import defaultBackgroundImage from '../../assets/layout1-careers.webp'; // Import default image
import  ImageWithFallback  from 'components/crm/image/ImageWithFallback';
import SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: 'aes' });

const HeaderContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
}));

const Title = styled(motion(Typography))(({ theme }) => ({
  position: 'relative',
  color: '#ffffff',
  fontWeight: 700,
  zIndex: 1,
  fontSize: '2.5rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '4rem',
  },
}));

export default function CareerHero({ data }) {
  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState([]);
  const [openDialogIndex, setOpenDialogIndex] = useState(null);
  const [dialogValue, setDialogValue] = useState('');

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [parentHover, setParentHover] = useState(false);
  const [childHover, setChildHover] = useState(null);

  useEffect(() => {
    const storedText = ls.get('crmCareerHeroText');
    const heroTitle = storedText || data?.title || 'Career Opportunities'; // Fallback to prop or default text

    const storedImage = ls.get('crmCareerHeroImage');
    const parsedImage = storedImage || data?.backgroundImage || defaultBackgroundImage; // Resolve the image path
    setTextFields([heroTitle]);
    setImageUrl([parsedImage]);
  }, [data]);

  const { isEditMode } = context || {};

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]);
    setOpenDialogIndex(index);
    setSelectedImageIndex(null);
    setImageDialogOpen(false); // Open dialog on image click
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);

    if (openDialogIndex === 0) {
      ls.set('crmCareerHeroText', newValues[0]);
    }

  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
  };

  const handleImageUpload = (newImageUrl) => {
    const updatedImages = [...imageUrl];
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrl(updatedImages);
    ls.set('crmCareerHeroImage', newImageUrl); // Save the new image in local storage
  };

  return (
    <>
      <EditTextDialog
        open={openDialogIndex !== null}
        value={dialogValue}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onChange={setDialogValue}
      />
      <ImageUploadDialog
        open={imageDialogOpen}
        onClose={handleImageDialogClose}
        onUpload={handleImageUpload}
        currentImage={imageUrl[selectedImageIndex]}
      />
      <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(0)} hover={parentHover && !childHover} onMouseEnter={() => setParentHover(true)} onMouseLeave={() => setParentHover(false)} editType={'image'}>
        <HeaderContainer>
          <ImageWithFallback
            src={imageUrl[0]}
            alt="Career Hero"
            fallbackSrc={defaultBackgroundImage}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)} hover={childHover === 1} onMouseEnter={() => setChildHover(1)} onMouseLeave={() => setChildHover(null)} editType={'text'}>
            <Title
              variant="h1"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.55 }}              
            >
              {textFields[0]}
            </Title>
          </EditableItemWrapper>
        </HeaderContainer>
      </EditableItemWrapper>
    </>
  );
}
