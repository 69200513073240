import {get, post, getStarjobs, updateStarbjobs} from 'api/utils/requests'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})

//const userData = ls.get('ud') ? JSON.parse(ls.get('ud')) : ''

// if(company === ''){
//   company = '7star'
// }

//onsole.log("Tried to load " + company)

const APP_SORTED_URL = `internal/record/sort/`
const APP_SEARCH_URL = `internal/record/search/`
const APP_SPECIFIC_URL = `internal/record/specific/`
const APP_SPECIFIC_REVIEW_REC_URL = `internal/record/specific/reviews/`
const APP_UPDATE_STORE = `internal/record/update_store/`
const APP_STATUS_URL = `internal/record/`
const LOGS_APP_URL = `internal/application/logs/`
const EXAMS_APP_URL = `internal/exams/logs/`
const EXAMS_APP_URL_POST = `internal/exams/email/`
const APP_RECORD_UPDATE = `internal/record/update/`
const APP_PHONE_UPDATE = `internal/phone/update/`
const APP_GOV_UPDATE = `internal/gov/update/`
const SPECIFIC_APP = `internal/applicant/specific/`


export const getSearchApplicant = async year => {
  let company = ls.get('company')

  let _url = `${APP_SEARCH_URL}/${company}/${year}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getSortedApplicants = async (_days, _type) => {
  let company = ls.get('company')

  let _url = `${APP_SORTED_URL}${_type}/${company}/${_days}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getStatusApplicants = async _status => {
  let company = ls.get('company')
  let _url = `${APP_STATUS_URL}${company}/${_status}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getSpecificApplicants = async (_id, _company) => {
  let _url = `${APP_SPECIFIC_URL}${_company}/${_id}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getSpecificApplicantReview = async (_id, _company) => {
  let _url = `${APP_SPECIFIC_REVIEW_REC_URL}${_company}/${_id}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

/* Logs */

export const getApplicantsLogs = async () => {
  let company = ls.get('company')
  let _url = `${LOGS_APP_URL}${company}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getApplicantsExams = async () => {
  let company = ls.get('company')
  let _url = `${EXAMS_APP_URL}${company}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const postApplicantExams = async _body => {
  let _url = `${EXAMS_APP_URL_POST}`
  return await post(_body, _url)
    .then(r => {
      if (r.status === 200) {
        return r.status
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const updateApplicantStore = async (_id, _company, _store) => {
  let _url = `${APP_UPDATE_STORE}${_company}/${_id}/${_store}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.message
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const updateApplicantProfile = async (_id, _data) => {
  let _url = `${APP_RECORD_UPDATE}/${_id}`

  return await post(_data, _url)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const updateApplicationPhone = async (_id, _data) => {
  let _url = `${APP_PHONE_UPDATE}/${_id}`

  return await post(_data, _url)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const updateApplicationGov = async (_id, _data) => {
  let _url = `${APP_GOV_UPDATE}/${_id}`

  return await post(_data, _url)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getSpecificApplicant = async (_name) => {
  let company = ls.get('company')

  let _url = `${SPECIFIC_APP}`
  const _data = {
    name: _name,
    company: company
  }
  return await post(_data, _url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getFailedApplicants = async () =>{
  let company = ls.get('company')
  try{
    const resultData = await getStarjobs(company)
    if(resultData){
      return resultData
    }
    return "No data in failed applicant"
  }catch(error){
    console.log(error)
    return error
  }
}

export const updateFailedApplicantStatus = async (id, status) =>{

  try{
    const resultData = await updateStarbjobs(id, status)
    if(resultData){
      return resultData
    }
    return "Update status not successful"
  }catch(error){
    console.log(error)
    return error
  }
}


