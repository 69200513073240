import React, { useEffect, useState, useContext, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import SecureLS from 'secure-ls';
import { Helmet } from 'react-helmet';
// components
import MainHeader from './MainHeader';
import MainFooter from './MainFooter';
import LandingPage from '../pages/home';
import CareerPage from '../pages/careers';
import AboutPage from '../pages/aboutus';
import NewsPage from '../pages/newsandevents';
import ContactPage from '../pages/contactus';
import ApplyPage from '../pages/apply';
import LoginPage from '../pages/login';
import { Box } from '@mui/material'
import { PageContext } from 'context/PageContext';

import { getGATracker } from 'api/private/crm';
import { set } from 'lodash';

// ----------------------------------------------------------------------

const ls = new SecureLS({ encodingType: 'aes' });

function MetaTags({ charset, viewport, trackingCode }) {
  console.log('Tracking Code Meta =>', trackingCode)
  return (
    <Helmet>
      <meta charset={charset}/>
      <meta name="viewport" content={viewport} />
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${trackingCode}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${trackingCode}');
        `}
      </script>
    </Helmet>
  );
}

export default function MainLayout({ metaTagsData, headerData, footerData, homePageData, careerPageData, aboutPageData, contactPageData, newsPageData, applyPageData, published, handleNavUpdate }) {
  const context = useContext(PageContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [trackingCode, setTrackingCode] = useState('');
  const [metaData, setMetaData] = useState({
    charset: 'utf-8',
    viewport: 'width=device-width, initial-scale=1.0',
    site: {
      title: '',
      description: '',
      keywords: ''
    },
    home: {
      title: '',
      description: '',
      keywords: ''
    },
    about: {
      title: '',
      description: '',
      keywords: ''
    },
    career: {
      title: '',
      description: '',
      keywords: ''
    },
    news: {
      title: '',
      description: '',
      keywords: ''
    },
    contact: {
      title: '',
      description: '',
      keywords: ''
    },
    apply: {
      title: '',
      description: '',
      keywords: ''
    },
    login: {
      title: '',
      description: '',
      keywords: ''
    },
  });

  const history = useHistory();

  const { selectedPage } = context || {}

  useEffect(() => {
    setCurrentPage(parseInt(selectedPage, 10));
  }, [selectedPage]);

  useEffect(() => {
    const localResponseMeta = ls.get('crmPageMetaTags');
    const getTrackerData = async () => {
      const hostname = window.location.hostname;
      const domainParts = hostname.split('.');

      if (domainParts.length >= 2) {
        const subdomain = domainParts[0];
        const data = await getGATracker(subdomain);
        if (data) {
          console.log("DATA OF TRACKING CODE =>", data.body.googleAnalyticsTracker)
          setTrackingCode(data.body.googleAnalyticsTracker); 
        }
      } else {
        console.log('No subdomain');
      }
    };
 
    if (localResponseMeta) {
      setMetaData(localResponseMeta);
    } else if (metaTagsData) {
      setMetaData(metaTagsData);
    }
    
    getTrackerData();
  }, []);

  const mapPageTitle = useCallback(() => {
    switch (currentPage) {
      case 1:
        return <LandingPage metaData={metaData.home} homePageData={homePageData} />;
      case 2:
        return <AboutPage metaData={metaData.about} aboutPageData={aboutPageData} />;
      case 3:
        return <CareerPage metaData={metaData.career} careerPageData={careerPageData} />;
      case 4:
        return <NewsPage metaData={metaData.news} newsPageData={newsPageData} />;
      case 5:
        return <ContactPage metaData={metaData.contact} contactPageData={contactPageData} />;
      case 6:
        return <ApplyPage metaData={metaData.apply} applyPageData={applyPageData} />;
      case 7:
        return <LoginPage />;
      default:
        return <LandingPage metaData={metaData.home} homePageData={homePageData} />;
    }
  }, [currentPage, metaData, homePageData, aboutPageData, careerPageData, newsPageData, contactPageData, applyPageData]);
  

  const handleNavigation = (page, pageNumber) => {
    if (published) {
      pageNumber === 1 ? history.push('/') : pageNumber === 2 ? history.push('/about') : pageNumber === 3 ? history.push('/career') : pageNumber === 4 ? history.push('/news') : pageNumber === 5 ? history.push('/contact') : pageNumber === 6 ? history.push('/apply') : pageNumber === 7 ? history.push('/login') : history.push('/');
    } else {
      setCurrentPage(pageNumber);
    }
  }

  return (
    <>
    <MetaTags charset={metaData.charset} viewport={metaData.viewport} trackingCode={trackingCode} />
    <Box sx={{ height: 'auto', overflow: 'hidden' }}>
      <MainHeader onNavigate={handleNavigation} headerData={headerData} handleNavUpdate={handleNavUpdate} />
      <Box sx={{ height: 'calc(100% - 64px - 64px)', overflowY: 'auto' }}>
        {published ? (
          <Switch>
            <Route exact path="/" component={() => <LandingPage metaData={metaData.home} homePageData={homePageData} published={published} />} />
            <Route path="/about" component={() => <AboutPage metaData={metaData.about} aboutPageData={aboutPageData} />} />
            <Route path="/career" component={() => <CareerPage metaData={metaData.career} careerPageData={careerPageData} />} />
            <Route path="/news" component={() => <NewsPage metaData={metaData.news} newsPageData={newsPageData} />} />
            <Route path="/contact" component={() => <ContactPage metaData={metaData.contact} contactPageData={contactPageData} />} />
            <Route path="/apply" component={() => <ApplyPage metaData={metaData.apply} applyPageData={applyPageData} />} />
            <Route path="/login" component={() => <LoginPage />} />
          </Switch>
        ) : mapPageTitle()}
        <MainFooter footerData={footerData} />
      </Box>
    </Box>
    </>
  );
}

