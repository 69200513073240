import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography,
    Grid
} from '@mui/material'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles for Quill
import PropTypes from 'prop-types';

const RichTextEditor = ({openTextDialog, oncloseDialog, onUpload, value }) => {
    const [html, setHtml] = useState(value)


     
     useEffect(() => {
        setHtml(value);
    }, [value]);

    const handleChange = (html) => {
        setHtml(html)
    };


    const handleSave = () => {
        onUpload(html)
        oncloseDialog()
    }

    const modulesInfo = [
        {icon: <span style={{fontSize: '1.2rem'}}><i className="fa fa-header" aria-hidden="true" />H<sub>1</sub></span>, text: 'Header 1' },
        {icon: <span style={{fontSize: '1.2rem'}}><i className="fa fa-header" aria-hidden="true" />H<sub>2</sub></span>, text: 'Header 2' },
        {icon: <i className="fa fa-bold" aria-hidden="true" />, text: 'Bold' },
        {icon: <i className="fa fa-italic" aria-hidden="true" />, text: 'Italic' },
        {icon: <i className="fa fa-underline" aria-hidden="true" />, text: 'Underline' },
        {icon: <i className="fa fa-strikethrough" aria-hidden="true" />, text: 'Strikethrough' },
        {icon: <i className="fa fa-list-ol" aria-hidden="true" />, text: 'Ordered List' },
        {icon: <i className="fa fa-list-ul" aria-hidden="true" />, text: 'Unordered List' },
    ];

    return (
        <Dialog
            open={openTextDialog}
            onClose={oncloseDialog}
        >
            <DialogTitle id="alert-dialog-slide-title">Text Editor</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Typography variant="body1" gutterBottom>
                        Legend of the tools above the text editor
                    </Typography>
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <ul>
                                {modulesInfo.slice(0, 4).map((info, index) => ( 
                                    <li key={index}>
                                        {info.icon} {info.text}
                                    </li>
                                ))}
                            </ul>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <ul>
                                {modulesInfo.slice(4).map((info, index) => ( 
                                    <li key={index}>
                                        {info.icon} {info.text}
                                    </li>
                                ))}
                            </ul>
                        </DialogContentText>
                    </Grid>
                </Grid>
                <ReactQuill
                    onChange={handleChange}
                    value={html}
                    modules={RichTextEditor.modules}
                    formats={RichTextEditor.formats}
                    bounds={'.app'}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={oncloseDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save Text
                </Button>
            </DialogActions>
        </Dialog>
    );
};

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RichTextEditor.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ],
    clipboard: {
        matchVisual: false,
    }
};

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
RichTextEditor.formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
];

/* 
 * PropType validation
 */
RichTextEditor.propTypes = {
    placeholder: PropTypes.string,
};

/* 
 * Render component on page
 */
export default RichTextEditor;
