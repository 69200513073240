import React, { useState } from 'react';
import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Button, 
    List, 
    ListItem, 
    ListItemText, 
    IconButton, 
    Tooltip, 
    Alert 
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const FAQDialog = ({ openFAQ, handleCloseFAQ }) => {
  const faqs = [
    { question: "What is a CMS?", answer: "A CMS (Content Management System) allows you to create, manage, and modify content on a website without needing specialized coding skills." },
    { question: "Why use a CMS?", answer: "It simplifies the management of content, provides flexibility for non-technical users, and allows easy collaboration." },
    { question: "Which CMS is best?", answer: "It depends on your needs. WordPress is popular for blogs, Shopify for e-commerce, and custom CMS tools are useful for specific use cases." },
    { question: "Can I customize the CMS?", answer: "Yes, most CMS platforms offer themes and plugins for customization. You can also integrate custom code if needed." },
    { question: "Is CMS secure?", answer: "CMS platforms can be secure if maintained properly, with regular updates, backups, and the use of security plugins." }
  ];

  return (
    <Dialog open={openFAQ} onClose={handleCloseFAQ}>
      <DialogTitle>
        FAQ - Content Management System (CMS)
        <Tooltip title="Find common questions and answers related to CMS">
          <IconButton style={{ marginLeft: '8px' }}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Alert severity="info" style={{ cursor: 'help' }} title="Info">
          Below is a list of frequently asked questions about CMS. If you have further questions, please reach out to support.
        </Alert>
        <List>
          {faqs.map((faq, index) => (
            <ListItem key={index}>
              <ListItemText 
                primary={faq.question} 
                secondary={faq.answer} 
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseFAQ}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FAQDialog;
