import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {getStores} from 'api/private/store'
import {Button, Card, Accordion} from 'react-bootstrap'
import RecruiterAssessment from './Recruiter'
import StoreAssessment from './Store'
import {getUserByToken} from 'api/public/auth'
import {getSpecificApplicantReview} from 'api/private/applicants'
var dayjs = require('dayjs')

export default function ViewApplicantForm({details}) {
  const {id, company} = useParams()
  const [user, setUser] = useState([])
  const [data, setData] = useState({
    recruitment: {},
    store: {},
    storeList: {},
  })

  const capitalizeName = str => {
    return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str
  }

  const getUser = async () => {
    const r = await getUserByToken()
    if (r.status === 200) {
      setUser({...user, ...r.data})
    }
  }

  const getReview = async () => {
    const q = await getSpecificApplicantReview(id, company)
    if (q) {
      const rc = q[0].recruitment ? JSON.parse(q[0].recruitment) : {}
      const str = q[0].store_assess ? JSON.parse(q[0].store_assess) : {}
      setData(prevState => ({...prevState, recruitment: rc, store: str}))
    }

    setData(prevState => ({...prevState, loading: false}))
  }

  const listStores = async () => {
    const r = await getStores()
    if (r && r.length > 0) {
      let dt = []
      r.map(d =>
        dt.push({
          id: d.id,
          store_name: capitalizeName(d.name.toLowerCase()),
          company: d.company,
          createdAt: dayjs(d.date_created).format('MMMM-DD-YYYY h:m:s a'),
        }),
      )
      setData(prevState => ({...prevState, storeList: dt, loading: false}))
    }
  }

  useEffect(() => {
    getUser()
    getReview()
    listStores()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Accordion>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Recruitment Assessment
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {data.recruitment.length !== 0 ? (
                <RecruiterAssessment recruitment={data.recruitment} details={details} store={data.storeList} />
              ) : (
                'No Recruitment Review yet.'
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="1">
              Store Assessment
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {data.store.length !== 0 ? (
                <StoreAssessment
                  store={data.store}
                  details={details}
                  recruitment={data.recruitment}
                  viewType={data.store.assess_ts_name ? true : false}
                />
              ) : (
                'No Recruitment Review yet.'
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}
