import React, { useState, useEffect } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled wrapper for the image container
const StyledBackgroundImage = styled(Box)(({ backgroundImageUrl, component, theme }) => ({
  width: '100%',
  height: '100%',
  maxHeight: '100dvh', // 100dvh is the maximum height of the viewport
  backgroundImage: `url(${backgroundImageUrl})`,
  backgroundColor: component === 'footer' ? theme.palette.background.default : component === 'landingBusiness' ? theme.palette.common.white : component === 'aboutServices' ? theme.palette.common.white : component === 'landingTeam' ? theme.palette.common.white : component === 'aboutBanner' ? theme.palette.grey[500] : component === 'landingCommitment' ? theme.palette.primary.main : '#FBBA37',
}));

const BackgroundImageWithFallback = ({ src, fallbackSrc, children, sx, component, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [backgroundImageUrl, setBackgroundImageURL] = useState(src);

  useEffect(() => {
    if (!src) {
      setBackgroundImageURL(fallbackSrc);
      return;
    }
    setLoading(true);
    setError(false);

    const img = new Image(); // Preload the image to track its loading state
    img.src = src;

    img.onload = () => {
      setLoading(false);
      setError(false);
      setBackgroundImageURL(src);
    };

    img.onerror = () => {
      setLoading(false);
      setError(true);
      setBackgroundImageURL(fallbackSrc);
    };

    
  }, [src]);

  return (
    <StyledBackgroundImage backgroundImageUrl={backgroundImageUrl} component={component} sx={sx}>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          position="absolute"
          top={0}
          left={0}
        >
          <CircularProgress />
        </Box>
      )}
      {children}
    </StyledBackgroundImage>
  );
};

export default BackgroundImageWithFallback;

