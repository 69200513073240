import React, { useEffect, useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Container, Typography, Grid, Button, Link, Box, CircularProgress, Backdrop } from '@mui/material';
import { alpha, lighten } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Link as RouterLink, useHistory } from 'react-router-dom';


import layoutImage from '../../assets/layout1-agency.jpg';
import cover from '../../assets/wavy-two.png';
import ImageUploadDialog from '../../../../image/ImageWithDialog';
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import { EditButtonDialog } from 'components/dialog/EditButtonDialog'
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import BackgroundImageWithFallback  from 'components/crm/image/BackgroundImageWithFallback';
import  ImageWithFallback  from 'components/crm/image/ImageWithFallback';
import SecureLS from 'secure-ls'


const ls = new SecureLS({encodingType: 'aes'})

export default function LandingBusiness({data}) {
  const history = useHistory();
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(false);
  
  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState(['Apply online', 'Employee Login']); // Assuming 3 text fields
  const [openDialogIndex, setOpenDialogIndex] = useState(null); // Track which dialog is open
  const [dialogValue, setDialogValue] = useState('');

  const [applyOnlineButton, setApplyOnlineButton] = useState({ text: 'Click here', link: '/apply'}); // Assuming 3 text fields
  const [employeeLoginButton, setEmployeeLoginButton] = useState({ text: 'Click here', link: '/login'}); // Assuming 3 text fields
  const [openButtonDialog, setOpenButtonDialog] = useState(''); // Track which dialog is open
  const [dialogButtonValue, setDialogButtonValue] = useState({ text: '', link: ''});

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState([layoutImage, cover]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [parentHover, setParentHover] = useState(false);
  const [childHover, setChildHover] = useState(null);

  useEffect(() => {
    const passedData = data?.data || {};
    const storedApplyOnlineText = ls.get('crmLandingBusinessApplyOnlineText');
    const storedEmployeeLoginText = ls.get('crmLandingBusinessEmployeeLoginText');
    const storedImage = ls.get('crmLandingBusinessImage');
    const storedApplyOnlineButton = ls.get('crmLandingBusinessApplyOnlineButton');
    const storedEmployeeLoginButton = ls.get('crmLandingBusinessEmployeeLoginButton');
    setTextFields([
      storedApplyOnlineText || passedData?.applyOnlineText || 'Apply online', 
      storedEmployeeLoginText || passedData?.employeeLoginText || 'Employee Login'
    ]);

    setImageUrl(storedImage || passedData?.layoutImage || [layoutImage, cover]);
    setApplyOnlineButton(storedApplyOnlineButton || passedData?.applyOnlineButton || { text: 'Click here', link: '/apply' });
    setEmployeeLoginButton(storedEmployeeLoginButton || passedData?.employeeLoginButton || { text: 'Click here', link: '/login' });
  }, []);


  const { isEditMode } = context || {}

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]); // Load the value of the clicked Typography into the dialog
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);

    if (openDialogIndex === 0) {
      ls.set('crmLandingBusinessApplyOnlineText', newValues[0]);
    } else if (openDialogIndex === 1) {
      ls.set('crmLandingBusinessEmployeeLoginText', newValues[1]);
    }

  };

  const handleButtonDialogOpen = (index) => {
    setOpenButtonDialog(index);
    if (index === 'apply') {
      setDialogButtonValue({text: applyOnlineButton.text, link: applyOnlineButton.link});
    } else if (index === 'employee') {
      setDialogButtonValue({text: employeeLoginButton.text, link: employeeLoginButton.link});
    }
  };

  const handleButtonDialogClose = () => {
    setOpenButtonDialog('');
    setDialogButtonValue({ text: '', link: ''});
  };

  const handleButtonDialogChange = (text, link) => {
    setDialogButtonValue({ text, link });
  };

  const handleButtonDialogSave = (text, link) => {
    if (openButtonDialog === 'apply') {
      setApplyOnlineButton({ text, link });
      ls.set('crmLandingBusinessApplyOnlineButton', {text, link});
    } else if (openButtonDialog === 'employee') {
      setEmployeeLoginButton({ text, link });
      ls.set('crmLandingBusinessEmployeeLoginButton', {text, link});
    }
  };


  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true); // Open dialog on image click
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false); // Close dialog
  };

  const handleImageUpload = (newImageUrl) => {
    handleImageLoading()
    const updatedImages = [...imageUrl];
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrl(updatedImages); 
    ls.set('crmLandingBusinessImage', updatedImages); // Save the new image in local storage
    handleImageLoaded()
  };

  const handleImageLoading = () => {
    setImageLoading(true);
  };

  const handleImageLoaded = () => {
    setImageLoading(false);
  };

  return (
    <>
    <EditTextDialog
      open={openDialogIndex !== null}
      value={dialogValue}
      onClose={handleDialogClose}
      onSave={handleDialogSave}
      onChange={setDialogValue}
    />
    <ImageUploadDialog
      open={imageDialogOpen}
      onClose={handleImageDialogClose}
      onUpload={handleImageUpload}
      currentImage={imageUrl[selectedImageIndex]}
    />
    <EditButtonDialog
      open={openButtonDialog !== ''}
      onClose={handleButtonDialogClose}
      onSave={handleButtonDialogSave}
      onChange={handleButtonDialogChange}
      textValue={dialogButtonValue.text}
      linkValue={dialogButtonValue.link}
    />
    <Backdrop
      sx={(theme) => ({ color: '#fff', zIndex: 5 })}
      open={imageLoading}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
    
    <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(1)} hover={parentHover && !childHover} onMouseEnter={() => setParentHover(true)} onMouseLeave={() => setParentHover(false)} editType={'background'}>
    <BackgroundImageWithFallback
      src={imageUrl[1]}
      fallbackSrc={cover}
      component={"landingBusiness"}
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '100% 75%',
        paddingBottom: '5vh',
        marginBottom: '5vh',
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{ width: '100%', ml: { xs: 0 } }}>
          <Grid item xs={12} md={8} sx={{ pl: { xs: '0 !important' } }}>
            <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(0)} hover={childHover === 1} onMouseEnter={() => setChildHover(1)} onMouseLeave={() => setChildHover(null)} editType={'image'}>
              <ImageWithFallback 
                src={imageUrl[0]}
                fallbackSrc={layoutImage}
                alt="Layout Agency"
                sx={{ width: '100%', height: { md: 480, xs: 300 }, objectFit: 'cover' }}
              />
            </EditableItemWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pl: { xs: '0 !important', md: '40px !important' }
            }}
          >
            <motion.div style={{ marginTop: 0, width: '100%' }}>
              <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)} hover={childHover === 2} onMouseEnter={() => setChildHover(2)} onMouseLeave={() => setChildHover(null)} editType={'text'}>
                <Typography
                  variant="h5"
                  sx={{
                    color: '#000000',
                    margin: 'auto',
                    textAlign: 'center',
                    mt: { xs: 0, sm: 0, md: 0 },
                    mb: { xs: 3 },
                    fontFamily: 'HKGrotesk, sans-serif',
                    fontWeight: 'bold'
                  }}
                  component="h2"
                >
                  {textFields[0]}
                </Typography>
              </EditableItemWrapper>
              <EditableItemWrapper onClick={() => !!isEditMode && handleButtonDialogOpen('apply')} hover={childHover === 3} onMouseEnter={() => setChildHover(3)} onMouseLeave={() => setChildHover(null)} editType={'button'}>
                <Link
                  color="common.white"
                  href={isEditMode ? undefined : applyOnlineButton.link}
                  sx={{
                    display: 'block',
                    width: '100%',
                    '&:hover': {
                      textDecoration: 'none'
                    }
                  }}
                >
                  <Button
                    component="h6"
                    variant="contained"
                    size="large"
                    sx={{
                      width: '100%',
                      marginLeft: '0',
                      fontSize: 18,
                      textTransform: 'none',
                      my: { xs: 1, md: 3 },
                      transition: 'all 0.4s ease',
                      backgroundColor: theme.palette.primary.main || '#007bff',
                      color: '#ffffff', 
                      boxShadow: 'none',
                      '&:hover': {
                        transition: 'all 0.4s ease',
                        transform: 'scale(1,1)',
                        bbackgroundColor: lighten(theme.palette.primary.main || '#0056b3', 0.2),
                        color: '#ffffff',
                        fontFamily: 'HKGrotesk, sans-serif',
                      }
                    }}
                  >
                    {applyOnlineButton.text}
                  </Button>
                </Link>
              </EditableItemWrapper>
              <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(1)} hover={childHover === 4} onMouseEnter={() => setChildHover(4)} onMouseLeave={() => setChildHover(null)} editType={'text'}>
                <Typography
                  variant="h5"
                  sx={{ color: '#000000', mt: 1, textAlign: 'center', fontFamily: 'HKGrotesk, sans-serif', fontWeight: 'bold' }}
                  component="p"
                >
                  {textFields[1]}
                </Typography>
              </EditableItemWrapper>
              <EditableItemWrapper onClick={() => !!isEditMode && handleButtonDialogOpen('employee')} hover={childHover === 5} onMouseEnter={() => setChildHover(5)} onMouseLeave={() => setChildHover(null)} editType={'button'}>
              <Link
                color="common.white"
                href={isEditMode ? undefined : employeeLoginButton.link}
                sx={{
                  display: 'block',
                  width: '100%',
                  '&:hover': {
                    textDecoration: 'none'
                  }
                }}
              >
                <Button
                  component="h6"
                  variant="contained"
                  size="large"
                  sx={{
                    width: '100%',
                    marginLeft: '0',
                    fontSize: 18,
                    textTransform: 'none',
                    my: { xs: 1, md: 3 },
                    transition: 'all 0.4s ease',
                    backgroundColor: theme.palette.primary.main || '#007bff',
                    color: '#ffffff', 
                    boxShadow: 'none',
                    '&:hover': {
                      transition: 'all 0.4s ease',
                      transform: 'scale(1,1)',
                      bbackgroundColor: lighten(theme.palette.primary.main || '#0056b3', 0.2),
                      color: '#ffffff',
                      fontFamily: 'HKGrotesk, sans-serif'
                    }
                  }}
                >
                  {employeeLoginButton.text}
                </Button>
              </Link>
              </EditableItemWrapper>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </BackgroundImageWithFallback>
    </EditableItemWrapper>
    </>
  );
}