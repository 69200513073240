import React, { useState, useEffect } from 'react';
import { Button, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, TextField, FormHelperText } from '@mui/material';

export const EditButtonDialog = ({ open, textValue, linkValue, onClose, onSave, onChange, textLimit }) => {
  const [errorText, setTextError] = useState(null);
  const [errorLink, setLinkError] = useState(null);
  const charLimit = textLimit !== undefined ? textLimit : 50;

  const handleTextChange = (e) => {
    const newValue = e.target.value;
    const newError = newValue.trim().length === 0 ? 'Text can\'t be empty' : newValue.length > charLimit  ? `Text is too long, exceeds ${charLimit} character limit` : null;
    setTextError(newError);
    onChange(newValue, linkValue);
  };

  const handleLinkChange = (e) => {
    const newValue = e.target.value;
    const newError = newValue.trim().length === 0 ? 'Link can\'t be empty' : !/^(https?:\/\/|\/).+$/.test(newValue) ? 'Text should be a http/https link or a path' : null;
    setLinkError(newError);
    onChange(textValue, newValue);
  };

  const handleSave = () => {
    if (errorText || errorLink) return;
    onSave(textValue, linkValue);
    onClose();
  };

  useEffect(() => {
    setTextError(textValue.trim().length === 0 ? 'Text can\'t be empty' : textValue.length > charLimit ? `Text is too long, exceeds ${charLimit} character limit` : null);
    setLinkError(linkValue.trim().length === 0 ? 'Link can\'t be empty' : !/^(https?:\/\/|\/).+$/.test(linkValue) ? 'Text should be a http/https link or a path' : null);
  }, [open]);


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Button</DialogTitle>
      <DialogContent>
        <TextField
          label="Button Text"
          value={textValue}
          onChange={handleTextChange}
          fullWidth
          variant="outlined"
          sx={{ maxWidth: '100%', marginBottom: 2, marginTop: 2 }}
          error={Boolean(errorText)}
          helperText={errorText}
        />
        <TextField
          label="Button Link"
          value={linkValue}
          onChange={handleLinkChange}
          fullWidth
          variant="outlined"
          sx={{ maxWidth: '100%' }}
          error={Boolean(errorLink)}
          helperText={errorLink}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={Boolean(errorText || errorLink)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};


