import React, { useEffect, useState, useContext } from 'react'
import {Icon} from '@iconify/react'
import facebookFill from '@iconify/icons-eva/facebook-fill'
import {Link as ScrollLink} from 'react-scroll'
import {styled} from '@mui/material/styles'
import {Grid, Link, Divider, Container, Typography, Stack, Box, Button, CircularProgress} from '@mui/material'
import cover from '../assets/wavy-two.png'
import defaultLogo from '../assets/logo.png'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import ImageUploadDialog from '../../../image/ImageWithDialog';
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import ImageWithFallback  from 'components/crm/image/ImageWithFallback';
import BackgroundImageWithFallback  from 'components/crm/image/BackgroundImageWithFallback';
import SecureLS from 'secure-ls'
import { EditURLDialog } from 'components/dialog/EditURLDialog'
import { repeat } from 'lodash'


const ls = new SecureLS({encodingType: 'aes'})

const defaultIcons = ['eva:facebook-fill', 'mdi:youtube', 'prime:twitter', 'mdi:instagram', 'mdi:linkedin'];

export default function MainFooter({footerData}) {
  const context = useContext(EditModeContext);
  const officeAddress = footerData?.officeAddress || 'Unit 903 MERN Building I, 833 P. Paredes Street, Sampaloc, Manila'
  const emailAddress = footerData?.emailAddress || 'info@xoxo.com.ph'
  const phoneNumber = footerData?.phoneNumber || '(02) 9999 2394'
  const facebookLink = footerData?.iconLink || [
    {
      icon: 0,
      alt: 'Facebook',
      link: 'https://www.facebook.com/syzygystaffing',
    }
  ]
  const footerText =
    footerData?.footerText || '© 2022. All rights reserved. XOXO Staffing Resources Agency Corporation.'

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState([defaultLogo, cover]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [textFields, setTextFields] = useState([officeAddress, emailAddress, phoneNumber, footerText]); // Assuming 3 text fields
  const [openDialogIndex, setOpenDialogIndex] = useState(null); // Track which dialog is open
  const [dialogValue, setDialogValue] = useState('');

  const [hrefLinks, setHrefLinks] = useState(facebookLink);
  const [openURLDialogIndex, setOpenURLDialogIndex] = useState(null); // Track which dialog is open
  const [dialogURLValue, setDialogURLValue] = useState({
    icon: 0,
    alt: '',
    link: '',
  });

  const [parentHover, setParentHover] = useState(false);
  const [childHover, setChildHover] = useState(null);

  useEffect(() => {
    const storedValues = ls.get('crmMainFooterTextFields');
    if (storedValues) {
      setTextFields(storedValues.split('|'));
    }
  }, [footerData]);

  useEffect(() => {
    console.log('footerData', footerData)
    // Load the image from local storage when the component mounts
    const storedImage = ls.get('crmMainFooterImage');
    setImageUrl(storedImage || footerData?.footerImages || [defaultLogo, cover]);
  }, [footerData]);

  useEffect(() => {
    const storedHrefLinks = ls.get('crmMainFooterHrefLinks');
    if (storedHrefLinks) {
      setHrefLinks(storedHrefLinks);
    }
    
  }, [footerData]);

  const { isEditMode } = context || {}

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]); // Load the value of the clicked Typography into the dialog
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);
    const concatenatedValues = newValues.join('|');
    ls.set('crmMainFooterTextFields', concatenatedValues);
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true); // Open dialog on image click
  };

  const handleImageDialogClose = () => {
    setSelectedImageIndex(null);
    setImageDialogOpen(false); // Close dialog
  };

  const handleImageUpload = (newImageUrl) => {
    const updatedImages = [...imageUrl];
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrl(updatedImages); 
    ls.set('crmMainFooterImage', updatedImages); // Save the new image in local storage
  };

  const handleEditURLClick = (index) => {
    setOpenURLDialogIndex(index);
    setDialogURLValue(hrefLinks[index]);
  };

  const handleEditURLClose = () => {
    setOpenURLDialogIndex(null);
  };

  const handleEditURLSave = (newHrefLink) => {
    const newValues = [...hrefLinks];
    newValues[openURLDialogIndex] = newHrefLink;
    setHrefLinks(newValues);
    ls.set('crmMainFooterHrefLinks', newValues);
  }

  const handleEditURLRemove = () => {
    const newValues = [...hrefLinks];
    newValues.splice(openURLDialogIndex, 1);
    setHrefLinks(newValues);
    ls.set('crmMainFooterHrefLinks', newValues);
  }

  const handleAddURL = () => {
    const newValues = [...hrefLinks];
    newValues.push({ icon: 5, alt: 'sample' , link: 'https://example.com' });
    setHrefLinks(newValues);
    ls.set('crmMainFooterHrefLinks', newValues);
  }

  return (
    <>
    <EditTextDialog
      open={openDialogIndex !== null}
      value={dialogValue}
      onClose={handleDialogClose}
      onSave={handleDialogSave}
      onChange={setDialogValue}
    />
    <ImageUploadDialog
      open={imageDialogOpen}
      onClose={handleImageDialogClose}
      onUpload={handleImageUpload}
      currentImage={imageUrl[selectedImageIndex]}
    />
    <EditURLDialog
      open={openURLDialogIndex !== null}
      initialValue={dialogURLValue}
      icons={defaultIcons}
      onClose={handleEditURLClose}
      onSave={handleEditURLSave}
      onRemove={handleEditURLRemove}
    />
    <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(1)} hover={parentHover && !childHover} onMouseEnter={() => setParentHover(true)} onMouseLeave={() => setParentHover(false)} editType={'background'}>
    <BackgroundImageWithFallback
      src={imageUrl[1]}
      fallbackSrc={cover}
      component={'footer'}
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 70vh',
        backgroundPosition: '100% -350px',
      }}
    >
      <Divider />
      <Container maxWidth="lg" sx={{pt:'5vh'}}>
        <Grid container justifyContent={{xs: 'center', md: 'center'}} sx={{textAlign: {xs: 'center', md: 'left'}}}>
          <Grid item xs={12} sx={{mb: 5, display: 'flex', justifyContent: 'center'}}>
            {!!isEditMode ? (
              <>
              <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(0)} hover={childHover === 990} onMouseEnter={() => setChildHover(990)} onMouseLeave={() => setChildHover(null)} editType={'logo'}>
                  <ImageWithFallback
                    src={imageUrl[0]}
                    fallbackSrc={defaultLogo}
                    sx={{width: 150, height:150, objectFit: 'contain'}}
                  />
              </EditableItemWrapper>
              </>
            ) : (
              <>
                <ScrollLink to="move_top" spy smooth>
                  <ImageWithFallback
                      src={imageUrl[0]}
                      fallbackSrc={defaultLogo}
                      sx={{width: 150, height:150, objectFit: 'contain'}}
                  />
                </ScrollLink>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={5} sx={{textAlign: 'center'}}>
            <Typography variant="overline" sx={{pr: {md: 5}}}>
              Office address
            </Typography>
            <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)} hover={childHover === 991} onMouseEnter={() => setChildHover(991)} onMouseLeave={() => setChildHover(null)} editType={'text'}>
              <Typography variant="body2" sx={{pr: {md: 5}, mb: 1}} >
              {textFields[0]} 
              </Typography>
            </EditableItemWrapper>
          </Grid>

          <Grid item xs={12} md={3} sx={{textAlign: 'center'}}>
            <Typography variant="overline" sx={{pr: {md: 5}}}>
              Email address
            </Typography>
            <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(1)} hover={childHover === 992} onMouseEnter={() => setChildHover(992)} onMouseLeave={() => setChildHover(null)} editType={'text'}>
              <Typography variant="body2" sx={{pr: {md: 5}, mb: 1}} >
              {textFields[1]}
              </Typography>
            </EditableItemWrapper>
          </Grid>
          <Grid item xs={12} md={3} sx={{textAlign: 'center'}}>
            <Typography variant="overline" sx={{pr: {md: 5}}}>
              Call
            </Typography>
            <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(2)} hover={childHover === 993} onMouseEnter={() => setChildHover(993)} onMouseLeave={() => setChildHover(null)} editType={'text'}>
              <Typography variant="body2" sx={{pr: {md: 5}, mb: 1}} >
              {textFields[2]}
              </Typography>
            </EditableItemWrapper>
          </Grid>
        </Grid>
        <Stack direction="row" flexWrap="wrap" justifyContent={{xs: 'center', md: 'center'}} sx={{mt: '1vh', mb: {xs: 5, md: 0}}}>
          {hrefLinks.map((iconLink, index) => (
            <>
          <EditableItemWrapper onClick={() => !!isEditMode && handleEditURLClick(index)} hover={childHover === index} onMouseEnter={() => setChildHover(index)} onMouseLeave={() => setChildHover(null)} editType={'link'}>
            <Link
              key={index}
              rel="noreferrer"
              href={ !isEditMode ? iconLink.link : undefined}
              sx={{
                mx: 2,
                transition: 'all 0.4s ease',
                '&:hover': {
                  color: iconLink.icon === 0 ? 'blue' : iconLink.icon === 1 ? 'red' : iconLink.icon === 2 ? 'grey' : iconLink.icon === 3 ? 'green' : iconLink.icon === 4 ? 'blue' : 'grey',
                  transform: 'scale(1.1)',
                  transition: 'all 0.4s ease',
                },
              }}
            >
              <Icon icon={defaultIcons[iconLink.icon] || 'material-symbols:link'} width={24} height={24} />
            </Link>
          </EditableItemWrapper>
          </>
          ))}
        {!!isEditMode && (
          <EditableItemWrapper onClick={() => !!isEditMode && handleAddURL()} hover={childHover === 994} onMouseEnter={() => setChildHover(994)} onMouseLeave={() => setChildHover(null)} withoutLabel={true}>
            <Button>Add</Button>
          </EditableItemWrapper>
        )}
        </Stack>
        <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(3)} hover={childHover === 995} onMouseEnter={() => setChildHover(995)} onMouseLeave={() => setChildHover(null)} editType={'text'}>
          <Typography
            component="p"
            variant="body2"
            sx={{
              fontSize: 13,
              textAlign: {xs: 'center', md: 'center'},
            }}
          >
            {textFields[3]}
          </Typography>
        </EditableItemWrapper>
      </Container>
    </BackgroundImageWithFallback>
    </EditableItemWrapper>
    </>
  )
}
