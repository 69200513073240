import React, { useEffect } from 'react'
import { useState } from 'react'

import Table from 'react-bootstrap-table-next'
import PaginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import CreateNewExams from './CreateExamsPage'
import CreateJobTitle from './CreateJobTitle'
import ComingSoonModal from '../../modal/ComingSoonModal'
import { Link } from 'react-router-dom'
import { getJobs } from 'api/private/jobs'

const { SearchBar } = Search
var dayjs = require('dayjs')
const JobsExamsPage = ({ createJob }) => {
  const [jobs, setJobs] = useState([])

  const listJobs = async () => {
    const r = await getJobs()
    let data = []
    if (!r) setJobs(data)

    r.map((jbv, jbk) => {
      let infos = jbv.meta_value ? JSON.parse(jbv.meta_value) : {}
      let exams = []
      if (jbv.exams.length === 0) exams = []

      jbv.exams.map((exv, exk) => {
        console.log(exv.meta_value)
        let examInfo = exv.meta_value ? JSON.parse(exv.meta_value) : {}
        exams.push({
          key: examInfo.id - 1 + Math.random() * (100 - 1),
          id: examInfo.id,
          job_id: examInfo.job_id,
          title: examInfo.title,
          notice: examInfo.notice,
          link: examInfo.link,
        })
      })

      data.push({
        key: jbk - 1 + Math.random() * (100 - 1),
        id: jbv.id,
        _id: jbv.id,
        exams: exams,
        ...infos,
        date_posted: dayjs(jbv.date_created).format('MMMM-DD-YYYY h:m:s a'),
      })
    })

    setJobs(data)
  }

  useEffect(() => {
    listJobs()
  }, [])

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ]

  const [showComingSoon, setShowComingSoon] = useState(false)

  const handleCloseComingSoon = () => setShowComingSoon(false)
  //const handleShowComingSoon = () => setShowComingSoon(true)

  const [showCreateExamForm, setShowCreateExamForm] = useState(false)

  const handleClose = () => setShowCreateExamForm(false)
  const handleShow = () => setShowCreateExamForm(true)

  const [showCreateJobTitleForm, setShowCreateJobTitle] = useState(false)
  const closeCreateJobTitle = () => setShowCreateJobTitle(false)
  const showCreateJobTitle = () => setShowCreateJobTitle(true)

  const columns = [
    {
      dataField: '_id',
      text: 'id',
      sort: true,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
    {
      dataField: 'title',
      text: 'Job Title',
    },
    {
      dataField: 'exams',
      text: 'Examinations',
      formatter: (cellContent, { exams }) => {
        return (
          <>
            {exams.map((exam, k) => (
              <a
                className="btn btn-primary m-1"
                href={exam.link}
                target="_blank"
                rel="noopener noreferrer"
                key={`exam-key-${k}`}
              >
                {exam.title}
              </a>
            ))}
          </>
        )
      },
    },
    {
      dataField: 'date_posted',
      text: 'Date Posted',
    },
    {
      dataField: 'queue_id',
      text: '',
      formatter: (cellContent, row) => (
        <>
          <Link type="button" to={`/system/jobs-exam/${row._id}/${row.company}`} className="btn btn-primary">
            View details
          </Link>
        </>
      ),
    },
  ]

  return (
    <div>
      <ToolkitProvider
        keyField="key"
        data={jobs}
        columns={columns}
        search
        defaultSorted={defaultSorted}
        pagination={PaginationFactory()}
      >
        {props => (
          <div>
            <SearchBar {...props.searchProps} />
            <button type="button" className="btn btn-primary m-1" onClick={showCreateJobTitle}>
              Create New Job Title
            </button>
            <button type="button" className="btn btn-primary m-1" onClick={handleShow}>
              Create Examinations
            </button>
            <hr />
            <Table defaultSorted={defaultSorted} pagination={PaginationFactory()} {...props.baseProps} />
          </div>
        )}
      </ToolkitProvider>

      <CreateNewExams show={showCreateExamForm} onClose={handleClose} jobs={jobs} />
      <CreateJobTitle onSubmit={createJob} show={showCreateJobTitleForm} onClose={closeCreateJobTitle} />
      <ComingSoonModal show={showComingSoon} onClose={handleCloseComingSoon} />
    </div>
  )
}

JobsExamsPage.propTypes = {}

export { JobsExamsPage }
