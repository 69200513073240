import React from 'react'
import 'style/_review.scss'

const HistorySurgery = ({d}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-5">
          <h4>Childhood Illness</h4>
          <table className="surgery-medical">
            <tbody>
              {!d.illness_none ? (
                <>
                  <tr className="form-group" key="illness_measles">
                    <td>
                      <input
                        type="checkbox"
                        name="illness_measles"
                        disabled={`${d.illness_measles !== undefined ? `disabled` : `readonly`}`}
                        checked={`${d.illness_measles !== undefined ? `checked` : ``}`}
                        className="form-control inline-chkbox "
                      />
                      <label className="control-label">Measles</label>
                    </td>
                  </tr>

                  <tr className="form-group" key="illness_mumps">
                    <td>
                      <input
                        type="checkbox"
                        name="illness_mumps"
                        disabled={`${d.illness_mumps !== undefined ? `disabled` : `readonly`}`}
                        checked={`${d.illness_mumps !== undefined ? `checked` : ``}`}
                        className="form-control inline-chkbox "
                      />
                      <label className="control-label">Mumps</label>
                    </td>
                  </tr>

                  <tr className="form-group" key="illness_chickenpox">
                    <td>
                      <input
                        type="checkbox"
                        name="illness_chickenpox"
                        disabled={`${d.illness_chickenpox !== undefined ? `disabled` : `readonly`}`}
                        checked={`${d.illness_chickenpox !== undefined ? `checked` : ``}`}
                        className="form-control inline-chkbox "
                      />
                      <label className="control-label">Chicken Pox</label>
                    </td>
                  </tr>

                  <tr className="form-group" key="illness_rheumatic">
                    <td>
                      <input
                        type="checkbox"
                        name="illness_rheumatic"
                        disabled={`${d.illness_rheumatic !== undefined ? `disabled` : `readonly`}`}
                        checked={`${d.illness_rheumatic !== undefined ? `checked` : ``}`}
                        className="form-control inline-chkbox"
                      />
                      <label className="control-label">Rheumatic Fever</label>
                    </td>
                  </tr>
                  <tr className="form-group" key="illness_polio">
                    <td>
                      <input
                        type="checkbox"
                        name="illness_polio"
                        disabled={`${d.illness_polio !== undefined ? `disabled` : `readonly`}`}
                        checked={`${d.illness_polio !== undefined ? `checked` : ``}`}
                        className="form-control inline-chkbox "
                      />
                      <label className="control-label">Polio</label>
                    </td>
                  </tr>
                </>
              ) : (
                <tr className="form-group" key="illness_none">
                  <td>
                    <input
                      type="checkbox"
                      name="illness_none"
                      disabled={`${d.illness_none !== undefined ? `disabled` : `readonly`}`}
                      checked={`${d.illness_none !== undefined ? `checked` : ``}`}
                      className="form-control inline-chkbox "
                    />
                    <label className="control-label">None</label>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="col-md-7">
          <table>
            <tbody>
              {/* This block of code returns a warning and needs different implementation 
              it is expecting a value after the arrow*/}
              {/* {d.surgery &&
                d.surgery.map((v, k) => {
                  if (v !== '' || v !== null) {
                    return (
                      <>
                        <tr key={`surgery-${k}`}>
                          <td width="400">Surgeries & Hospital:</td>
                          <td className="font-weight-bold text-uppercase">{`${v} / ${d.surgery_hospital[k]}`}</td>
                        </tr>
                        <tr key={`surgery-year-${k}`}>
                          <td width="200">Year:</td>
                          <td className="font-weight-bold text-uppercase">{`${
                            d.surgery_year ? d.surgery_year[k] : ''
                          }`}</td>
                        </tr>
                      </>
                    )
                  }
                })} */}
            </tbody>
          </table>
          <table>
            <tbody>
              {/* {d.other_hospital &&
                d.other_hospital.map((v, k) => {
                  if (v !== '' || v !== null) {
                    return (
                      <>
                        <tr key={`hospitalization-${k}`}>
                          <td width="400">Hospitalization & Hospital:</td>
                          <td className="font-weight-bold text-uppercase">{`${v} / ${d.other_hosp_hospital[k]}`}</td>
                        </tr>
                        <tr key={`hosp-year-${k}`}>
                          <td width="200">Year:</td>
                          <td className="font-weight-bold text-uppercase">{`${
                            d.other_hosp_year ? d.other_hosp_year[k] : ''
                          }`}</td>
                        </tr>
                      </>
                    )
                  } else {
                    return <tr></tr>
                  }
                })} */}
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <div className="col-md-12">
          {d.medical_agree_no_hospitalization ? (
            <table>
              <tbody>
                <tr className="form-group" key="medical_agree_no_hospitalization">
                  <td>
                    <label className="control-label">
                      Agreed - I hereby declare that I do not have any related medical history, hospitalization, known
                      surgery and medication from any of above stated childhood illnesses
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            ''
          )}
        </div>
        <br />
        <br />
      </div>
    </>
  )
}
export default HistorySurgery
