import React, {useState, useEffect, useRef} from 'react'
import {useParams} from 'react-router-dom'
import {getStores} from 'api/private/store'
import {Button, Card, Accordion, Form} from 'react-bootstrap'
import {getUserByToken} from 'api/public/auth'
import {getSpecificApplicantReview} from 'api/private/applicants'
import {getStoreDetails, updateApplicantStore, updateApplicantStatus} from 'api/private/store'
import {postSanctions} from 'api/private/documents'
import {CircularProgress, TextField, Box} from '@mui/material'
import SecureLS from 'secure-ls'
import DatePicker from "react-datepicker";
import moment from 'moment'
import {
  generateUploadUrl,
  postUploadDocument,
  postDocumentChange,
  postUpdateDocumentPending,
  postUpdateDocumentTraining,
} from 'api/private/documents'

var dayjs = require('dayjs')

export default function ViewPersonnelForm(verify) {
  const ls = new SecureLS({encodingType: 'aes'})
  const {id, company} = useParams()
  const [user, setUser] = useState([])
  const [status, setStatus] = useState()
  const [matShow, setMatShow] = useState(true)
  const [abandon, setAbandon] = useState(true)
  const [sanctionsShow, setSanctionsShow] = useState(true)
  const [facilitated, setFacilitated] = useState(true)
  const [isUploading, setUploading] = useState(false)
  const [storeId, setStoreId] = useState()
  const [newStatus, setNewStatus] = useState()
  const [storeName, setStoreName] = useState()
  const [oldStoreName, setOldStoreName] = useState(null)
  const [date, setDate] = useState(new Date())
  const [dateOfSigning, setDateOfSigning] = useState()
  const [facilitatedHr, setFacilitatedHr] = useState()
  const [dateOfTransfer, setDateOfTransfer] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [stores, setStores] = useState([])
  const [fileUrl, setFileUrl] = useState([])
  const [sanctions, setSanctions] = useState({
    applicantId: '',
    sanction: '',
    section: '',
    remarks: '',
    url: '',
    blobImg: '',
    imgFile: '',
    hr:'',
  })
  
  const capitalizeName = str => {
    return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str
  }

  const ref = useRef()

  const getUser = async () => {
    const r = await getUserByToken()
    if (r.status === 200) {
      setUser({...user, ...r.data})
    }
  }

  const getStore = async () => {
    const r = await getStoreDetails(id)
    if (r.status === 200) {
      if (ls.get('transferDate') === "") {
        setStoreName(r.store_name.storeName.name)
        if (r.store_name.oldStoreName) {
          setOldStoreName(r.store_name.oldStoreName.name) 
        }   
      }
      else {
        setStoreName(r.store_name.storeName.name + ls.get('transferDate'))
        if (r.store_name.oldStoreName) {
          setOldStoreName(r.store_name.oldStoreName.name) 
        }
      }
    }
  }

  const listStores = async () => {
    const r = await getStores()
    if (r && r.length > 0) {
      setStores(r)
    }
  }

  const handleHrStatusChange = async (e) => {
    setNewStatus(e.target.value)
    setFacilitatedHr("")
    setFacilitated(true)
    if (e.target.value === "MAT LEAVE") {
        setMatShow(false)
        setAbandon(true)
    }
    else if (e.target.value !== "MAT LEAVE") {
        setAbandon(false)
        setMatShow(true)
    }
    else {
      setMatShow(true)
      setAbandon(true)
    }
    if(e.target.value === "ACTIVELY DEPLOYED") {
      setMatShow(true)
      setAbandon(true)
    }

    if (e.target.value === "FACILITATED VRS") {
      setFacilitated(false)
    }
    
  } 


  const handleStatusChangeClick = async (e) => {
    e.preventDefault()
    setUploading(true)
    let data
    
    if (newStatus === "MAT LEAVE") {
      data = {
        "hrName": user.firstname + " " + user.lastname,
        "hrEmail": user.email,
        "id": id,
        "status": newStatus,
        "startDate": moment(startDate).format("YYYY-MM-DD"),
        "endDate": moment(endDate).format("YYYY-MM-DD")
      }
      const updateResult = await updateApplicantStatus(data)
      if (updateResult.status === 200) {
        alert("Personnel Status Succesfully Updated")
        setStatus(newStatus + " ( " + data.startDate + " <- TO -> " + data.endDate + " )")
        ls.set('status', newStatus + " ( " + data.startDate + " <- TO -> " + data.endDate + " )")
      }  
    }
    else {
      if (newStatus === "ACTIVELY DEPLOYED") {
        data = {
          "hrName": user.firstname + " " + user.lastname,
          "hrEmail": user.email,
          "id": id,
          "status": newStatus,
          "date": moment(new Date).format("YYYY-MM-DD"),
        }  
      } 
      else {
        data = {
          "hrName": user.firstname + " " + user.lastname,
          "hrEmail": user.email,
          "id": id,
          "status": newStatus,
          "date": moment(date).format("YYYY-MM-DD"),
          "hr": facilitatedHr
        }
      }


      const updateResult = await updateApplicantStatus(data)
      if (updateResult.status === 200) {
        alert("Personnel Status Succesfully Updated")
        if (updateResult.data.hr === "" || updateResult.data.hr === null) {
          setStatus(updateResult.data.status + " ( " + updateResult.data.date + " ) ")
          ls.set('status', updateResult.data.status + " ( " + updateResult.data.date + " ) ") 
        }
        else {
          setStatus(updateResult.data.status + " ( " + updateResult.data.date + " ) " + " -> " + updateResult.data.hr ) 
          ls.set('status', updateResult.data.status + " ( " + updateResult.data.date + " ) " + " -> " + updateResult.data.hr)  
        }
        
      }
    }
    setUploading(false)
  }
  const handleStoreUpdateClick = async (e) => {
    e.preventDefault()
    setUploading(true)
    const data = {
      "hrName": user.firstname + " " + user.lastname,
      "hrEmail": user.email,
      "id": id,
      "storeId": storeId,
      "date": moment(dateOfTransfer).format("YYYY-MM-DD"),
    }
    const updateResult = await updateApplicantStore(data)
    if (updateResult.status === 200) {
      const r = await getStoreDetails(id)
      if (r.status === 200) {
        alert("Store Deployment Succesfully Updated")
        setStoreName(r.store_name.name + " ( " + updateResult.data.date_of_transfer + " ) ")
        ls.set('transferDate', " ( " + updateResult.data.date_of_transfer + " ) ")
        setUploading(false)
      }
    }
  }

  const handleStoreChange = (e) => {
    setStoreId(e.target.value)
  }

  const handleFileChange = async e => {
    setUploading(true)
    const selectedFiles = Array.from(e.target.files)
    selectedFiles.forEach(async file => {
      const _file = file
      const _blob = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      const _url = await generateUploadUrl(_blob, _file)
      
      if (_url) {
        setSanctions({...sanctions, url: _url, blobImg: _blob, imgFile: _file})
        setFileUrl(prevState => [...prevState, {"url": _url, "blobImg": _blob, "imgFile": _file}])
        setUploading(false)  
      }
      else
      {
        alert("Server error please contact your IT administrator")
      }
    });
  }

  const handleSanctionsClick = async (e) => {
    let hasError = false
    e.preventDefault()
    setUploading(true)
    const name = user.firstname + " " + user.lastname
    for (const file of fileUrl) {
      try {
        const sanctionsResult = await postSanctions(
          file.blobImg,
          file.imgFile,
          file.url,
          id,
          sanctions.sanction,
          capitalizeName(sanctions.section),
          capitalizeName(sanctions.remarks),
          sanctions.hr,
          name,
          user.email
        );

        if (sanctionsResult !== 200) {
          hasError = true;
          break; // Exit the loop if an error is encountered
        }
      } catch (error) {
        console.error(error);
        hasError = true;
        break; // Exit the loop if an error occurs
      }
    }
    if(!hasError) {
      setFileUrl([])
      setSanctions({
        applicantId: '',
        sanction: '',
        section: '',
        remarks: '',
        url: '',
        blobImg: '',
        imgFile: '',
        hr:'',
      })
      verify.verify("success") 
      alert("Sanctions successfuly registered")
      setUploading(false)
      ref.current.value = ""
       
    }
    else {
      console.log("Here" + hasError)
      alert("Something went wrong please try again later")
      setUploading(false)
    } 


  }
    
  useEffect(() => {
    getUser()
    getStore()
    setStatus(ls.get('status'))
    setStoreName(ls.get('store'))
    setDateOfSigning(ls.get('date_signing'))
    listStores()
    verify.verify("")
  }, [])

  useEffect(() => {
    getStore()
  }, [storeName])

  return (
    <>
    	<Card>
    	  	<Card.Header>
    	    	<span>
    	      		Employment Status: <span className="font-weight-bolder font-size-sm">{status}</span>
    			  </span>
            <br/>
            <br/>
            {oldStoreName 
              ?
                <span>
                    Deployment Store: 
                      <span className="font-weight-bolder font-size-sm">
                        {oldStoreName ? `${oldStoreName} -> ${storeName}` : storeName}
                      </span>
                </span> 
              :
                <span>
                    Deployment Store: <span className="font-weight-bolder font-size-sm">{storeName}</span>
                </span> 
            }
            
            <br/>
            <br/>
            <span>
                Date of contract signing: <span className="font-weight-bolder font-size-sm">{dateOfSigning}</span>
            </span>
    		</Card.Header>
    	</Card>
	    <Accordion>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Update Employment Status
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <form onSubmit={(e) => handleStatusChangeClick(e)}>
                <select
                  className="form-control mb-5"
                  name="status"
                  onChange={e => handleHrStatusChange(e)}
                  required
                >
                  <option value="">Select Status</option>
                    <>
                      <option value="ACTIVELY DEPLOYED">ACTIVELY DEPLOYED</option>
                      <option value="ABANDONED">ABANDONED</option>
                      <option value="FACILITATED VRS">FACILITATED VRS</option>
                      <option value="MAT LEAVE">MAT LEAVE</option>
                    </>
                </select>

                <div>
                <span hidden={matShow}>
                    Start Date: <DatePicker className="font-size-sm form-control w-100 xs-12" selected={startDate} onChange={(date) => setStartDate(date)}/>
                    <br/><br/><span className="mr-3">End Date:</span> <DatePicker className="font-size-sm form-control w-100 xs-12" selected={endDate} onChange={(date) => setEndDate(date)}/>
                </span>
                <span hidden={abandon}>
                  Date: <DatePicker className="font-size-sm form-control w-100 xs-12" selected={date} onChange={(date) => setDate(date)}/>
                </span>
                </div>
                <Form.Group className="mt-3" controlId="formSection" hidden={facilitated}>
                      <Form.Label>HR Personnel</Form.Label>
                      <Form.Control type="text" placeholder="HR Personnel" onChange={(e) => setFacilitatedHr(e.target.value)} />
                  </Form.Group>
                <button
                  className="btn btn-primary font-weight-bolder font-size-sm mt-5 w-100"
                  type="submit"
                  disabled={isUploading}
                >
                {isUploading ? <CircularProgress color="primary" size={20} /> : 'Update'}
                </button>
              </form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Accordion>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Update Store Deployment
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <form onSubmit={(e) => handleStoreUpdateClick(e)}>
                <select
                  name="store"
                  className={`form-control mt-5 mb-5`}
                  onChange={e => handleStoreChange(e)}
                  value={storeId}
                  required              
                >
                  <option value="">Select Store Deployment</option>
                  {stores.map((v, k) => {
                      return (
                        <option key={k} value={v.id}>
                          {capitalizeName(v.name)}
                        </option>
                      )
                    })}
                </select>
                <span>
                  Date of Transfer: <DatePicker className="font-size-sm form-control w-100 xs-12" selected={dateOfTransfer} onChange={(date) => setDateOfTransfer(date)}/>
                </span>
                <button
                  className="btn btn-primary font-weight-bolder font-size-sm mt-5 w-100"
                  type="submit"
                  disabled={isUploading}
                >
                {isUploading ? <CircularProgress color="primary" size={20} /> : 'Update'}
                </button>
              </form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
        <Accordion>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Infractions and other Violations
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <form onSubmit={(e) => handleSanctionsClick(e)}>
                  <select
                    className="form-control mb-5"
                    name="status"
                    value={sanctions.sanction}
                    onChange={(e) => setSanctions({...sanctions, sanction: e.target.value})}
                    required
                  >
                    <option value="">Type of Sanction</option>
                      <>
                        <option value="Written Warning">Written Warning</option>
                        <option value="Verbal Warning">Verbal Warning</option>
                        <option value="Suspension">Suspension</option>
                        <option value="Termination VRS">Termination VRS</option>
                      </>
                  </select>
                  <Form.Group className="mb-3" controlId="formSection" >
                      <Form.Label>Section and Article</Form.Label>
                      <Form.Control type="text" placeholder="Section and Article" value={sanctions.section} onChange={(e) => setSanctions({...sanctions, section: e.target.value})} required/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formRemarks">
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control type="text" placeholder="Remarks" value={sanctions.remarks} onChange={(e) => setSanctions({...sanctions, remarks: e.target.value})} required/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formSection" >
                      <Form.Label>HR Personnel</Form.Label>
                      <Form.Control type="text" placeholder="HR Personnel" value={sanctions.hr} onChange={(e) => setSanctions({...sanctions, hr: e.target.value})} required/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formFileUpload">
                      <Form.Label >File Upload</Form.Label>
                      <br/>
                      <input ref={ref} className="form-control w-50" type="file" onChange={e => handleFileChange(e)} required multiple/>
                      
                  </Form.Group>
                  <button className="form-control w-100 mt-2 btn-primary" type="submit" disabled={isUploading}>
                    {isUploading ? <CircularProgress color="primary" size={20} /> : 'Submit'}
                    </button>
                </form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
    </>
  )
}
