import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import ViewPersonnelForm from './ViewPersonnelForm'
import Resume from 'components/resume'
import Loader from 'components/preloader'
import {getDocuments, getSpecificDocuments} from 'api/private/documents'
import {getSpecificApplicants} from 'api/private/applicants'
import PreviewCard from 'components/preview'
import 'style/_review.scss'
const ViewPersonnelPage = () => {
  const [previewData, setPreviewData] = useState([])
  const [loading, setLoading] = useState(true)
  const {id, company} = useParams()
  const [action, setAction] = useState()

  const previewApplicant = async () => {
    const r = await getSpecificApplicants(id, company)
    const data = []
    if (r.length > 0) {
      const s = await getSpecificDocuments(r[0].id, 'all')
      const profile = JSON.parse(r[0].data)
      data.push({
        name: `${profile.person_fname} ${profile.person_mname} ${profile.person_lname}`,
        perm_addr: `${profile.person_permanent_address_street_blk_pres} ${profile.person_permanent_address_street} ${profile.person_permanent_address_zipcode} ${profile.person_permanent_address_city}`,
        pres_addr: `${profile.person_present_address_street_blk} ${profile.person_present_address_street} ${profile.person_present_address_zipcode} ${profile.person_present_address_city}`,
        profile_img: r[0].profile,
        reference_id: r[0].reference_id,
        email: profile.person_email,
        contact: profile.person_contact_no_mob,
        sex: profile.person_sex,
        civil_status: profile.person_civil_status,
        religion: profile.person_rel,
        height: profile.person_ht,
        weight: profile.person_wt,
        blood_type: profile.person_bloodtype,
        tin: profile.person_tin,
        sss: profile.person_sss,
        phl: profile.person_phlhealth,
        pagibig: profile.person_pagibig,
        emerg_name: profile.person_emergency_name,
        emerg_contact: profile.person_emergency_contact_no,
        emerg_relationship: profile.person_emergency_relationship,
        nationality: profile.person_nationality,
        id: r[0].id,
        company: r[0].company,
        documents: s,
        status: ''
      })
      setLoading(false)
      setPreviewData(data)
      
    }
  }

  const genKey = Math.random()
    .toString(36)
    .substring(2)

  useEffect(() => {
    previewApplicant()
  }, [action])// eslint-disable-line react-hooks/exhaustive-deps
  function verify(value) {
    setAction(value)
  }
  return (
    <div className={`review_application-body ${loading ? `is-loading` : ``}`}>
      {!loading ? (
        <div className="review-container-fluid">
          <div className="row">
            <div className="col-5 form">
              <ViewPersonnelForm verify={verify}/>
            </div>
            <div className="col-7 preview">
              <PreviewCard previewData={previewData} type={5} pageType="personnel" key={genKey}></PreviewCard>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export {ViewPersonnelPage}
