import React, { useState } from 'react';
import SecureLS from 'secure-ls';
import { Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Button, 
    TextField, Alert } from '@mui/material';

const ls = new SecureLS({ encodingType: 'aes' });

const TrackingGADialog = ({ openTracking, handleCloseTracking, handleSaveGA }) => {
  const [trackingCode, setTrackingCode] = useState('');

  const handleSave = () => {
    
    // ls.set('gaTrackingCode', trackingCode)
    handleSaveGA(trackingCode)
    handleCloseTracking();
  };

  return (
    <div>
      <Dialog open={openTracking} onClose={handleCloseTracking}>
        <DialogTitle>Google Analytics Tracking Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="info" style={{cursor: 'help'}} title="Instructions">
              You need to create a Google Analytics account and get a tracking code for the website. Please follow these steps to get the tracking code: <br />
              1. Go to Google Analytics website and create an account: <a href="https://analytics.google.com/analytics/web/" target="_blank" rel="noopener noreferrer">https://analytics.google.com/analytics/web/</a><br />
              2. Click on the "Admin" button and select "Create Property" from the dropdown menu.<br />
              3. Select "Website" as the property type and enter the required information.<br />
              4. Click on the "Get Tracking ID" button to get the tracking code.
            </Alert>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="tracking-code"
            label="Tracking Code"
            type="text"
            fullWidth
            variant="outlined"
            value={trackingCode}
            onChange={(e) => setTrackingCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTracking}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TrackingGADialog;
