/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {NavLink} from 'react-router-dom'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl, checkIsActive} from '../../../../_helpers'

export function AsideMenuList({accessLevel, layoutProps}) {
  const location = useLocation()

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : ''
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Board */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Board</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        <li className={`menu-item ${getMenuItemActive('/board', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/board">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
            </span>
            <span className="menu-text">Recruitment</span>
          </NavLink>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/google-material', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Applicant</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Applicants</span>
                </span>
              </li>

              {/* New Applicants */}
              {/*begin::2 Level*/}
              <li className={`menu-item  ${getMenuItemActive('/applicants/new')}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/applicants/new">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">New Applicants</span>
                </NavLink>
              </li>

              {/*end::2 Level*/}

              {/* Recruitment Head Review */}
              {/*begin::2 Level*/}
              <li className={`menu-item  ${getMenuItemActive('/applicants/review')}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/applicants/review">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Recruitment Head Review</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Store Review */}
              {/*begin::2 Level*/}
              <li className={`menu-item  ${getMenuItemActive('/applicants/store/review')}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/applicants/store/review">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Store Review</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Search Applicants */}
              {/*begin::2 Level*/}
              <li className={`menu-item  ${getMenuItemActive('/applicants/search')}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/applicants/search">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Search Applicants</span>
                </NavLink>
              </li>
              <li className={`menu-item  ${getMenuItemActive('/applicants/fail')}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/applicants/fail">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Failed Applicants</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::Resume Pool*/}
        <li className={`menu-item ${getMenuItemActive('/applicants/pool', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/applicants/pool">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
            </span>
            <span className="menu-text">Resume Pool</span>
          </NavLink>
        </li>
        {/*end::Resume Pool*/}
        {accessLevel === 3 || accessLevel === 8 ? (
          <>
            {/*begin::Reports*/}
            <li className={`menu-item ${getMenuItemActive('/reports', false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/reports">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
                </span>
                <span className="menu-text">Reports</span>
              </NavLink>
            </li>
            {/*end::Reports*/}
            {accessLevel !== 9 
              ?
                <li className={`menu-item ${getMenuItemActive('/human-relations', false)}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/human-relations">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
                    </span>
                    <span className="menu-text">Human Relations</span>
                  </NavLink>
                </li>
              : 
                ''
            }
            
            {/*Store */}
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Store</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}

            {/*begin::Store List*/}
            <li className={`menu-item ${getMenuItemActive('/stores', false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/stores">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
                </span>
                <span className="menu-text">List</span>
              </NavLink>
            </li>
            {/*end::Store List*/}

            {/*begin:: Store Accounts*/}
            <li className={`menu-item ${getMenuItemActive('/store-accounts', false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/store-accounts">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
                </span>
                <span className="menu-text">Accounts</span>
              </NavLink>
            </li>
            {/*end::Store Accounts*/}

            {/*People */}
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">People</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}
          </>
        ) : (
          ''
        )}
        {/*begin::List*/}
        <li className={`menu-item ${getMenuItemActive('/people', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/people">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
            </span>
            <span className="menu-text">List</span>
          </NavLink>
        </li>
        {/*end::List*/}

        {/*Review */}
        <li className="menu-section ">
          <h4 className="menu-text">Review</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* begin::section */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/google-material', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Documents</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Documents</span>
                </span>
              </li>

              {/* New Applicants */}
              {/*begin::2 Level*/}
              <li className={`menu-item  ${getMenuItemActive('/documents/pending')}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/documents/pending">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Pending</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {accessLevel === 3 || accessLevel === 8 ? (
                <>
                  {/* Recruitment Head Review */}
                  {/*begin::2 Level*/}
                  <li className={`menu-item  ${getMenuItemActive('/documents/complete')}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/documents/complete">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Complete</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/* Training Review */}
                  {/*begin::3 Level*/}
                  <li className={`menu-item  ${getMenuItemActive('/documents/training')}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/documents/training">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Training</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                </>
              ) : (
                ''
              )}
            </ul>
          </div>
        </li>
        {/*end::Documents*/}

        {/*System */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">System</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::Emails*/}
        <li className={`menu-item ${getMenuItemActive('/system/email', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/system/email">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
            </span>
            <span className="menu-text">Emails</span>
          </NavLink>
        </li>
        {/*end::Emails*/}
        {/*begin::Applicant Logs*/}
        <li className={`menu-item ${getMenuItemActive('/system/applicants', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/system/applicants">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
            </span>
            <span className="menu-text">Applicant Logs</span>
          </NavLink>
        </li>
        {/*end::Applicant Logs*/}
        {/*begin::Exam Logs*/}
        <li className={`menu-item ${getMenuItemActive('/system/exams', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/system/exams">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
            </span>
            <span className="menu-text">Exam Logs</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/system/personnel-logs', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/system/personnel-logs">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
            </span>
            <span className="menu-text">Personnel Logs</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/system/crm-editor', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/system/crm-editor">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
            </span>
            <span className="menu-text">Crm Editor</span>
          </NavLink>
        </li>
        {accessLevel === 3 || accessLevel === 8  ? (
          <>
            {/*end::Exam Logs*/}
            {/*begin:: Settings*/}
            <li className={`menu-item ${getMenuItemActive('/system/settings', false)}`} aria-haspopup="true">
              {/* <NavLink className="menu-link" to="/SettingsPage">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
            </span>
            <span className="menu-text">Settings</span>
          </NavLink> */}

              <NavLink className="menu-link menu-toggle" to="/system/settings">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')} />
                </span>
                <span className="menu-text">Settings</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                      <span className="menu-text">Applicant</span>
                    </span>
                  </li>

                  {/* Jobs & Exams */}
                  {/*begin::2 Level*/}
                  <>
                    <li className={`menu-item  ${getMenuItemActive('/system/settings/job')}`} aria-haspopup="true">
                      <NavLink className="menu-link" to="/system/settings/jobs">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Jobs and Exams</span>
                      </NavLink>
                    </li>

                    {/*end::2 Level*/}
                  </>
                </ul>
              </div>
            </li>

            {/*end:: Settings*/}
          </>
        ) : (
          ''
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
