import React from 'react'
import 'style/_review.scss'

const HistoryFamily = ({d}) => {
  return (
    <div className="form-group">
      <h2>Family Medical History</h2>
      <hr />
      <table>
        <tbody>
          <div className="break-print spacing medExamContainer">
            <h4>Family History</h4>
            <div className="row mt-3">
              <div className="col-md-6">
                <table className="family-medical">
                  <tbody>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_adopted !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_adopted !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_adopted"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Adopted no knowledge</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_alcohol_abuse !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_alcohol_abuse !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_alcohol_abuse"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Alcohol Abuse</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.illness_measles !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.illness_measles !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_anemia"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Anemia</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.illness_measles !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.illness_measles !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_anesthetic"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Anesthetic Complication</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_anxiety !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_anxiety !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_anxiety"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Anxiety Disorder</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_athritis !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_athritis !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_athritis"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Athritis</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_bladdeProblems !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_bladdeProblems !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_bladdeProblems"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Bladder Problems</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_bleedingDisease !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_bleedingDisease !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_bleedingDisease"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Bleeding Disease</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_cancer !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_cancer !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_cancer"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Cancer</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_depresion !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_depresion !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_depresion"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Depression</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_diabetes !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_diabetes !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_diabetes"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Diabetes</label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="family-medical">
                  <tbody>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_heartDisease !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_heartDisease !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_heartDisease"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Heart Disease</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_highBlood !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_highBlood !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_highBlood"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">High Blood Pressure</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_cholesterol !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_cholesterol !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_cholesterol"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">High Cholesterol</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_kidneyDisease !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_kidneyDisease !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_kidneyDisease"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Kidney Disease</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_leukemia !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_leukemia !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_leukemia"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Leukemia</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_respiratoryDisease !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_respiratoryDisease !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_respiratoryDisease"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Lung/ Respiratory Disease</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_migraine !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_migraine !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_migraine"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Migrane</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_osteoporosis !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_osteoporosis !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_osteoporosis"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Osteoporosis</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_seizures !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_seizures !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_seizures"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Seizures / Convulsion</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_severAllergy !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_severAllergy !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_severAllergy"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Severe Allergy</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.fam_HISTORY_stroke !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.fam_HISTORY_stroke !== undefined ? `checked` : ``}`}
                          name="fam_HISTORY_stroke"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Stroke</label>
                      </td>
                    </tr>
                    <tr className="form-group">
                      <td>
                        <input
                          type="checkbox"
                          disabled={`${d.famHISTORY_thyroid !== undefined ? `disabled` : `readonly`}`}
                          checked={`${d.famHISTORY_thyroid !== undefined ? `checked` : ``}`}
                          name="famHISTORY_thyroid"
                          className="form-control inline-chkbox "
                        />
                        <label className="control-label">Thyroid Problems</label>
                      </td>
                    </tr>
                    {!d.family_agree_no_hospitalization ? (
                      <tr className="form-group" key="family_agree_no_hospitalization">
                        <td>
                          <input
                            type="checkbox"
                            name="family_agree_no_hospitalization"
                            disabled={`${d.family_agree_no_hospitalization !== undefined ? `disabled` : `readonly`}`}
                            checked={`${d.family_agree_no_hospitalization !== undefined ? `checked` : ``}`}
                            className="form-control inline-chkbox "
                          />
                          <label className="control-label">
                            Agreed - I hereby declare that I do not have medically relevant recollections (symptoms,
                            concerns and past diseases), hospitalization, known surgery and on-going medication from any
                            of above stated medical conditions.
                          </label>
                        </td>
                      </tr>
                    ) : (
                      ''
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </tbody>
      </table>
      <br />
      <br />
      {d.family_agree_no_hospitalization ? (
        <table>
          <tbody>
            <tr className="form-group" key="family_agree_no_hospitalization">
              <td>
                <label className="control-label">
                  Agreed - I hereby declare that I do not have medically relevant recollections (symptoms, concerns and
                  past diseases), hospitalization, known surgery and on-going medication from any of above stated
                  medical conditions.
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        ''
      )}
      {d.family_agree_no_history ? (
        <table>
          <tbody>
            <tr className="form-group" key="family_agree_no_history">
              <td>
                <label className="control-label">
                  Agreed - I hereby declare that I DO NOT KNOW /NOT SURE of the medical history of my family
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        ''
      )}
      <br />
      <br />
    </div>
  )
}
export default HistoryFamily
