import React, { useContext, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Box } from '@mui/material'
import { motion } from 'framer-motion'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import  ImageWithFallback  from 'components/crm/image/ImageWithFallback';

import ImageUploadDialog from '../../../../image/ImageWithDialog';
import defaultBackgroundImage from '../../assets/about-us.jpg'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ encodingType: 'aes' })

const HeaderContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
}))


const Title = styled(motion(Typography))(({ theme }) => ({
  position: 'relative',
  color: '#ffffff',
  fontWeight: 700,
  zIndex: 1,
  fontSize: '2.5rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '4rem',
  },
}))

export default function ApplyHero({data}) {
  const context = useContext(EditModeContext);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState([]);
  const [textFields, setTextFields] = useState([]);
  const [dialogValue, setDialogValue] = useState('');
  const [openDialogIndex, setOpenDialogIndex] = useState(null);

  const [parentHover, setParentHover] = useState(false);
  const [childHover, setChildHover] = useState(null);

  const { isEditMode } = context || {};


  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true); // Open dialog on image click
  };

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]); // Load the value of the clicked Typography into the dialog
    setOpenDialogIndex(index);
    setSelectedImageIndex(null);
    setImageDialogOpen(false); // Open dialog on image click
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };


  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);
    ls.set('crmApplyHeroText', newValues[0]);
  };

  const handleImageUpload = (newImageUrl) => {
    const updatedImages = [...imageUrl];
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrl(updatedImages);
    ls.set('crmApplyHeroImage', newImageUrl); // Save the new image in local storage
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false); // Close dialog
  };


  useEffect(() => {
    const passedData = data || {}; 
  
    const storedText = ls.get('crmApplyHeroText');
    const heroTitle = storedText || passedData?.title || 'Application Form'; 
  
    const storedImage = ls.get('crmApplyHeroImage');
    const parsedImage = storedImage || passedData?.backgroundImage || defaultBackgroundImage;
  
    setTextFields([heroTitle]);
    setImageUrl([parsedImage]);
  }, [data]);

  return (
    <>
      <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(0)} hover={parentHover && !childHover} onMouseEnter={() => setParentHover(true)} onMouseLeave={() => setParentHover(false)} editType={'image'}>
        <HeaderContainer>
          <ImageWithFallback
            src={imageUrl[0]}
            fallbackSrc={defaultBackgroundImage}
            alt="About Us Hero"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)} hover={childHover === 1} onMouseEnter={() => setChildHover(1)} onMouseLeave={() => setChildHover(null)} editType={'text'}>
            <Title
              variant="h1"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.55 }}             
            >
              {textFields[0]}
            </Title>
          </EditableItemWrapper>
        </HeaderContainer>
      </EditableItemWrapper>

      <ImageUploadDialog
        open={imageDialogOpen}
        onClose={handleImageDialogClose}
        onUpload={handleImageUpload}
        currentImage={imageUrl[selectedImageIndex]}
      />
       <EditTextDialog
        open={openDialogIndex !== null}
        value={dialogValue}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onChange={setDialogValue}
      />
    </>
  )
}