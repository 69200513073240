import React, {useState, useEffect} from 'react'
import {getEmails, postResendEmail, patchChangeEmail} from 'api/private/emails'
import {getHrPersonnel, getHrPersonnelSpecific} from 'api/private/recruitment'
import {getStores} from 'api/private/store'
//import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Table from 'react-bootstrap-table-next'
import PaginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit'
import {Modal} from 'react-bootstrap'
import Alert from '@mui/material/Alert';
import DialogModal from 'components/dialog'
import SecureLS from 'secure-ls'
import {getDocuments, getSpecificDocuments} from 'api/private/documents'
import {getSpecificApplicants} from 'api/private/applicants'
import {Link} from 'react-router-dom'
import {getStoreDetails, updateApplicantStore, updateApplicantStatus} from 'api/private/store'
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Loader from 'components/preloader'

const ls = new SecureLS({encodingType: 'aes'})

const {SearchBar} = Search
var dayjs = require('dayjs')
const HumanRelationsPage = props => {
  const [data, setData] = useState({
    personnel: [],
    loading: false,
    filterType: [],
    selectedType: '',
    selectedStatus: '',
    storeName: ''
  })

  const [email, setEmail] = useState([
    {
      id: '',
      email: '',
      modal: false,
    },
  ])


  const [stores, setStores] = useState([])
  
  const [previewData, setPreviewData] = useState([])

  const [storeName, setStoreName] = useState('Select Store')

  const [specificStoreName, setSpecificStoreName] = useState('')

  const [oldStoreName, setOldStoreName] = useState('')

  const [loading, setLoading] = useState(false)

  const [name, setName] = useState('')

  const listPersonnel = async (store) => {
    setLoading(true)
    let company = ls.get('company')
    const r = await getHrPersonnel(store, company)
    if (r.data) {
      let dt = []
      let store = ''
      let oldStore = ''
      await Promise.all(
      r.data.map(async d => {
        const s = await getStoreDetails(d.applicant_id)
        if (s.status === 200) {
          if (s.store_name.oldStoreName) {
            store = s.store_name.oldStoreName.name + " -> " + s.store_name.storeName.name
          } 
          else {
            store = s.store_name.storeName.name
          }   
        }
        if (d.date === null && d.start_date === null) {
          return dt.push({
            applicant_id: d.applicant_id,
            applicant_name: d.applicant_name,
            company: d.company,
            status: d.status,
            storeId: store,
            date_hired: d.date_hired,
          })  
        }
        else if (d.date === null) {
          return dt.push({
            applicant_id: d.applicant_id,
            applicant_name: d.applicant_name,
            company: d.company,
            status: d.status,
            //status: d.status + " ( " + d.start_date + " <- TO -> " + d.end_date + " )",
            storeId: store,
            date_hired: d.date_hired,
          })
        }
        else {
          return dt.push({
            applicant_id: d.applicant_id,
            applicant_name: d.applicant_name,
            company: d.company,
            status: d.status,
            //status: (d.hr === "" || d.hr === null ? d.status + " ( " + d.date + " )" : d.status + " ( " + d.date + " ) -> ( " + d.hr + " )") ,
            storeId: store,
            date_hired: d.date_hired,
          }) 
        }
        
      }))

      setData({...data, personnel: dt, loading: false, filterType: [...new Set(dt.map(o => o.type))]})
      setLoading(false)
    }
    else {
      let dt = []
      setData({...data, personnel: dt, loading: false, filterType: [...new Set(dt.map(o => o.type))]})
      setLoading(false)
    }
  }

  const getStoreList = async () => {
    const r = await getStores()
    if (r) {
      setStores(r)
    }
  }

  const handleClickPersonnel = (status, date) => {
    ls.set('status', status)
    ls.set('date_signing', date)
  }

  useEffect(() => {
    getStoreList()
  },[])

  const handleStatusChange = async e => {
    e.preventDefault()
    setStoreName(e.target.selectedOptions[0].getAttribute('data-name'))
    listPersonnel(e.target.value)
  }

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ]

  const selectOptions = {
    'HIRED': 'HIRED',
    'ACTIVELY DEPLOYED': "ACTIVELY DEPLOYED",
    'ABANDONED': 'ABANDONED',
    'FACILITATED VRS': 'FACILITATED VRS',
    'MAT LEAVE': 'MAT LEAVE'
  };

  const columns = [
    {
      dataField: 'applicant_id',
      text: 'Applicant ID',
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
      headerStyle: { 'text-align': 'center', 'padding-bottom': '58px' }
    },
    {
      dataField: 'applicant_name',
      text: 'Applicant Name',
      headerStyle: { 'text-align': 'center', 'padding-bottom': '58px' }
    },
    {
      dataField: 'date_hired',
      text: 'Date of Contract Signing',
      headerStyle: { 'text-align': 'center', 'padding-bottom': '38px' }
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: { 'text-align': 'center'},
      filter: selectFilter({
        options: selectOptions
      }),
    },
    {
      dataField: 'company',
      text: 'Company',
      headerStyle: { 'text-align': 'center', 'padding-bottom': '58px' }
    },
    {
      dataField: 'storeId',
      text: 'Store',
      headerStyle: { 'text-align': 'center', 'padding-bottom': '58px' }
    },
    {
      dataField: 'df1',
      text: 'Actions',
      formatter: (cellContent, row) => (
        <>
          <Link
            to={`/personnel/view/${row.applicant_id}/${row.company}`}
            target="_blank"
            className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
            onClick={() => handleClickPersonnel(row.status, row.date_hired)}
          >
            View Application
          </Link>
        </>
      ),
      headerStyle: { 'text-align': 'center', 'padding-bottom': '58px' }
    },
  ]

  const handleSearchPersonnel = async () => {
    setLoading(true)
    const r = await getHrPersonnelSpecific(name)
    if (r.data) {
      setLoading(false)
      let dt = []
      let store = ''
      let oldStore = ''
      await Promise.all(
      r.data.map(async d => {
        console.log(d.status)
        const s = await getStoreDetails(d.applicant_id)
        if (s.status === 200) {
          if (s.store_name.oldStoreName) {
            store = s.store_name.oldStoreName.name + " -> " + s.store_name.storeName.name
          } 
          else {
            store = s.store_name.storeName.name
          }   
        }
        if (d.date === null && d.start_date === null) {
          return dt.push({
            applicant_id: d.applicant_id,
            applicant_name: d.applicant_name,
            company: d.company,
            status: d.status,
            storeId: store,
            date_hired: d.date_hired,
          })  
        }
        else if (d.date === null) {
          return dt.push({
            applicant_id: d.applicant_id,
            applicant_name: d.applicant_name,
            company: d.company,
            //status: d.status + " ( " + d.start_date + " <- TO -> " + d.end_date + " )",
            status: d.status,
            storeId: store,
            date_hired: d.date_hired,
          })
        }
        else {
          return dt.push({
            applicant_id: d.applicant_id,
            applicant_name: d.applicant_name,
            company: d.company,
            status: d.status,
            //status: (d.hr === "" || d.hr === null ? d.status + " ( " + d.date + " )" : d.status + " ( " + d.date + " ) -> ( " + d.hr + " )") ,
            storeId: store,
            date_hired: d.date_hired,
          }) 
        }
        
      }))

      setData({...data, personnel: dt, loading: false, filterType: [...new Set(dt.map(o => o.type))]})
    }
    else {
      setLoading(false)
      let dt = []
      setData({...data, personnel: dt, loading: false, filterType: [...new Set(dt.map(o => o.type))]})
    }
  }

  return (
    <div>
      <ToolkitProvider
        keyField="id"
        data={data.personnel}
        columns={columns}
        search
        defaultSorted={defaultSorted}
        pagination={PaginationFactory()}
      >
        {props => (
          <div>
            <div className="d-flex aling-items-center">
              <select
                className="form-control mx-2 w-25"
                name="type"
                defaultValue={stores.name ? stores.name : ''}
                placeholder="Filter by Type"
                onChange={e => handleStatusChange(e)}
              >
                <option value="">Select Store</option>
                {stores && stores.length > 0
                  ? stores.map((d, k) => {
                      return (
                        <option key={k} value={d.id} data-name={d.name}>
                          {d.name}
                        </option>
                      )
                    })
                  : ''}
              </select>
              <input
                id="search"
                type="text"
                placeholder="Search By Name"
                aria-label="Search Input"
                //value={name}
                onChange={(e) => setName(e.target.value)}
                key="filterName"
                autoFocus={true}
                className="form-control mx-2 w-25"
              />
              <button type="button" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={handleSearchPersonnel}>
                <i className="fa fa-search"></i>
              </button>
              {/*<span className="mt-3 font-weight-bolder font-size-sm">{storeName}</span>*/}
            </div>
            <hr />
            {loading? <Loader/>:  <Table defaultSorted={defaultSorted} pagination={PaginationFactory()} {...props.baseProps} columns={ columns } filter={filterFactory()}/>}
          </div>
        )}
      </ToolkitProvider>
    </div>
  )
}

HumanRelationsPage.propTypes = {}

export {HumanRelationsPage}
