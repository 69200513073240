import React, { useState, useContext } from 'react';
import { Typography, Box } from '@mui/material';
import { EditModeContext } from 'context/EditModeContext';

export const EditableItemWrapper = ({ sx, children, onClick, onDoubleClick, hover, setHover, onMouseEnter, onMouseLeave, editType, withoutLabel }) => {
  const context = useContext(EditModeContext);
  const isEditMode = context?.isEditMode || false;

  const handleClick = (event) => {
    event.stopPropagation();
    onClick(event);
  };

  const handleMouseEnter = (event) => {
    onMouseEnter(event);
  };

  const handleMouseLeave = (event) => {
    onMouseLeave(event);
  };

  return (
    <Box
      onClick={isEditMode ? handleClick : undefined}
      onDoubleClick={isEditMode ? onDoubleClick : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        cursor: isEditMode ? 'pointer' : 'default',
        backgroundColor: isEditMode && hover ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
        padding: '4px',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
        zIndex: 2,
        position: 'relative',
        border: isEditMode ? '3px dashed rgba(0, 0, 0, 0.8)' : 'none',
        minHeight: '50px',
        ...sx,
      }}
    >
      {isEditMode && !withoutLabel && hover && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            padding: '2px 4px',
            borderRadius: '0 4px 0 4px',
            fontSize: '12px',
            zIndex: 2,
          }}
        >
          {editType ? `Edit ${editType}` : 'Edit'}
        </Box>
      )}
      {children}
    </Box>
  );
};

