import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MainLayout from 'components/crm/templates/first-template/layout';
import { fetchAppliedLayout } from 'api/private/crm'; // Import the fetch API call
import SecureLS from 'secure-ls';

export const PublishPage = ({ subdomainLayoutData }) => {
  const layoutData = JSON.parse(localStorage.getItem('layoutData')) || subdomainLayoutData || {}

  const theme = createTheme({
    palette: {
      primary: {
        main: layoutData.primaryColor || '#000000',
      },
      secondary: {
        main: layoutData.secondaryColor || '#ffffff',
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <MainLayout
        headerData={layoutData.header}
        footerData={layoutData.footer}
        metaTagsData={layoutData.meta}
        homePageData={layoutData.homePage}
        careerPageData={layoutData.careerPage}
        contactPageData={layoutData.contactPage}
        newsPageData={layoutData.newsPage}
        aboutPageData={layoutData.aboutPage}
        applyPageData={layoutData.applyPage}
        published={true}
      />
    </ThemeProvider>
  )
};
