import {post, patch, get} from 'api/utils/requests'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})
const uid = ls.get('uid')

const RT_ASSES_URL = `internal/record/review_app`
const ST_ASSES_URL = `internal/record/review_store_app`
const ADMIN_STATUS_URL = `internal/record/bypass`
const HR_PERSONNEL_TRANSFER = `internal/hr/create`
const HR_PERSONNEL_GET = `internal/hr/personnel`
const HR_PERSONNEL_SPECIFIC_GET = `internal/personnel/specific`

export const postRecruiterAssessment = async data => {
  const compress = {
    data: JSON.stringify(data),
    id: data.id,
    company: data.company,
    refernce_id: data.refernce_id,
    reviewer: uid,
    store: data.assess_deployment_store,
    review: data.assess_evaluation,
    assess_evaluation: data.assess_evaluation,
  }
  return await post(compress, RT_ASSES_URL)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const postStoreAssesment = async data => {
  const compress = {
    store_assess: JSON.stringify({...data, store: data.store}),
    id: data.id,
    company: data.company,
    review_status: data.assess_evaluation,
    reviewer: uid,
  }
  return await post(compress, ST_ASSES_URL)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const patchAdminStatusChange = async data => {
  let _url = `${ADMIN_STATUS_URL}`
  return await patch(data, _url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const postHrPersonnel = async data => {
  return await post(data, HR_PERSONNEL_TRANSFER)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getHrPersonnel = async (store, company) => {
  let _url = `${HR_PERSONNEL_GET}/${store}/${company}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getHrPersonnelSpecific = async name => {
  let _url = `${HR_PERSONNEL_SPECIFIC_GET}/${name}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}