import {get, patch, post} from 'api/utils/requests'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})

//const userData = ls.get('ud') ? JSON.parse(ls.get('ud')) : ''
const company = ls.get('company')

const EMAIL_URL = `internal/emails/`
const EMAIL_RESEND_URL = `internal/resend/email`
const EMAIL_CHANGE_URL = `internal/system/update/email`

export const getEmails = async () => {
  let _url = `${EMAIL_URL}${company}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const patchChangeEmail = async data => {
  let _url = `${EMAIL_CHANGE_URL}`
  return await patch(data, _url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const postResendEmail = async data => {
  let _url = `${EMAIL_RESEND_URL}`
  return await post(data, _url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}
