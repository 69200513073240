import React from 'react'
import 'style/_review.scss'

const ResumePersonal = ({dt}) => {
  return (
    <div className="form-group">
      <h2>Personal Information</h2>
      <hr />
      <div className="row">
        <div className="col-md-6">
          <table>
            <tbody>
              <tr>
                <td width="200">Sex:</td>
                <td className="font-weight-bold text-uppercase">{`${dt.sex}`}</td>
              </tr>
              <tr>
                <td width="200">Civil Status:</td>
                <td className="font-weight-bold text-uppercase">{`${dt.civil_status}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-6 pl-0">
          <b>Goverment Id's</b>
          <table>
            <tbody>
              <tr>
                <td width="200">TIN:</td>
                <td className="font-weight-bold text-uppercase">{`${dt.tin}`}</td>
              </tr>
              <tr>
                <td width="200">PHILHealth:</td>
                <td className="font-weight-bold text-uppercase">{`${dt.phil_health}`}</td>
              </tr>
              <tr>
                <td width="200">SSS:</td>
                <td className="font-weight-bold text-uppercase">{`${dt.sss}`}</td>
              </tr>
              <tr>
                <td width="200">PAGIBIG:</td>
                <td className="font-weight-bold text-uppercase">{`${dt.pagibig}`}</td>
              </tr>
            </tbody>
          </table>
          <b>Emergency Contact</b>
          <table>
            <tbody>
              <tr>
                <td width="200">Name:</td>
                <td className="font-weight-bold text-uppercase">{`${dt.emerg_name}`}</td>
              </tr>
              <tr>
                <td width="200">Contact No. / Mobile No:</td>
                <td className="font-weight-bold text-uppercase">{`${dt.emerg_contact}`}</td>
              </tr>
              <tr>
                <td width="200">Relationship:</td>
                <td className="font-weight-bold text-uppercase">{`${dt.emerg_relationship}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default ResumePersonal
