import React from 'react'
import 'style/_review.scss'

const ResumeHeader = ({dt}) => {
  return (
    <div className="resume_header">
      <div className="form-group text-center">
        <img className="mt-5 mb-3" src={`${dt.img}`} width="150" height="150" alt={`${dt.name}`} />
        <h3 className=" mt-2">{`${dt.name}`}</h3>
        <div className="d-flex justify-content-center">
          <p className="mr-2 mb-0">
            Email: <b>{`${dt.email}`}</b>
          </p>
          <p className="mb-0">
            Contact No: <b>{`${dt.number}`}</b>
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <p>{dt.pres_addr && dt.pres_addr.length > 0 ? `Permanent Address:${dt.pres_addr}` : ''}</p>
          <p>{dt.perm_addr && dt.perm_addr.length > 0 ? `Present Address: ${dt.perm_addr}` : ''}</p>
        </div>
      </div>
    </div>
  )
}

export default ResumeHeader
