/**
 * High level router.
 *
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { Layout } from '../_metronic/layout'
import BasePage from './BasePage'
import { Logout, AuthPage } from './modules/Auth'
import { PreviewPage } from './pages/crm/preview'
import { PublishPage } from './pages/crm/published'
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage'
import { getPublished } from 'api/private/crm'
export function Routes() {
  const [isPublished, setIsPublished] = useState(true)
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual,
  )

  useEffect(() => {
    const fetching = async () => {
      try {
        const result = await getPublished()
        if (result.body.isPublished) {
          setIsPublished(result.body.isPublished)
        }
      } catch (error) {
        console.error('Error fetching published status:', error)
        setIsPublished(false)
      }
    }
    fetching()
  }, [])


  return (
    <Switch>

      {isPublished && <Route exact path="/home" component={PublishPage} />}
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}
      <Route path="/preview" component={PreviewPage} />
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  )
}
