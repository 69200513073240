import React from 'react';
import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Button, 
    Box,
    Alert 
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChatIcon from '@mui/icons-material/Chat';

const OpenChat = ({ openChatMode, handleCloseChat }) => {
  return (
    <Dialog open={openChatMode} onClose={handleCloseChat}>
      <DialogTitle>
        Contact Support
      </DialogTitle>
      <DialogContent>
        <Alert severity="info" style={{ cursor: 'help' }} title="Info">
          If you have any questions or need assistance, feel free to reach out to our support team through the chat options below.
        </Alert>
        <Box style={{ padding: 20, backgroundColor: 'white', margin: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <a 
              href="https://wa.me/1234567890?text=Hello!" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <WhatsAppIcon /> Chat on WhatsApp
            </a>
            <a 
              href="https://www.facebook.com/messages/t/102802262670743" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <ChatIcon /> Chat on Messenger
            </a>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseChat}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OpenChat;
