import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import { styled } from '@mui/material/styles';
import {useParams} from 'react-router-dom'
import DialogModal from '../../../../components/dialog'
import {postStoreAssesment} from 'api/private/recruitment'
const useStyles1 = styled(theme => ({
  button: {
    width: '100%',
    color: ' #FFF',
    'background-color': '#3783e7',
    'border-color': 'transparent',
    'font-weight': 'bold',
    'text-transform': 'uppercase',
  },
}))

const StoreAssessment = ({store, details, recruitment, viewType}) => {
  const classes1 = useStyles1()
  const {id, company} = useParams()
  // const [viewType] = useState(true)
  const [edit, setEditData] = useState({})
  const [process, setProcess] = useState({
    loading: false,
    success: null,
    changeSuccess: null,
  })
  const handleClose = () => {
    setProcess({...process, success: false, loading: false})
    window.location.reload()
  }

  const handleStoreAssessment = async e => {
    e.preventDefault()

    let d = {
      id: id,
      company: company,
      store: recruitment.assess_deployment_store,
      ...edit,
    }

    await setProcess({
      loading: true,
    })

    const r = await postStoreAssesment(d)
    if (r) {
      await setProcess({
        loading: false,
        success: true,
      })
      setTimeout(process.success, 700)
    } else {
      await setProcess({
        loading: false,
      })
    }
  }

  return (
    <>
      <DialogModal
        show={process.success | false}
        hide={handleClose}
        title={`Store Assessment Complete!`}
        content={`Kindly notify the applicant to proceed with documents uploading process.`}
        _type={``}
      ></DialogModal>

      <DialogModal
        show={process.changeSuccess | false}
        hide={handleClose}
        title={`Change of Status Success!`}
        content={`Applicant's recruitment status is changed.`}
        _type={``}
      ></DialogModal>

      <form onSubmit={handleStoreAssessment}>
        <div action="" id="form-assessment-store">
          <div className="row">
            <div className="col-lg-12">
              <h6 className="mb-1">Evaluation</h6>
            </div>
            <div className="col-lg-12 col-md-6 col-sm-12 col-xs-12">
              <div className="form-group">
                <label className="control-label"> Name of Team Supervisor </label>
                <input
                  className="form-control"
                  type="text"
                  name="assess_ts_name"
                  onChange={e => setEditData({...edit, assess_ts_name: e.target.value})}
                  value={store ? store.assess_ts_name : ''}
                  disabled={viewType | false}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-6 col-sm-12 col-xs-12">
              <div className="form-group">
                <label className="control-label"> Station </label>
                <input
                  className="form-control"
                  type="text"
                  name="assess_store_station"
                  onChange={e => setEditData({...edit, assess_store_station: e.target.value})}
                  value={store ? store.assess_store_station : ''}
                  disabled={viewType | false}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-6 col-sm-12 col-xs-12">
              <div className="form-group">
                <label className="control-label"> Recommended By (Manager on Duty) </label>
                <input
                  className="form-control"
                  type="text"
                  name="assess_reviewed_by"
                  onChange={e => setEditData({...edit, assess_reviewed_by: e.target.value})}
                  value={store ? store.assess_reviewed_by : ''}
                  disabled={viewType | false}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <label className="control-label"> Result </label>
                <select
                  name="assess_evaluation"
                  onChange={e => setEditData({...edit, assess_evaluation: e.target.value})}
                  className="form-control"
                  value={store.assess_evaluation}
                  disabled={viewType | false}
                  required=""
                >
                  <option value="">- Rate -</option>
                  <option value="1">Passed</option>
                  <option value="2">Failed</option>
                </select>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <label className="control-label">Remarks</label>
                <textarea
                  className="form-control"
                  name="asseess_remarks"
                  rows="10"
                  columns="30"
                  required="required"
                  onChange={e => setEditData({...edit, asseess_remarks: e.target.value})}
                  value={store ? store.asseess_remarks : ''}
                  disabled={viewType | false}
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-6 col-sm-12 col-xs-12">
              <div className="form-group">
                <label className="control-label">Person Confirmed By: </label>
                <input
                  className="form-control"
                  type="text"
                  name="assess_reviewed_by"
                  onChange={e => setEditData({...edit, assess_name_confirmation: e.target.value})}
                  value={store ? store.assess_name_confirmation : ''}
                  disabled={viewType | false}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-6 col-sm-12 col-xs-12">
              <div className="form-group">
                {!viewType ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className={classes1.button}
                    disabled={process.loading}
                  >
                    Submit Store Assessment
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default StoreAssessment
