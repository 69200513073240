import React, {useEffect, useState} from 'react'
import {ApexAdminDashboardPage} from './ApexAdminDashboardPage'
import getData from './functions/getData'

const ApexDashboardPageContainer = () => {
  let [data, setData] = useState({
    loading: false,
    data: {},
  })

  useEffect(() => {
    setData({
      ...data,
      loading: true,
    })

    getData(
      data => {
        setData({
          ...data,
          data: data,
          loading: false,
        })
      },
      error => {
        alert(error)
      },
    )
  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  return <ApexAdminDashboardPage {...data} />
}

export default ApexDashboardPageContainer
