import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

const ImageWithFallback = ({ src, alt, fallbackSrc, sx, onMouseEnter, onMouseLeave, ...props}) => {
  const [loading, setLoading] = useState(true);   // Manage loading state
  const [error, setError] = useState(false);      // Manage error state

  // Handler when image is loaded successfully
  const handleLoad = () => {
    setLoading(false);
  };

  // Handler when image fails to load
  const handleError = () => {
    setLoading(false);
    setError(true);
  };

  useEffect(() => {
    if (!src) {
      return;
    }
    setLoading(true);
    setError(false);
  }, [src]);


  return (
    <Box
      position="relative"
      width={300}
      height={300}
      sx={sx}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    > {/* Container for image */}
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          position="absolute"
          top={0}
          left={0}
        >
          <CircularProgress />  {/* Circular progress displayed while loading */}
        </Box>
      )}
      <img
        src={error ? fallbackSrc : src}
        alt={alt}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover', // Prevent image from stretching
          display: loading ? 'none' : 'block',
        }}
        onLoad={handleLoad}
        onError={handleError}
        {...props}
      />
    </Box>
  );
};

export default ImageWithFallback;

