import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import ViewApplicantForm from './ViewApplicantForm'
import Resume from 'components/resume'
import Loader from 'components/preloader'

import {getSpecificApplicants} from 'api/private/applicants'
import 'style/_review.scss'
const ViewApplicantPage = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const {id, company} = useParams()

  const getApplicant = async () => {
    const r = await getSpecificApplicants(id, company)
    const formatData = []
    if (r.length > 0) {
      const profile = JSON.parse(r[0].data)
      formatData.push({
        name: `${profile.person_fname} ${profile.person_mname} ${profile.person_lname}`,
        perm_addr: `${profile.person_permanent_address_street} ${profile.person_permanent_address_zipcode} ${profile.person_permanent_address_city}`,
        pres_addr: `${profile.person_present_address_street} ${profile.person_present_address_zipcode} ${profile.person_present_address_city}`,
        profile_img: r[0].profile,
        reference_id: r[0].reference_id,
        email: profile.person_email,
        contact: profile.person_contact_no_mob,
        sex: profile.person_sex,
        civil_status: profile.person_civil_status,
        religion: profile.person_rel,
        height: profile.person_ht,
        weight: profile.person_wt,
        blood_type: profile.person_bloodtype,
        tin: profile.person_tin,
        sss: profile.person_sss,
        phl: profile.person_phlhealth,
        pagibig: profile.person_pagibig,
        emerg_name: profile.person_emergency_name,
        emerg_contact: profile.person_emergency_contact_no,
        emerg_relationship: profile.person_emergency_relationship,
        nationality: profile.person_nationality,
        last_menstruation_date: profile.last_menstruation_date,
        id: r[0].id,
        company: r[0].company,
        ...profile,
      })
      setLoading(false)
      setData(formatData)
    }
  }
  useEffect(() => {
    getApplicant()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`review_application-body ${loading ? `is-loading` : ``}`}>
      {!loading ? (
        <div className="review-container-fluid">
          <div className="row">
            <div className="col-5 form">
              <ViewApplicantForm details={data} />
            </div>
            <div className="col-7 preview">
              <Resume details={data} />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export {ViewApplicantPage}
