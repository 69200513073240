import React, {useState, useEffect} from 'react'
import {getStoreAccounts, postStoreAccountReset} from 'api/private/store'
import Table from 'react-bootstrap-table-next'
import PaginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit'
import DialogModal from 'components/dialog'
const {SearchBar} = Search
var dayjs = require('dayjs')
const StoreAccountsPage = () => {
  const [data, setData] = useState({
    stores: [],
    loading: false,
    success: null,
  })
  const [show, setShow] = useState(false)
  const [req, setRequest] = useState({
    id: 0,
  })

  const capitalizeName = str => {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

  const listStores = async () => {
    const r = await getStoreAccounts()
    setData({...data, loading: true})
    if (r.length > 0) {
      let dt = []
      r.map(d =>
        dt.push({
          id: d.id,
          usr_id: d.usr_id,
          store_name: capitalizeName(d.name.toLowerCase()),
          email: d.email,
          password: d.temp_password,
          company: d.company,
          createdAt: dayjs(d.date_created).format('MMMM-DD-YYYY h:m:s a'),
        }),
      )
      setData({...data, stores: dt, loading: false})
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = _id => {
    setShow(true)
    setRequest({...req, id: _id})
  }

  const handleSubmit = async () => {
    req.id = null
    if (req.id === null) {
      await setData({...data, loading: false, success: false})
    }

    const r = await postStoreAccountReset(req)
    handleClose()
    if (r === undefined) {
      await setData({...data, loading: false, success: false})
    } else {
      await setData({...data, loading: false, success: true})
    }
  }

  useEffect(() => {
    listStores()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ]

  const columns = [
    {
      dataField: 'id',
      text: '-',
      sort: true,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
    {
      dataField: 'store_name',
      text: 'Store Name',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'password',
      text: 'Password',
    },
    {
      dataField: 'createdAt',
      text: 'Date Posted',
      sort: true,
    },
    {
      dataField: 'df1',
      text: '',
      formatter: (cellContent, row) => (
        <button type="button" className="btn btn-primary" onClick={() => handleShow(row.usr_id)}>
          Reset Password
        </button>
      ),
    },
  ]

  return (
    <div>
      <ToolkitProvider
        keyField="id"
        data={data.stores}
        columns={columns}
        search
        defaultSorted={defaultSorted}
        pagination={PaginationFactory()}
      >
        {props => (
          <div>
            <SearchBar {...props.searchProps} />
            <hr />
            <Table defaultSorted={defaultSorted} pagination={PaginationFactory()} {...props.baseProps} />
          </div>
        )}
      </ToolkitProvider>

      <DialogModal
        show={show}
        hide={handleClose}
        submit={handleSubmit}
        title={`Store password reset`}
        content={`Do you wish to reset this store's password?`}
        _type={`confirmation`}
      ></DialogModal>
    </div>
  )
}

export {StoreAccountsPage}
