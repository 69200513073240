// const API = process.env.REACT_APP_PUBLIC_URL;
// const SECRET = process.env.REACT_APP_SECRET;
// const TITLE = process.env.REACT_APP_TITLE;
// const APICRM = process.env.REACT_APP_LOCAL;
// const AWSURI = process.env.REACT_APP_AWS_URI;
// const AWSDOCUMENT = process.env.REACT_APP_AWS_DOCUMENT_URI;
const AWSDOCUMENT = "https://sparkle-time-keep.herokuapp.com/api/upload"
const AWSURI = "https://oheast2-upload-s3.s3.us-east-2.amazonaws.com"
const API = "https://api.starjobs.com.ph/webservice-hr-api";
const SECRET = "CODEX@123";
const TITLE = "APEX";
const APICRM = "https://sparkles-backend.onrender.com/";

export { APICRM, API, SECRET, TITLE, AWSURI, AWSDOCUMENT };
