import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, MenuItem, Grid, TextField, FormControl, InputLabel, Select, Checkbox, FormControlLabel } from '@mui/material';

const AddFieldDialog = ({ open, onClose, onSubmit }) => {
  const [data, setData] = useState({
    type: 'text',
    label: '',
    value: '',
    options: [],
    validations: {
      required: false,
      minLength: '',
      maxLength: '',
      pattern: '',
      email: false,
      numeric: false,
      allowedFileTypes: ''
    },
    error: '',
    helperText:''
  });

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'options'){
      setData((prevState) => ({ ...prevState, options: Array.from(value.split(',')) }));
    } else if (name.startsWith('validations.')) {
      const validationField = name.split('.')[1];
      setData((prevState) => ({
        ...prevState,
        validations: {
          ...prevState.validations,
          [validationField]: event.target.type === 'checkbox' ? checked : value
        }
      }));
    } else {
      setData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  useEffect(() => {
    console.log('data: ', data);
  }, [data])

  const handleSubmit = () => {
    onSubmit(data, open + 1);
    onClose();
  };

  return (
    <Dialog open={open !== null} onClose={onClose}>
      <DialogTitle>Add New Field</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Field Type</InputLabel>
          <Select
            value={data.type}
            name="type"
            label="Field Type"
            onChange={handleInputChange}
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="label">Label</MenuItem>
            <MenuItem value="select">Select</MenuItem>
            <MenuItem value="image">Image Upload</MenuItem>
            <MenuItem value="divider">Divider</MenuItem>
            <MenuItem value="divider-label">Divider and Label</MenuItem>
            <MenuItem value="next-row">Next Row</MenuItem>
          </Select>
        </FormControl>
        {data.type === 'select' && (
          <TextField
            fullWidth
            name="options"
            sx={{ mt: 2 }}
            label="Options (comma separated)"
            onChange={handleInputChange}
          />
        )}
        {
          data.type !== 'divider' && data.type !== 'image' && data.type !== 'next-row' && (
            <TextField
              fullWidth
              name="label"
              sx={{ mt: 2 }}
              label="Field Label"
              onChange={handleInputChange}
            />
          )
        }
        {data.type !== 'divider' && data.type !== 'next-row' && data.type !== 'label' && (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.validations.required}
                    onChange={handleInputChange}
                    name="validations.required"
                  />
                }
                label="Required"
              />
            </Grid>
            {data.type === 'text' && (
              <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="validations.minLength"
                    label="Min Length"
                    value={data.validations.minLength}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="validations.maxLength"
                    label="Max Length"
                    value={data.validations.maxLength}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.validations.email}
                        onChange={handleInputChange}
                        name="validations.email"
                      />
                    }
                    label="Email Format"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.validations.numeric}
                        onChange={handleInputChange}
                        name="validations.numeric"
                      />
                    }
                    label="Numeric Only"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="validations.pattern"
                    label="Custom Pattern (RegEx)"
                    value={data.validations.pattern}
                    onChange={handleInputChange}
                  />
                </Grid>
              </>
            )}
            {data.type === 'image' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="validations.allowedFileTypes"
                  label="Allowed File Types (e.g., .jpg,.png,.jpeg)"
                  value={data.validations.allowedFileTypes}
                  onChange={handleInputChange}
                  helperText="Enter file extensions separated by commas"
                />
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">Add Field</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFieldDialog;