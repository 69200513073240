import React, { useState, useRef, useEffect} from 'react';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from 'react-image-crop';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Switch, FormControlLabel,Box, CircularProgress, TextField, Alert, Divider } from '@mui/material';
import { uploadImage } from 'api/private/crm';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import MediaDialog from '../modal/media-modal';
import 'react-image-crop/dist/ReactCrop.css';
import SecureLS from 'secure-ls'
import { set } from 'lodash';

const ls = new SecureLS({encodingType: 'aes'})

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageUploadDialog({ open, onClose, onUpload, currentImage, comoponentRequest }) {
  const [imgSrc, setImgSrc] = useState('');
  const [imgTitle, setImgTitle] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const submitToMedia = useRef([])
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);
  const [error,setError] = useState(null)
  const [openMedia, setOpenMedia] = useState(false)
  const [fileData, setFileData] = useState(null);
  const companyName = ls.get('company')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setError(null);
  }, [open]);

  function onSelectFile(e) {
    const file = e.target.files[0]
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      // reject file
      alert('Only .jpg, .jpeg, and .png files are allowed');
      e.target.value = null;
      return;
    }
    const fileSizeInMB = file.size / 1024 / 1024;
    
    if(fileSizeInMB > 1){
      setError('File size exceeds 1MB. Please upload a smaller file.');
    }else if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      setFileData(file);
      setImgTitle(file.name);
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || '')
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }


  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else {
      setAspect(16 / 9);

      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 16 / 9);
        setCrop(newCrop);
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  }

  async function handleUpload() {
    setLoading(true)
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const resultUpload = await offscreen.convertToBlob({
      type: 'image/png',
    }).then(async (blob) => {
      const file = new File([blob], imgTitle, { type: 'image/png' })
      const formdata = new FormData()
      formdata.append("file", file)
      try {
        const result = await uploadImage(formdata, companyName)
        if (result.error) {
          setError(result.error)
          return
        }
        const total = result.media.length
        return result.media[total - 1]
      } catch (e) {
        console.error(e)
        return null
      }
    });

    if (resultUpload){
      if(comoponentRequest === "mediaDialog"){
        onUpload(resultUpload);
        setLoading(false)
        onClose();
      }else{
        handleSubmitIntoMedia(resultUpload)
        onUpload(resultUpload.imageUrl)
        setLoading(false)
        onClose();
      }
    }
    setLoading(false)
  }


  const handleCloseMedia = () => {
    setOpenMedia(false);
  };

  const handleImageSelect = (image) => {
    onUpload(image); 
    setCrop(undefined); 
    onClose();
  };


  const handleSubmitIntoMedia = (mediaData) =>{
    const storedMedia = ls.get('mediaAssets');
    const updatedMedia = storedMedia ? [...storedMedia, mediaData] : [mediaData];
    ls.set('mediaAssets', updatedMedia);
}

  const handleCloseEditPhoto = () =>{
    setImgSrc('');
    setCrop(undefined);
    setCompletedCrop(undefined);
    setScale(1);
    setRotate(0);
    setError(null);
    onClose()
  }

  const handleCloseImageDialog = () =>{
    setImgSrc('');
    setCrop(undefined);
    setCompletedCrop(undefined);
    setScale(1);
    setRotate(0);
    setError(null);
    onClose()
  }

  return (
    <>
      <Dialog className="App" open={open} onClose={handleCloseImageDialog}>
      {loading ? <DialogContent> <CircularProgress determinate={false} value={25} /> </DialogContent> : (
      <>
        <DialogTitle>Edit Photo</DialogTitle>
        <DialogContent>
        { comoponentRequest !== "mediaDialog" && <Box>
            <Button onClick={() => setOpenMedia(true)}> Open Media Library </Button>
          </Box>}
          <div className="Crop-Controls">
            <input type="file" accept="image/jpeg, image/png" onChange={onSelectFile} />
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!aspect}
                    onChange={handleToggleAspectClick}
                    color="primary"
                  />
                }
                label={`Aspect ${aspect ? 'on' : 'off'}`}
              />
            </div>
          </div>
          {!!imgSrc && (
            <>
            <TextField
              value={imgTitle}
              onChange={(e) => {
                const newValue = e.target.value.trim();
                const validExtension = newValue.endsWith('.jpg') || newValue.endsWith('.png');
                if (newValue === '') {
                  setError('Image name is required');
                } else if (!validExtension) {
                  setError('Image name must end with .jpg or .png');
                } else if (newValue === '.jpg' || newValue === '.png') {
                  setError('Image name cannot be just .jpg or .png');
                } else {
                  setError(null);
                }
                setImgTitle(e.target.value);
              }}
              label="Image Name"
              variant="outlined"
              fullWidth
              sx={{ mb: 5 }}
            />
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              minHeight={100}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
                accept="image/jpeg, image/png"
              />
            </ReactCrop>
            </>
          )}
          {error && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditPhoto}>Cancel</Button>
          <Button onClick={handleUpload} color="primary" disabled={!imgSrc || error}>Upload</Button>
        </DialogActions>
      </>
      )}
    </Dialog>
    {openMedia && (
      <MediaDialog
        openMedia={openMedia}
        handleCloseMedia={handleCloseMedia}
        onImageSelect={handleImageSelect}
        eventButton='uploadComponent'
      />
    )}
    </>
  );
}
