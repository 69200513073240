import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'

export default function DialogModal({show, hide, title, content, submit, _type}) {
  return (
    <div>
      <Dialog
        open={show}
        onClose={hide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {_type === 'confirmation' ? (
            <>
              <Button onClick={submit} color="primary">
                Yes
              </Button>
              <Button onClick={hide} color="primary" autoFocus>
                No
              </Button>
            </>
          ) : (
            <>
              <Button onClick={hide} color="primary">
                Ok, Cool!
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}
