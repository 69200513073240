import React, { useState, useEffect, useContext } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  Container,
  IconButton
} from '@mui/material';

import { Icon } from '@iconify/react';

import { styled } from '@mui/material/styles';
import SwornDialog from '../../../../../../dialog/SwornDialog';
import { AWSURI } from '../../../../../../../api/utils/config';
import SecureLS from 'secure-ls'
import DynamicForm from './DynamicForm';

import { EditModeContext } from 'context/EditModeContext'; // Import the context

const ls = new SecureLS({encodingType: 'aes'})

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'relative',
  paddingTop: 42,
  paddingBottom: 42,
  display: 'flex',
  alignItems: 'center'
}));

const image_bucket = AWSURI;
const defaultSteps = [
  {
    label: 'Consent',
    formLayout: []
  },
  {
    label: 'Personal Information',
    formLayout: []
  },
  {
    label: 'Education background',
    formLayout: []
  },
  {
    label: 'Family background',
    formLayout: []
  },
  {
    label: 'Working experience',
    formLayout: []
  },
  {
    label: 'Medication information',
    formLayout: []
  },
  {
    label: 'Review Information',
    formLayout: []
  },
];

const ApplicationForm = ({ data }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(defaultSteps);
  const [skipped, setSkipped] = useState(new Set());
  const [isLoading, setLoading] = useState(false);
  const [isSworn, setSworn] = useState(false); // form data
  const [open, setOpenSworn] = useState(false); // dialog state
  const [user, setUser] = useState([]);
  const [review, setReview] = useState(false);
  const [fields, setFields] = useState([])
  const [newFieldType, setNewFieldType] = useState('text')
  const context = useContext(EditModeContext);
  const { isEditMode } = context || {};

  useEffect(() => {
    const localSteps = ls.get('crmApplySteps');
    let parsedSteps;
    try {
      parsedSteps = localSteps;
    } catch (e) {
      parsedSteps = null;
    }
    const steps = parsedSteps || data || defaultSteps;
    setSteps(steps);
  }, []);

  const handleSworn = () => {
    setSworn(true);
    setOpenSworn(false);
  };

  const openSworn = () => {
    setOpenSworn(true);
  };

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const addStep = (index) => {
    const updatedSteps = [...steps, {
      label: 'New step',
      formLayout: []}];
    setSteps(updatedSteps);
  };

  const removeStep = (index) => {
    setSteps((prev) => prev.filter((_, i) => i !== index));
  };

  const moveStep = (index) => {
    const updatedSteps = [...steps];
    const stepToMove = updatedSteps[index];
    const nextIndex = (index + 1) % steps.length;
    
    updatedSteps[index] = updatedSteps[nextIndex];
    updatedSteps[nextIndex] = stepToMove;
    
    setSteps(updatedSteps);
  };

  const handleSubmit = () => {
    if (activeStep === steps.length - 1) {
      setReview(true);
      return;
    } else {
      handleNext();
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleSetActiveStep = (index) => {
    setActiveStep(index);
  };

  const handleUpdateFields = (fields) => {
    console.log('fields: ', fields);
    const updatedSteps = [...steps];
    updatedSteps[activeStep].formLayout = fields;
    ls.set('crmApplySteps', updatedSteps);
  }

  return (
    <RootStyle>
    <SwornDialog open={open} onConfirm={handleSworn} />
    <Container maxWidth="lg">
    <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
            stepProps.completed = false;
            }
            return (
            <Step key={index} {...stepProps}>
                <StepLabel
                {...labelProps}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSetActiveStep(index);
                }}
                >
                  <Box direction="column">
                    <Box direction="row">
                      {step.label}
                    </Box>
                    {!!isEditMode && (
                      <Box direction="row">
                        <IconButton onClick={() => removeStep(index)} size="small">
                          <Icon icon="lsicon:minus-outline"  style={{color: 'black'}} />
                        </IconButton>
                        <IconButton onClick={() => moveStep(index)} size="small">
                          <Icon icon="ic:outline-forward"  style={{color: 'black'}} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </StepLabel>
            </Step>
            );
        })}
        {!!isEditMode && (
          <Box direction="row" spacing={1} mt={1}>
              <IconButton onClick={addStep} size="medium"><Icon icon="ic:outline-plus"  style={{color: 'black'}} /></IconButton>
          </Box>
        )}
    </Stepper>

    {
      steps.map((step, index) => {
        const stepProps = {};
        const labelProps = {};
        if (isStepSkipped(index)) {
          stepProps.completed = false;
        }
        return (
          <React.Fragment key={index}>
            {activeStep === index && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%', mx: 'auto', mt: '4vh', mb: '1vh' }}>
                <Typography sx={{ mb: '2vh' }}>{step.label}</Typography>
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <DynamicForm onSubmit={handleSubmit} activeStep={step} lastStep={index === steps.length - 1} updateFields={handleUpdateFields} handleBack={handleBack} />
                </Box>
              </Box>
            )}
          </React.Fragment>
        );
      })
    }
    </Container>
    </RootStyle>
  );
};

export default ApplicationForm;

