import {signIn, sendOtp, googleSignIn, updateFB, facebookSignIn} from 'api/public/auth'
import {ErrorPage1} from 'app/modules/ErrorsExamples/ErrorPage1'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import {connect} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import SecureLS from 'secure-ls'
import 'style/_login.scss'
import * as Yup from 'yup'
import * as auth from '../_redux/authRedux'
import Select from 'react-select'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login'
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material'; 
import { BehaviorSubject } from 'rxjs';

const initialValues = {
  email: '',
  password: '',
}

const accountSubject = new BehaviorSubject(null);

const options = [
  { value: 'Phone', label: 'Phone No.' },
  { value: 'Email', label: 'Email' },
]

function Login(props) {
  const history = useHistory()
  const {intl} = props
  const [loading, setLoading] = useState(false)
  const [loginType, setLoginType] = useState(false)
  const [open, setOpen] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [fbLinkUp, setFbLinkUp] = useState(false)
  const [mobile, setMobile] = useState()
  const [fbEmail, setFbEmail] = useState()
  const [updateValue, setUpdateValue] = useState("Subscribe")
  const [fb, setFb] = useState()
  const [values, setValues] = useState({
    showPassword: false,
  })
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  })
  const {showPassword} = values
  const ls = new SecureLS({encodingType: 'aes'})

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  function handleLoginTypeChange (e) {
    setLoginType(e.value)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}, e) => {
      enableLoading()
      await signIn(values.email, values.password)
        .then(r => {
          const {token, route, id, company} = r.data
          disableLoading()
          ls.set('token', token)
          ls.set('ud', JSON.stringify(r.data))
          ls.set('uid', id)
          ls.set('route', route)
          if (r.data['switchable'] && parseInt(r.data['switchable']) === 1) {
            ls.set('company', JSON.parse(company).company[0])
          } else {
            ls.set('company', company)
          }
          props.login(id)
        })
        .catch(err => {
          console.log(err)
          disableLoading()
          setSubmitting(false)
          setStatus(
            intl.formatMessage({
              id: 'AUTH.VALIDATION.INVALID_LOGIN',
            }),
          )
        })
    },
  })

  const togglePassword = () => {
    if (!showPassword) {
      setValues({...values, showPassword: true})
    } else {
      setValues({...values, showPassword: false})
    }
  }

  const newTo = { 
    pathname: "/auth/forgot-password", 
    param1: "Par1" 
  };
  
  async function handleMobileSubmit(e) {
    e.preventDefault()
    const mobile = e.target[0].value
    await sendOtp(mobile).then(
      res => {
        if (res.status != 200) {
          alert(res.message)
        }
        else {
          history.push({
            pathname: '/auth/mobile',
            mobile: mobile,
            time: res.Time
          });
        }
      }
    )
    
  }

  async function authenticateGoogleToken(response) {
      const email = response.profileObj.email
      //setTokenId(response.tokenId)
      await googleSignIn(email)
        .then(r => {
          const {token, route, id, company} = r.data
          disableLoading()
          ls.set('token', token)
          ls.set('ud', JSON.stringify(r.data))
          ls.set('uid', id)
          ls.set('route', route)
          if (r.data['switchable'] && parseInt(r.data['switchable']) === 1) {
            ls.set('company', JSON.parse(company).company[0])
          } else {
            ls.set('company', company)
          }
          props.login(id)
        })
        .catch(err => {
          console.log(err)
          disableLoading()
          alert("No account registered with this email")
        })
  }

  async function responseFacebook(response) {
    if (fbLinkUp) {
      const email = response.email
      const fbId = response.userID
      setFb(response.userID)
      await facebookSignIn(email, fbId)
        .then(r => {
          if(r.status === 422) {
            alert("User not found")
            setOpen(true)
          }
          else {
            const {token, route, id, company} = r.data
            disableLoading()
            ls.set('token', token)
            ls.set('ud', JSON.stringify(r.data))
            ls.set('uid', id)
            ls.set('route', route)
            if (r.data['switchable'] && parseInt(r.data['switchable']) === 1) {
              ls.set('company', JSON.parse(company).company[0])
            } else {
              ls.set('company', company)
            }
            props.login(id)  
          }
        })
        .catch(err => {
          disableLoading()
        })
    }
    
  }

  function handleClose() {
    setOpen(false)
  }
  let authenticateTimeout

  function stopAuthenticateTimer() {
      // cancel timer for re-authenticating with the api
      clearTimeout(authenticateTimeout)
  }

  function handleFacebookClick(response) {
    setFbLinkUp(true)
  }

  async function handleSubscribe() {
    setUpdateLoading(true)
    setUpdateValue("Loading")
    await updateFB(fbEmail, fb)
      .then(r => {
        if(r.status !== 200 ) {
          alert(r.message)
        }
        else {
          alert("Facebook account link Successful! Please try to log-in using facebook log-in!")
          setOpen(false)  
        }
      })
      .catch(err => {
        disableLoading()
      })
    setUpdateValue("Subscribe")
    setUpdateLoading(false)
  }


  const style = {
    width: "100% !important",
  };


  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        {loginType !== "Phone" 
          ?
            <p className="text-muted font-weight-bold">Enter your username and password</p> 
          :
            <p className="text-muted font-weight-bold">Enter your mobile</p>
        }
      </div>
      
      {/* end::Head */}

      {/*begin::Form*/}
      
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          ''
        )}

        <Select className="mb-5" isDisabled={true} options={options} onChange={(e) => {handleLoginTypeChange(e)}} defaultValue={{ label: "Email.", value: "Email" }}/>
        {loginType !== "Phone" 
          
        ? 
        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework form-login">
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Email"
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
              name="email"
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container input-relative">
            <button type="button" className="btn bg-none btn__show" onClick={() => togglePassword()}>
              <i className={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
            </button>
            <input
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
              name="password"
              {...formik.getFieldProps('password')}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <Link to="/auth/forgot-password" className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot">
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span>Sign In</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
{/*          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_ID}
            buttonText="Google Login"
            onSuccess={ authenticateGoogleToken }
            onFailure={ authenticateGoogleToken }
            cookiePolicy={ 'single_host_origin' }
            className="w-100 text-center d-flex justify-content-center mb-5"
          />
          <FacebookLogin
            appId={process.env.REACT_APP_FB_ID}
            autoLoad={true}
            fields="name,email,picture"
            onClick={handleFacebookClick}
            callback={responseFacebook}
            icon="fa-facebook"
            className="w-100"
          />*/}
          </form>
        :
          <form onSubmit={(e)=>{handleMobileSubmit(e)}} className="form fv-plugins-bootstrap fv-plugins-framework form-login">
            <input
              placeholder="Mobile No."
              type="tel"
              onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                      setMobile(event.target.value)
                    }}
              required
              minLength = "11"
              maxLength = "11"
              value={mobile}
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="mobile"
              {...formik.getFieldProps('mobile')}
            />
            <div className="form-group d-flex justify-content-between align-items-center w-100">
              <Link to={newTo} className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot">
                Update Mobile
              </Link>
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span>Sign In</span>

                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
            
          </form>
        }
        
      {/*end::Form*/}
      <Dialog open={open} onClose={handleClose}>  
        <DialogTitle>Link FB account to APEX?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To Link your current FB account to this website, please enter your active APEX email address here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setFbEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubscribe} disabled={updateLoading}>{updateValue}</Button>
        </DialogActions>
      </Dialog>
    </div>

  )
}

export default injectIntl(connect(null, auth.actions)(Login))
