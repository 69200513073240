import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Alert from '@mui/material/Alert';
import {getApplicantsExams, postApplicantExams} from 'api/private/applicants'
import Loader from 'components/preloader'
import React, {useEffect, useState} from 'react'
import Table from 'react-bootstrap-table-next'
import PaginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit'
import ConfirmModal from './popup/'
const {SearchBar} = Search
var dayjs = require('dayjs')
const ExamLogsPage = props => {
  const [isModal, setIsModal] = useState(false)
  const [data, setData] = useState({
    exams: [],
    loading: false,
    isSent: false,
    selected: [],
  })
  const handleModal = _body => {
    setIsModal(true)
    setData({...data, selected: _body})
  }

  const handleClose = () => {
    setIsModal(false)
    setData({...data, selected: []})
  }

  const listExams = async () => {
    setData({...data, loading: true})
    const r = await getApplicantsExams()
    console.log(r)
    if (r.length > 0) {
      let dt = []
      for (let index = 0; index < r.length; index++) {
        if (r[index].exams.length > 0) {
          if (r[index].exams.length > 1) {
            let exms = []
            r[index].exams.map(ex => exms.push({...ex, email: r[index].username, company: r[index].company}))

            r[index].exams = exms
          } else {
            r[index].exams.push({
              email: r[index].username,
              company: r[index].company,
            })
          }
        }

        dt.push({
          id: r[index].id,
          reference_id: r[index].reference_id,
          email: r[index].username,
          date: dayjs(r[index].date_created).format('MMMM-DD-YYYY h:m:s a'),
          company: r[index].company,
          exams: r[index].exams.length > 0 ? r[index].exams : '',
        })
      }
      setData(prevstate => ({...prevstate, exams: dt, loading: false}))
    }
    setData(prevstate => ({...prevstate, loading: false}))
  }

  useEffect(() => {
    listExams()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleResend = async () => {
    const r = await postApplicantExams(data.selected)
    if (r === 200) {
      setData({...data, isSent: true, selected: []})
      setIsModal(false)
    }
  }

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ]

  const columns = [
    {
      dataField: 'id',
      text: 'QueueID',
      sort: true,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'date',
      text: 'Date',
    },
    {
      dataField: 'exams',
      text: 'Test Taken',
      formatter: (cellContent, {exams}) => {
        return exams.length > 0 ? (
          <div className="d-flex justify-content-around">
            {exams && exams.length > 0
              ? exams.map((ex, k) => {
                  const {title, email, exam_id, job_id, company, applicant_id} = ex
                  const req = {
                    email: email,
                    exam_id: exam_id,
                    job_id: job_id,
                    company: company,
                    id: applicant_id,
                  }
                  return (
                    <button type="button" key={k} className="btn btn-primary m-1" onClick={() => handleModal(req)}>
                      {title}
                    </button>
                  )
                })
              : 'No test taken'}
          </div>
        ) : (
          <span>No Test taken</span>
        )
      },
    },
  ]

  return (
    <div>
      {data.loading ? (
        <Loader />
      ) : (
        <>
          {data.isSent ? (
            <Alert className="my-2" iconMapping={{success: <CheckCircleOutlineIcon fontSize="inherit" />}}>
              Examination Link Sent
            </Alert>
          ) : (
            ''
          )}
          <ConfirmModal show={isModal} save={handleResend} onClose={handleClose}></ConfirmModal>
          <ToolkitProvider
            keyField="id"
            data={data.exams}
            columns={columns}
            search
            defaultSorted={defaultSorted}
            pagination={PaginationFactory()}
          >
            {props => (
              <div>
                <SearchBar {...props.searchProps} />
                <hr />
                <Table defaultSorted={defaultSorted} pagination={PaginationFactory()} {...props.baseProps} />
              </div>
            )}
          </ToolkitProvider>
        </>
      )}
    </div>
  )
}

ExamLogsPage.propTypes = {}

export {ExamLogsPage}
