import React, {useState, useEffect} from 'react'
//import PropTypes from 'prop-types'

import ApplicantLogsTable from './ApplicantLogsTable'
import {getApplicantsLogs} from 'api/private/applicants'
var dayjs = require('dayjs')
const ApplicantLogsPage = props => {
  const [data, setData] = useState({
    logs: [],
    loading: false,
  })

  const listLogs = async () => {
    const r = await getApplicantsLogs()

    if (r.length > 0) {
      let dt = []
      r.map(d => {
        return dt.push({
          id: d.id,
          email: d.information.length > 0 ? JSON.parse(d.information).username : '',
          date: dayjs(d.date).format('MMMM-DD-YYYY h:m:s a'),
          company: d.company,
          message: d.message,
          type: d.type,
          information: d.information.length > 0 ? 'Information Saved' : '',
        })
      })
      setData({...data, logs: dt, loading: false})
    }
  }

  useEffect(() => {
    listLogs()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  let columns = [
    {
      dataField: 'id',
      text: 'id',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
    },
    {
      dataField: 'information',
      text: 'Information',
    },
    {
      dataField: 'message',
      text: 'Message',
    },
  ]

  return (
    <div>
      <h3>Applicant Logs</h3>
      <ApplicantLogsTable applicants={data.logs} columns={columns} />
    </div>
  )
}

ApplicantLogsPage.propTypes = {}

export {ApplicantLogsPage}
