import React, {useState, useEffect} from 'react'
import {getEmails, postResendEmail, patchChangeEmail} from 'api/private/emails'
//import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Table from 'react-bootstrap-table-next'
import PaginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit'
import {Modal} from 'react-bootstrap'
import Alert from '@mui/material/Alert';
import DialogModal from 'components/dialog'

const {SearchBar} = Search
var dayjs = require('dayjs')
const EmailsPage = props => {
  const [data, setData] = useState({
    emails: [],
    loading: false,
    filterType: [],
    selectedType: '',
    selectedStatus: '',
  })

  const [email, setEmail] = useState([
    {
      id: '',
      email: '',
      modal: false,
    },
  ])
  const [changeEmail, setChangeEmail] = useState([
    {
      user: '',
      email: '',
      modal: false,
      success: false,
      loading: false,
    },
  ])

  const listEmails = async () => {
    const r = await getEmails()
    if (r) {
      let dt = []
      r.map(d => {
        return dt.push({
          queue_id: d.id,
          email: d.email,
          company: d.company,
          message: d.message,
          type: d.type,
          user: d.user,
          date_posted: dayjs(d.date).format('MMMM-DD-YYYY h:m:s a'),
        })
      })

      setData({...data, emails: dt, loading: false, filterType: [...new Set(dt.map(o => o.type))]})
    }
  }

  useEffect(() => {
    listEmails()
  },[])// eslint-disable-line react-hooks/exhaustive-deps


  const handleStatusChange = async e => {
    e.preventDefault()
    const v = e.target.value
    const r = await getEmails()
    setData({...data, emails: [], loading: true})
    if (r) {
      let dt = []
      await r.map(d => {
        return dt.push({
          queue_id: d.id,
          email: d.email,
          company: d.company,
          message: d.message,
          type: d.type,
          user: d.user,
          date_posted: dayjs(d.date).format('MMMM-DD-YYYY h:m:s a'),
        })
      })
      let sorted = dt
      if (v.length > 0 && data.selectedStatus.length === 0) {
        sorted = await dt.filter(function(el) {
          return v === el.type
        })
        return setData({...data, emails: sorted, loading: false, selectedType: v})
      } else {
        sorted = await sorted.filter(function(el) {
          if (v.length === 0) {
            if (data.selectedStatus.length > 0) return data.selectedStatus === el.message
            return sorted
          } else {
            return data.selectedStatus === el.message && v === el.type
          }
        })
        return setData({...data, emails: sorted, loading: false, selectedType: v})
      }
    }
  }

  const handTypeChange = async e => {
    e.preventDefault()
    const v = e.target.value
    const r = await getEmails()

    setData({...data, emails: [], loading: true})
    if (r) {
      let dt = []
      await r.map(d => {
        return dt.push({
          queue_id: d.id,
          email: d.email,
          company: d.company,
          message: d.message,
          type: d.type,
          user: d.user,
          date_posted: dayjs(d.date).format('MMMM-DD-YYYY h:m:s a'),
        })
      })
      let sorted = dt
      if (v.length > 0 && data.selectedType.length === 0) {
        sorted = await dt.filter(function(el) {
          return v === el.message
        })

        return setData({...data, emails: sorted, loading: false, selectedStatus: v})
      } else {
        sorted = await sorted.filter(function(el) {
          if (v.length === 0) {
            if (data.selectedType.length > 0) return data.selectedType === el.type
            return sorted
          } else {
            return data.selectedType === el.type && v === el.message
          }
        })
        return setData({...data, emails: sorted, loading: false, selectedStatus: v})
      }
    }
  }

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ]

  const columns = [
    {
      dataField: 'queue_id',
      text: 'Queue Id',
      sort: true,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'date_posted',
      text: 'Date Posted',
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
    },
    {
      dataField: 'message',
      text: 'Message',
    },
    {
      dataField: 'df1',
      text: 'Actions',
      formatter: (cellContent, row) => (
        <>
          <button type="button" className="btn btn-primary m-1" onClick={e => handleShowEmail(e, row.user, row.email)}>
            Resend Email
          </button>
          <button
            type="button"
            className="btn btn-primary m-1"
            onClick={e => handleEmailInputShow(e, row.email, row.user)}
          >
            Change Email
          </button>
        </>
      ),
    },
  ]

  const handEmailSend = async e => {
    const data = {
      id: email.id,
    }

    const r = await postResendEmail(data)
    if (r) {
      setEmail({
        ...email,
        id: '',
        email: '',
      })

      setTimeout(function() {
        setEmail({...email, modal: false})
        window.location.reload()
      }, 5000)
    }
  }

  const handleShowEmail = (e, id, email) => {
    setEmail({...email, id: id, email: email, modal: true})
  }

  const handleCloseEmail = e => {
    setEmail({...email, modal: false, user: '', email: ''})
  }

  const handleEmailInputShow = (e, email, id) => {
    setChangeEmail({...changeEmail, modal: true, email: email, user: id})
  }

  const handleEmailInputClose = () => {
    setChangeEmail({...changeEmail, modal: false, user: '', email: ''})
  }

  const handleEmailChange = async e => {
    e.preventDefault()
    const data = {
      email: changeEmail.email,
      id: changeEmail.user,
    }

    const r = await patchChangeEmail(data)

    if (r) {
      setChangeEmail({
        ...changeEmail,
        user: '',
        email: '',
        loading: false,
        success: true,
      })

      setTimeout(function() {
        setChangeEmail({...changeEmail, modal: false, success: false})
        window.location.reload()
      }, 5000)
    }
  }

  return (
    <div>
      <ToolkitProvider
        keyField="id"
        data={data.emails}
        columns={columns}
        search
        defaultSorted={defaultSorted}
        pagination={PaginationFactory()}
      >
        {props => (
          <div>
            <div className="d-flex aling-items-center">
              <SearchBar {...props.searchProps} />

              <select
                className="form-control mx-2 w-25"
                name="type"
                defaultValue={data.selectedType ? data.selectedType : ''}
                placeholder="Filter by Type"
                onChange={e => handleStatusChange(e)}
              >
                <option value="">All</option>
                {data.filterType && data.filterType.length > 0
                  ? data.filterType.map((d, k) => {
                      return (
                        <option key={k} value={d}>
                          {d}
                        </option>
                      )
                    })
                  : ''}
              </select>
              <select
                className="form-control w-25"
                name="status"
                placeholder="Filter by Status"
                onChange={e => handTypeChange(e)}
              >
                <option value="">All</option>
                <option value="SUCCESS">Success</option>
                <option value="FAILED">Failed</option>
              </select>
            </div>
            <hr />
            <Table defaultSorted={defaultSorted} pagination={PaginationFactory()} {...props.baseProps} />
          </div>
        )}
      </ToolkitProvider>

      <DialogModal
        show={email.modal || false}
        hide={handleCloseEmail}
        submit={handEmailSend}
        title={`Resend Email?`}
        content={`Do you wish to resend an email for this account?`}
        _type={`confirmation`}
      ></DialogModal>

      <Modal
        size="md"
        show={changeEmail.modal}
        onHide={handleEmailInputClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <h3>Change Applicant's Email address</h3>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <form onSubmit={e => handleEmailChange(e)}>
            <input type="hidden" value={changeEmail.user} name="id" />
            <div className="form-group mb-1">
              <label className="label-control">Email address</label>
              <input
                type="text"
                className="form-control"
                onInput={e => (e ? setChangeEmail({...changeEmail, email: e.target.value}) : false)}
                value={changeEmail.email}
              />
            </div>

            {changeEmail.success ? (
              <Alert className="my-2" iconMapping={{success: <CheckCircleOutlineIcon fontSize="inherit" />}}>
                Applicant's Status changed success.
              </Alert>
            ) : (
              ''
            )}
            <div className="text-right">
              <button
                className="btn btn-light-primary font-weight-bolder font-size-sm mt-2 w-25"
                type="button"
                onClick={e => handleEmailInputClose(e)}
              >
                Cancel
              </button>
              <button
                className="btn btn-light-success font-weight-bolder font-size-sm mt-2 ml-3  w-50"
                type="submit"
                disabled={changeEmail.loading}
              >
                Save Changes
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  )
}

EmailsPage.propTypes = {}

export {EmailsPage}
