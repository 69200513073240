import React, {useContext, useState, useEffect} from 'react'
// material
import {styled} from '@mui/material/styles'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import BackgroundImageWithFallback  from 'components/crm/image/BackgroundImageWithFallback';

import ImageUploadDialog from '../../../../image/ImageWithDialog';
import defaultBackgroundImage from '../../assets/about-us.jpg'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ encodingType: 'aes' })

export default function AboutBanner({data}) {
  const context = useContext(EditModeContext);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState([]);

  const [parentHover, setParentHover] = useState(false);
  const [childHover, setChildHover] = useState(null);

  const { isEditMode } = context || {};


  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true); // Open dialog on image click
  };

  const handleImageUpload = (newImageUrl) => {
    const updatedImages = [...imageUrl];
    console.log(newImageUrl)
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrl(updatedImages);
    ls.set('crmAboutBanner', newImageUrl); // Save the new image in local storage
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false); // Close dialog
  };

  useEffect(() => {

    const storedImage = ls.get('crmAboutBanner');
    const parsedImage = storedImage || data?.backgroundImage || defaultBackgroundImage; // Resolve the image path

    setImageUrl([parsedImage]);
  }, [data]);

  return (
    <>
      <ImageUploadDialog
      open={imageDialogOpen}
      onClose={handleImageDialogClose}
      onUpload={handleImageUpload}
      currentImage={imageUrl[selectedImageIndex]}
      />
      <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(0)} hover={parentHover && !childHover} onMouseEnter={() => setParentHover(true)} onMouseLeave={() => setParentHover(false)} editType={'banner'}>
        <BackgroundImageWithFallback
          src={imageUrl[0]}
          fallbackSrc={defaultBackgroundImage}
          component={'aboutBanner'}
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: '100% 30%',
            backgroundColor: '#FBBA37',
            backgroundAttachment: 'fixed',
            height: { xs: '200px', md: '400px' },
            py: { xs: 0, md: 10 },
            overflow: 'hidden',
            position: 'relative',
            paddingTop: 60,
            paddingBottom: 60,
            display: 'flex',
            alignItems: 'center'
          }}
          className="overlayBg"   
        />
    </EditableItemWrapper>
    </>
  );
}
