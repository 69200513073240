import React, {useEffect, useState, useMemo} from 'react'
import {getDocuments, getSpecificDocuments, getSearchSpecificDocuments} from 'api/private/documents'
import {getSpecificApplicants} from 'api/private/applicants'
import PendingApplicantListItem from './PendingApplicantListItem'
import PreviewCard from 'components/preview'
import DataTable from 'react-data-table-component'
//import {useParams} from 'react-router-dom'
import ModalFinalizeDocument from './ModalFinalizeDocument'
import 'style/_table.scss'
import {useSubheader} from '../../../_metronic/layout'
import Loader from 'components/preloader'
import SecureLS from 'secure-ls'
var dayjs = require('dayjs')
const ReviewCompleteDocumentsPage = props => {


  const suhbeader = useSubheader()
  const ls = new SecureLS({encodingType: 'aes'})

  suhbeader.setTitle('Applicants - Documents Complete')
  //const {id, company} = useParams()


  //Usestate for list Data
  const [data, setData] = useState({
    applicants: [],
    loading: false,
  })

   //Modal controllers
   const [show, setShow] = useState(false)
   const handleClose = () => setShow(false)
   const handleShow = () => setShow(true)


  //Usestate for filter
  const [filterText, setFilterText] = useState('')
  const filteredItems = data.applicants
    ? data.applicants.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
    : []

  //UseState for pagination
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)


  //UseState for preview data
  const [previewData, setPreviewData] = useState([])

  //UseState for showing update Status modal
  

  //For generating a random key
  const genKey = Math.random()
    .toString(36)
    .substring(2)

  //Capitalization
  const capitalizeName = str => {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

  //Function for getting all applicants
  const listApplicants = async () => {
    const r = await getDocuments('5')
    setData({...data, loading: true})
    if (r.length > 0) {
      let dt = []
      for (let index = 0; index < r.length; index++) {
        let det = JSON.parse(r[index].data)
        dt.push({
          id: r[index].id,
          ref_id: r[index].reference_id,
          applicant_id: r[index].applicant_id,
          email: r[index].username,
          profileImage: r[index].profile,
          createdAt: dayjs(r[index].date_created).format('MMMM-DD-YYYY h:m:s a'),
          company: r[index].company,
          position: r[index].applying_for,
          status: r[index].returned,
          details: det,
          name: capitalizeName(
            `${det.person_fname} ${
              det.person_mname.length > 0 || det.person_mname.toLowerCase() !== 'n/a'
                ? `${det.person_mname.charAt(0)}.`
                : ''
            } ${det.person_lname}`.toLowerCase(),
          ),
        })
      }
      setData(prevState => ({...prevState, applicants: dt, loading: false}))
      ls.set("applicants", dt)
    }
    setData(prevState => ({...prevState, loading: false}))
  }


  useEffect(() => {
    listApplicants()
  },[])// eslint-disable-line react-hooks/exhaustive-deps

 //Function for applicant preview
  const previewApplicant = async (_company, _id) => {
    const r = await getSpecificApplicants(_id, _company)
    const data = []
    if (r.length > 0) {
      const s = await getSpecificDocuments(r[0].id, 'all')
      const profile = JSON.parse(r[0].data)
      data.push({
        name: `${profile.person_fname} ${profile.person_mname} ${profile.person_lname}`,
        firstName: profile.person_fname,
        middleName: profile.person_mname,
        lastName: profile.person_lname,
        perm_addr: `${profile.person_permanent_address_street_blk_pres} ${profile.person_permanent_address_street} ${profile.person_permanent_address_zipcode} ${profile.person_permanent_address_city}`,
        pres_addr: `${profile.person_present_address_street_blk} ${profile.person_present_address_street} ${profile.person_present_address_zipcode} ${profile.person_present_address_city}`,
        profile_img: r[0].profile,
        reference_id: r[0].reference_id,
        email: profile.person_email,
        contact: profile.person_contact_no_mob,
        sex: profile.person_sex,
        civil_status: profile.person_civil_status,
        religion: profile.person_rel,
        height: profile.person_ht,
        weight: profile.person_wt,
        blood_type: profile.person_bloodtype,
        tin: profile.person_tin,
        sss: profile.person_sss,
        phl: profile.person_phlhealth,
        pagibig: profile.person_pagibig,
        emerg_name: profile.person_emergency_name,
        emerg_contact: profile.person_emergency_contact_no,
        emerg_relationship: profile.person_emergency_relationship,
        nationality: profile.person_nationality,
        id: r[0].id,
        company: r[0].company,
        documents: s,
        status: r[0].returned,
        age: profile.person_age,
        birthdate: dayjs(profile.person_birthdate).format('MMMM-DD-YYYY'),
      })
      setPreviewData(data)
    }
  }

  const updateStore = async (_id) => {
    console.log(_id)
  }

  const handleSearchPersonnel = async (name) => {
    const r = await getSearchSpecificDocuments(filterText, 5)
    setData({...data, loading: true})
    if (r.length > 0) {
      let dt = []
      for (let index = 0; index < r.length; index++) {
        let det = JSON.parse(r[index].data)
        dt.push({
          id: r[index].id,
          ref_id: r[index].reference_id,
          applicant_id: r[index].applicant_id,
          email: r[index].username,
          profileImage: r[index].profile,
          createdAt: dayjs(r[index].date_created).format('MMMM-DD-YYYY h:m:s a'),
          company: r[index].company,
          position: r[index].applying_for,
          status: r[index].returned,
          details: det,
          name: capitalizeName(
            `${det.person_fname} ${
              det.person_mname.length > 0 || det.person_mname.toLowerCase() !== 'n/a'
                ? `${det.person_mname.charAt(0)}.`
                : ''
            } ${det.person_lname}`.toLowerCase(),
          ),
        })
      }
      setData(prevState => ({...prevState, applicants: dt, loading: false}))
    }
    else {
      alert("Applicant not found")
      if (ls.get("applicants") === "") {
        listApplicants()
        setData(prevState => ({...prevState, applicants: ls.get("applicants"), loading: false}))
      }
      else{
        setData(prevState => ({...prevState, applicants: ls.get("applicants"), loading: false}))
      }
    }
  }

  const onEnterSearchPersonnel = (e) => {
    if (filterText !== null && e.key === "Enter") {
      handleSearchPersonnel() 
    } 
  }

  // const updateReviewStatus = () => {

  
  // }

  const columns = [
    {
      name: 'Applicants',
      selector: 'name',
      cell: row => (
        <PendingApplicantListItem
          image={row.profileImage}
          applicantName={row.name}
          dateCreated={row.createdAt}
          company={row.company}
          id={row.id}
          action={previewApplicant}
          actionUpdate={updateStore}
          update={handleShow}
          status={row.status}
          type="complete"
        />
      ),
    },
  ]

  const FilterComponent = ({filterText, onFilter, onClear}) => (
    <>
      <input
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        defaultValue={filterText}
        onChange={onFilter}
        onKeyDown={onEnterSearchPersonnel}
        key="filterName"
        autoFocus={true}
        className={`form-control w-75 mr-5`}
      />
      <button type="button" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={handleSearchPersonnel} >
        <i className="fa fa-search"></i>
      </button>
    </>
  )

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    const handleFilter = e => {
      e.preventDefault()
      const d = e.target.value
      setFilterText(d)
      if (d === "") {
        setData(prevState => ({...prevState, applicants: ls.get("applicants"), loading: false}))
      } 
    }

    return <FilterComponent onFilter={e => handleFilter(e)} onClear={handleClear} filterText={filterText} />
  }, [filterText, resetPaginationToggle])

  return (
    <div className="row">
      {data.loading ? (
        <Loader />
      ) : (
        <>
          <div className="row w-100">
            <div className="col table">
              {data.applicants && data.applicants.length > 0 ? (
                <>
                  <DataTable
                    keys="id"
                    columns={columns}
                    progressPending={data.loading}
                    data={data.applicants}
                    initialPageLength={20}
                    pagination
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                    className="table"
                  />
                </>
              ) : (
                ' No Data '
              )}
            </div>
            <div
              className={`col ${previewData.length === 0 ? `d-flex align-items-center justify-content-center` : ``}`}
              style={{backgroundColor: 'white'}}
            >
              {previewData && previewData.length > 0 ? (
                <PreviewCard previewData={previewData} type={2} pageType="complete" key={genKey}></PreviewCard>
              ) : (
                <div className="">
                  <h3>No selected applicant yet.</h3>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <ModalFinalizeDocument show={show} onClose={handleClose}/>
    </div>
  )
}

ReviewCompleteDocumentsPage.propTypes = {}

export {ReviewCompleteDocumentsPage}
