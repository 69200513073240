import {validateOtp, sendOtp} from 'api/public/auth'
import {useFormik} from 'formik'
import React, {useState, useEffect} from 'react'
import {injectIntl} from 'react-intl'
import {connect} from 'react-redux'
import {Link, Redirect, useHistory} from 'react-router-dom'
import 'style/_login.scss'
import * as Yup from 'yup'
import * as auth from '../_redux/authRedux'
import OtpInput from 'react-otp-input';
import SecureLS from 'secure-ls'



function ForgotPassword(props) {
  const mobile = props.location.mobile
  const time = props.location.time
  const {intl} = props
  const history = useHistory()
  const [state, setState] = useState(true)
  const [isRequested, setIsRequested] = useState(false)
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState(300)
  const initialValues = {
    mobile: mobile,
    time: time
  }
  const ls = new SecureLS({encodingType: 'aes'})
  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  async function handleResend(e) {
    e.preventDefault()
    if (mobile === undefined) {
      alert("Please re-enter your mobile number")
      history.push({
        pathname: '/auth/login',
        mobile: mobile
      });
    } else {
      await sendOtp(mobile).then(
        res => {
          if (res.status != 200) {
            alert(res.message)
          }
          else {
            alert("New OTP has been sent")
          }
        }
      )
    }
     
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if (values.mobile === undefined) {
        alert("Please re-enter your mobile number")
        history.push({
          pathname: '/auth/login',
          mobile: mobile
        });
      }
      else {
        enableLoading()
        await validateOtp(values.mobile, values.otp, values.time).then(
          res => {
            if (res.status !== 200) {
              alert(res.message);  
            }
            else {
              const {token, route, id, company} = res.data
              disableLoading()
              ls.set('token', token)
              ls.set('ud', JSON.stringify(res.data))
              ls.set('uid', id)
              ls.set('route', route)
              if (res.data['switchable'] && parseInt(res.data['switchable']) === 1) {
                ls.set('company', JSON.parse(company).company[0])
              } else {
                ls.set('company', company)
              }
              props.login(id)
            }
            
        })
      }
  
    },
  })
  useEffect(() => {
    if (counter > 0) {
         setTimeout(() => {
           setCounter(counter - 1);
         }, 1000);
    } else {
      setState(false)
      setCounter("Resend")
    }
  }, [counter, state])
  return (
     <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{display: 'block'}}>
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">Enter OTP</h3>
              <div className="text-muted font-weight-bold">Enter otp code sent to your mobile no.</div>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp form-forgot_password"
            >
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              )}
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="6-Digit OTP"
                  type="tel"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  required
                  minLength = "6"
                  maxLength = "6"
                  className={`form-control form-control-solid h-auto py-5 px-6`}
                  name="otp"
                  {...formik.getFieldProps('otp')}
                />
              </div>
              <div className="form-group d-flex flex-wrap flex-center">
                <button
                  id="kt_otp_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-7 py-4 my-3 mx-4"
                  disabled={formik.isSubmitting}
                >
                  Submit
                </button>
                <button
                  id="kt_login_forgot_submit"
                  onClick={handleResend}
                  className="btn btn-light-success font-weight-bold px-7 py-4 my-3 mx-4"
                  disabled={state}
                >
                  {counter}
                </button>
                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-7 py-4 my-3 mx-4"
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
        </div>
      )}
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword))
