import React, { useEffect, useState, useContext } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Alert  } from '@mui/material';
import { Icon } from '@iconify/react';
import { EditModeContext } from 'context/EditModeContext';
import SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: 'aes' });

export default function CareerTable({ data }) {
  const context = useContext(EditModeContext);
  const [tableData, setTableData] = useState([]);
  const [newRow, setNewRow] = useState({});
  const [columns, setColumns] = useState(['position', 'client', 'location']);

  const [openAddColumnDialog, setOpenAddColumnDialog] = useState(false);
  const [newColumnName, setNewColumnName] = useState('');
  const [errorAddColumn, setErrorAddColumn] = useState('');

  const [openEditColumnDialog, setOpenEditColumnDialog] = useState(false);
  const [prevColumnName, setPrevColumnName] = useState('');
  const [editColumnName, setEditColumnName] = useState('');
  const [errorEditColumn, setErrorEditColumn] = useState('');
  const storedData = ls.get('crmCareerTableData');

  const { isEditMode } = context || {};

  const getAllKeys = (arr) => {
    return arr.reduce((acc, obj) => {
      Object.keys(obj).forEach(key => acc.add(key));
      return acc;
    }, new Set());
  };

  useEffect(() => {
    
    const parsedData = storedData ? JSON.parse(storedData) : data || []; // Fallback to 'data' prop if no stored data
    setTableData(parsedData);
    const allKeys = Array.from(getAllKeys(parsedData));
    setColumns(allKeys.filter(key => key !== '_id'));
  }, [data]);

  useEffect(() => {
    if (isEditMode) {
      ls.set('crmCareerTableData', JSON.stringify(tableData));
    }
  }, [tableData, isEditMode]);

  const handleAddRow = () => {
    const newRowValues = Object.values(newRow);
    const isEmptyRow = newRowValues.some((value) => value.trim() === '');

    if (isEmptyRow) {
      return;
    }

    const newRowData = [...tableData];
    newRowData.push(newRow);
    setTableData(newRowData);
    setNewRow({});
  };

  const handleAddColumn = () => {
    if (columns.includes(newColumnName.toLowerCase())) {
      setErrorAddColumn('Column already exists');
      return;
    } 
    if ( newColumnName.trim() === '') {
      setErrorAddColumn('Column name cannot be empty');
      return;
    }
    const updatedColumns = [...columns, newColumnName.toLowerCase()];
    const updatedData = tableData.map((row) => ({ ...row, [newColumnName.toLowerCase()]: '' }));
    setColumns(updatedColumns);
    setTableData(updatedData);
    setNewColumnName('');
  };

  const handleOpenAddColumnDialog = () => {
    setOpenAddColumnDialog(true);
  };


  const handleOpenEditColumnDialog = (column) => {
    setPrevColumnName(column); 
    setEditColumnName(column);
    setOpenEditColumnDialog(true);
  };


  const handleCloseAddColumnDialog = () => {
    setOpenAddColumnDialog(false);
    setErrorAddColumn('');
  };

  const handleCloseEditColumnDialog = () => {
    setOpenEditColumnDialog(false);
    setErrorEditColumn('');
  };

  const handleDeleteRow = (index) => {
    const newRowData = [...tableData];
    newRowData.splice(index, 1);
    setTableData(newRowData);
  };

  const handleDeleteColumn = (column) => {
    setTableData((prev) => prev.map((row) => {
      if (row.hasOwnProperty(column)) {
        const newRow = { ...row };
        delete newRow[column];
        return newRow;
      }
      return row;
    }));
    const newColumns = columns.filter((col) => col !== column);
    setColumns(newColumns);
  }

  const handleCellChange = (event, row, column) => {
    const newRowData = [...tableData];
    const newValue = event.target.value.slice(0, 50);
    newRowData[row][column] = newValue;
    setTableData(newRowData);
  };

  const handleColumnChange = (newColumn, prevColumn) => {
    if (columns.includes(newColumn)) {
      setErrorEditColumn('Column already exists');
      return
    }
    if (newColumn.trim() === '') {
      setErrorEditColumn('Column name cannot be empty');
      return
    }
    const newColumns = [...columns];
    const index = newColumns.indexOf(prevColumn);
    newColumns.splice(index, 1, newColumn);
    setColumns(newColumns);
    setTableData((prev) => prev.map((row) => {
      const newRow = { ...row };
      const oldValue = newRow[prevColumn];
      delete newRow[prevColumn];
      newRow[newColumn] = oldValue;
      return newRow;
    }));  

    handleCloseEditColumnDialog();
  }

  return (
    <>
      <Dialog
        open={openAddColumnDialog}
        onClose={handleCloseAddColumnDialog}
      >
        <DialogTitle>Add New Column</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="new-column-name"
            label="Column Name"
            type="text"
            fullWidth
            variant="standard"
            value={newColumnName}
            onChange={(e) => setNewColumnName(e.target.value)}
          />
          {errorAddColumn && <Alert severity="error">{errorAddColumn}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddColumnDialog}>Cancel</Button>
          <Button onClick={handleAddColumn}>Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditColumnDialog}
        onClose={handleCloseEditColumnDialog}
      >
        <DialogTitle>Edit Column</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="new-column-name"
            label="Column Name"
            type="text"
            fullWidth
            variant="standard"
            value={editColumnName}
            onChange={(e) => setEditColumnName(e.target.value)}
          />
          {errorEditColumn && <Alert severity="error">{errorEditColumn}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditColumnDialog}>Cancel</Button>
          <Button onClick={() => handleColumnChange(editColumnName, prevColumnName)}>Save</Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{ mt: 4, borderRadius: 2, boxShadow: 3, overflow: 'hidden' }}
      >
        {tableData.length !== 0 || isEditMode ? (
          <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
            <Table>
              <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                <TableRow>
                  {isEditMode && (
                    <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem', fontFamily: 'CircularStd, sans-serif' }}>
                      Action
                    </TableCell>
                  )}
                  {columns.map((column) => (
                    <TableCell
                      key={column}
                      align="center"
                      sx={{ fontWeight: 'bold', fontSize: '1rem', fontFamily: 'CircularStd, sans-serif' }}
                    >
                      {isEditMode ? (
                        <>
                          <Button onClick={() => handleOpenEditColumnDialog(column)}>{column.charAt(0).toUpperCase() + column.slice(1)}</Button>
                          <IconButton edge="end" onClick={() => handleDeleteColumn(column)} >
                            <Icon icon="memory:minus-box-fill" />
                          </IconButton>
                        </>
                      ) : (
                        column.charAt(0).toUpperCase() + column.slice(1)
                      )}
                    </TableCell>
                  ))}
                  {isEditMode && (
                    <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem', fontFamily: 'CircularStd, sans-serif' }}>
                      <IconButton edge="end" onClick={handleOpenAddColumnDialog} >
                        <Icon icon="ph:plus-fill" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow key={index}>
                    {isEditMode && (
                      <TableCell align="center">
                        <Button onClick={() => handleDeleteRow(index)}>Delete Row</Button>
                      </TableCell>
                    )}
                    {columns.map((column) => (
                      <TableCell align="center" sx={{ fontFamily: 'CircularStd, sans-serif' }} key={column}>
                        {isEditMode ? (
                          <TextField
                            value={row[column]}
                            onChange={(event) => handleCellChange(event, index, column)}
                            inputProps={{ maxLength: 50 }}
                          />
                        ) : (
                          row[column]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {isEditMode && (
                  <TableRow>
                    <TableCell align="center">
                      <Button
                        onClick={handleAddRow}
                      >
                        Add Row
                      </Button>
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell align="center" key={column}>
                        <TextField
                          value={newRow[column] || ''}
                          onChange={(event) => setNewRow({ ...newRow, [column]: event.target.value.slice(0, 50) })}
                          inputProps={{ maxLength: 50 }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (<Typography textAlign={'center'}>No Data</Typography>)}
      </Box>
    </>
  );
}
