import React from 'react'
import HeaderBannerChunk from './HeaderBannerChunk'
import Divider from '@mui/material/Divider'
import UserStatisticsChart from './UserStatisticsChart'
import DailySalesChart from './DailySalesChart'
import TaskProgressChart from './TaskProgressChart'

const ApexAdminDashboardPage = ({loading, data}) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="card mb-9" style={{flex: 1}}>
            <div className="d-flex justify-content-between">
              <HeaderBannerChunk
                icons="/media/svg/icons/Communication/Group.svg"
                title="No. of applicants"
                count={loading ? "loading" :  data.applicants}
                iconStyle="svg-icon svg-icon-2x svg-icon-warning d-block my-2"
              />
              <Divider orientation="vertical" flexItem />
              <HeaderBannerChunk
                icons="/media/svg/icons/Home/Home.svg"
                title="No. of personnel"
                count={loading ? "loading" : data.personnel}
                iconStyle="svg-icon svg-icon-2x svg-icon-success d-block my-2"
              />
              <Divider orientation="vertical" flexItem />
              <HeaderBannerChunk
                icons="/media/svg/avatars/001-boy.svg"
                title="Completed"
                count={loading ? "loading" : data.completed}
                iconStyle="svg-icon svg-icon-2x svg-icon-warning d-block my-2"
              />
              <Divider orientation="vertical" flexItem />
              <HeaderBannerChunk
                icons="/media/svg/avatars/003-girl-1.svg"
                title="Pending"
                count={loading ? "loading" : data.pending}
                iconStyle="svg-icon svg-icon-2x svg-icon-warning d-block my-2"
              />
              <Divider orientation="vertical" flexItem />
              <HeaderBannerChunk
                icons="/media/svg/avatars/004-boy-1.svg"
                title="For Examination"
                count={loading ? "loading" : data.examination}
                iconStyle="svg-icon svg-icon-2x svg-icon-warning d-block my-2"
              />
              <Divider orientation="vertical" flexItem />
              <HeaderBannerChunk
                icons="/media/svg/avatars/005-girl-2.svg"
                title="For Store Review"
                count={loading ? "loading" : data.storeReview}
                iconStyle="svg-icon svg-icon-2x svg-icon-warning d-block my-2"
              />
              
            </div>
          </div>
        </div>
      </div>
     {/* <div className="coming-soon-bg">
      <div className="row  coming-soon-component">
        <div className="col-lg-8">
          <UserStatisticsChart className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4">
          <div>
            <DailySalesChart className="card-stretch card-stretch-half gutter-b" />
          </div>
          <div>
            <TaskProgressChart className="card-stretch card-stretch-half gutter-b" />
          </div>
        </div>
      </div>

      </div>*/}
     
    </>
  )
}

// ApexAdminDashboard.propTypes = {

// }

export {ApexAdminDashboardPage}
