import React, { useEffect, useState, useContext, useRef } from 'react'
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import { Form, FormikProvider, useFormik } from 'formik';
import {Container} from 'react-bootstrap'
// material
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Card, Grid, Stack, Box, TextField, Typography, Button, InputAdornment, Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { EditModeContext } from 'context/EditModeContext';
import { EditTextDialog } from 'components/dialog/EditTextDialog'
import { EditContactDialog } from 'components/dialog/EditFormDialog'
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import SecureLS from 'secure-ls'

const RootStyle = styled('div')(() => ({
  position: 'relative',
  paddingTop: 42,
  paddingBottom: 42,
  display: 'flex',
  alignItems: 'center'
}));

const ls = new SecureLS({ encodingType: 'aes' })

// ----------------------------------------------------------------------

export default function ContactForm({ data }) {
  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState([]); // Assuming 3 text fields
  const [openDialogIndex, setOpenDialogIndex] = useState(null); // Track which dialog is open
  const [dialogValue, setDialogValue] = useState('');
  const [otherSenderInfo, setOtherSenderInfo] = useState([]);
  const [openTextField, setOpenTextField] = useState(false)
  const listOfFieldsAdded = useRef(null);
  const [submitted, setSubmitted] = useState(false);

  const [parentHover, setParentHover] = useState(false);
  const [childHover, setChildHover] = useState(null);

  const senderInfoArray = Array.isArray(otherSenderInfo) ? otherSenderInfo : [];


  useEffect(() => {
    const fields = ls.get('crmContactFieldAdded')
    setOtherSenderInfo( fields || data?.contactAddFields || [])
  }, [])

  useEffect(() => {
    const storedText = ls.get('crmContactFormText');
    const formTitle = storedText || data?.title || 'Message Us'; // Fallback to prop or default text

    setTextFields([formTitle]);
  }, [data]);



  // const Schema = Yup.object().shape({
  //   name: Yup.string().required('Title is required'),
  //   email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  //   contact: Yup.string().min(6).required('Contact No. is required'),
  //   message: Yup.string().min(3).required('Message is required'),
  // });

  const createValidationSchema = (fields, values) => {
    const shape = {
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        contact: Yup.string().min(6).required('Contact No. is required'),
        message: Yup.string().min(3).required('Message is required'),
      };

    if (senderInfoArray.length > 0) {
      senderInfoArray.forEach((field) => {
        shape[field] = Yup.string().required(`${field} is required`);
      });
    }

    return Yup.object().shape(shape);
  };


  const handleAddField = () => {
    setOpenTextField(true)
  
  }

  const handleDialogSaveText = (value) => {
   if(!otherSenderInfo.some((item) => item === value)){
      setOtherSenderInfo(prevItems => [...prevItems, value])
      setOpenTextField(false)
      const fields = listOfFieldsAdded.current = [...otherSenderInfo, value]
      ls.set('crmContactFieldAdded', fields);
   }else{
    window.alert(`${value} is already added`)
   }
  }

  const handleDeleteField = (index) => () => {
    setOtherSenderInfo((prevItems) => {
      const updatedItems = prevItems.filter((_, i) => i !== index);
      ls.set('crmContactFieldAdded', updatedItems);
      return updatedItems;
    });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      contact: '',
      message: '',
      ...senderInfoArray.reduce((acc, field) => ({ ...acc, [field]: '' }), {})
    },
    validationSchema: createValidationSchema(senderInfoArray),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
          const userId = process.env.REACT_APP_EMAILJS_USER_ID;
          const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
          const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT;
          await emailjs
            .send(serviceId, templateId, values, userId)
            .then(() => alert('Contact message sent successfully'));

          resetForm();
          setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, isValidating, getFieldProps, setFieldTouched, setErrors } = formik;



  useEffect(() => {
    if (isSubmitting) {
      Object.entries(errors).forEach(([key, value]) => {
        setErrors(prevErrors => ({ ...prevErrors, [key]: value }));
        setFieldTouched(key, true, true);
      });
      setSubmitted(false);
    }
  }, [errors, touched])

  const { isEditMode } = context || {};

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]); // Load the value of the clicked Typography into the dialog
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
    setOpenTextField(false);
  };

  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);

    if (openDialogIndex === 0) {
      ls.set('crmContactFormText', newValues[0]);
    }
  };

  return (
    <>
      <EditTextDialog
        open={openDialogIndex !== null}
        value={dialogValue}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onChange={setDialogValue}
      />
      <EditContactDialog
        open={openTextField}
        onClose={handleDialogClose}
        handleDialogSaveText={handleDialogSaveText}
      />
      <RootStyle>
        <Container fluid>
          <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)} hover={parentHover && !childHover} onMouseEnter={() => setParentHover(true)} onMouseLeave={() => setParentHover(false)} editType={'text'}>
            <Typography
              component="h2"
              variant="h3"
              color="common.black"
              sx={{ textAlign: 'center', my: { xs: 2, md: 3 }, fontWeight: '400' }}
            >
              {textFields[0]}
            </Typography>
          </EditableItemWrapper>
          <Box sx={{ textAlign: 'center', mb: 10 }}>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    justifyContent: 'center',
                    width: { xs: '100%', md: '45%' },
                    margin: { xs: '0', md: 'auto' }
                  }}
                >
                  <Box sx={{ width: '100%', margin: { xs: '0', md: 'auto' } }}>
                  <Card sx={{ px: 3, py: 5 }}>
                        <Stack spacing={3}>
                          <TextField
                            fullWidth
                            label="Name"
                            sx={{ color: '#000', '& label': { color: '#000' } }}
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                          />
                          <TextField
                            fullWidth
                            label="Email address"
                            {...getFieldProps('email')}
                            sx={{ color: '#000', '& label': { color: '#000' } }}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                          />
                          <TextField
                            fullWidth
                            label="Contact No."
                            {...getFieldProps('contact')}
                            sx={{ color: '#000', '& label': { color: '#000' } }}
                            error={Boolean(touched.contact && errors.contact)}
                            helperText={touched.contact && errors.contact}
                          />

                          {otherSenderInfo.length > 0 && (
                            otherSenderInfo.map((item, index) => {
                              console.log('item: ', item)
                              console.log('item error: ', errors[item])
                              return (
                                <TextField
                                  fullWidth
                                  label={item}
                                  {...getFieldProps(item)}
                                  sx={{ color: '#000', '& label': { color: '#000' } }}
                                  error={Boolean(touched[item] && errors[item])}
                                  helperText={touched[item] && errors[item]}
                                  InputProps={{
                                    startAdornment: isEditMode && (
                                      <InputAdornment position="start">
                                        <Tooltip title="Delete field" arrow>
                                          <DeleteForeverIcon onClick={handleDeleteField(index)} />
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )
                            })
                          )}

                          <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={5}
                            label="Message"
                            {...getFieldProps('message')}
                            sx={{ color: '#000', '& label': { color: '#000' } }}
                            error={Boolean(touched.message && errors.message)}
                            helperText={touched.message && errors.message}
                          />
                        </Stack>
                        {isEditMode && (<Stack>
                          <Button sx={{mt:'1vh'}}onClick={handleAddField}> Add Sender Information </Button>
                        </Stack>)}
                        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
                          <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{
                              backgroundColor: 'primary.main',
                              transition: 'all 0.4s ease',
                              '&:hover': {
                                backgroundColor: 'primary.main',
                                transition: 'all 0.4s ease'
                              }
                            }}
                            loading={isSubmitting}
                          >
                            Send
                          </LoadingButton>
                        </Stack>
                      </Card>
                  </Box>
                </Grid>
              </Form>
            </FormikProvider>
          </Box>
        </Container>
        </RootStyle>
    </>
  );
}
