import React, { useEffect, useState } from 'react';
import {Icon} from '@iconify/react'
import { Button, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, TextField, 
  Divider} from '@mui/material';

export const EditURLDialog = ({ open, initialValue, icons, onClose, onSave, onRemove }) => {
  const [value, setValue] = useState({icon: 0, alt: '' , link: ''});
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null)
  }, [open])

  const handleChange = (event) => {
    const newValue = event.target.value;

    setValue((prevValue) => ({...prevValue, link: newValue}));
    const isValid = newValue ? newValue.startsWith('http://') || newValue.startsWith('https://') : false;
    if (!isValid) {
      setError('Invalid URL');
    }
    if(newValue.trim().length === 0) {
      setError('URL can\'t be empty')
    }
    if(newValue.length > 255) {
      setError('URL is too long, exceeds 255 character limit')
    }
  };


  const handleIconChange = (index) => {
    setValue((prevValue) => ({
      ...prevValue,
      alt: index === 0 ? 'Facebook' : index === 1 ? 'Youtube' : index === 2 ? 'Twitter' : index === 3 ? 'Instagram' : 'LinkedIn',
      icon: index
    }));
  };

  const handleSave = () => {
    if (error) return;
    onSave(value);
    onClose();
  };

  const handleRemove = () => {
    onRemove();
    onClose();
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const isValid = value.link ? value.link.startsWith('http://') || value.link.startsWith('https://') : false;
    setError(isValid ? null : 'Invalid URL');
  }, [value]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit URL</DialogTitle>
      <DialogContent>
        {icons.map((icon, index) => (
          <Button variant={index === value.icon ? 'contained' : 'outlined'} key={index} onClick={() => handleIconChange(index)}>
            <Icon icon={icon} width={24} height={24} />
          </Button>
        ))}
        <Divider/>
        <TextField
          autoFocus
          margin="dense"
          label="URL"
          type="text"
          fullWidth
          value={value.link || 'https://example.com'}
          onChange={handleChange}
          error={Boolean(error)}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleRemove}>Remove</Button>
        <Button onClick={handleSave} disabled={Boolean(error)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};


