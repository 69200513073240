import React from 'react'
import 'style/_review.scss'

const ResumeWork = ({dt}) => {
  return (
    <div className="form-group ">
      <h2 className="spacing">Work Experience</h2>
      <hr />
      <b>Latest employment</b>
      <table key={`latest`}>
        <tbody>
          <tr key={'tr-1-1'}>
            <td width="400">Position:</td>
            <td className="font-weight-bold text-uppercase">{dt.latest}</td>
          </tr>
          <tr key={'tr-1-2'}>
            <td width="200">Start Date:</td>
            <td className="font-weight-bold text-uppercase">{dt.latest_start}</td>
          </tr>
          <tr key={'tr-1-3'}>
            <td width="200">End Date:</td>
            <td className="font-weight-bold text-uppercase">{dt.latest_end}</td>
          </tr>
          <tr key={'tr-1-4'}>
            <td width="200">Place of Work:</td>
            <td className="font-weight-bold text-uppercase">{dt.latest_pow}</td>
          </tr>
          <tr key={'tr-1-5'}>
            <td width="400">Monthly Salary:</td>
            <td className="font-weight-bold text-uppercase">{dt.latest_salary}</td>
          </tr>
          <tr key={'tr-1-6'}>
            <td width="200">Reason for Leaving:</td>
            <td className="font-weight-bold text-uppercase">{dt.latest_reason}</td>
          </tr>
        </tbody>
      </table>
      <b>Character reference</b>
      <table key={`character`}>
        <tbody>
          {dt.reference &&
            dt.reference.map((v, k) => {
              return (
                <>
                  <tr key={`name-${k}-a`}>
                    <td width="400" key={`name-${k}-ab`}>
                      Name & Position:
                    </td>
                    <td
                      key={`name-${k}-ac`}
                      className="font-weight-bold text-uppercase"
                    >{`${v} / ${dt.reference_pos[k]}`}</td>
                  </tr>
                  <tr key={`contact-${k}-b`}>
                    <td key={`name-${k}-ad`} width="200">
                      Contact No & Address:
                    </td>
                    <td
                      key={`name-${k}-ae`}
                      className="font-weight-bold text-uppercase"
                    >{`${dt.reference_contact[k]} / ${dt.reference_address[k]}`}</td>
                  </tr>
                </>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
export default ResumeWork
