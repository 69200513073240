import React, {useEffect, useState} from 'react'
import {JobsExamsPage} from './JobsExamsPage'
import {getUserByToken} from 'api/public/auth'
import {createJob} from 'api/private/jobs'
import ListJobs from './functions/listJobs'
import SecureLS from 'secure-ls'

const ls = new SecureLS({encodingType: 'aes'})

export default function JobsExamsPageContainer() {
  let [jobs, setJobs] = useState({
    jobs: [],
    loading: false,
  })

  useEffect(() => {
    getJobs()
    loadMiscData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //required for creating job
  let [miscData, setMiscData] = useState({})

  async function loadMiscData() {
    try {
      let user = await getUserByToken()
      if (user && user.status === 200) setMiscData({...miscData, company: ls.get('company'), id: user.data.id})
    } catch (er) {
      console.log(er)
    }
  }

  async function getJobs() {
    try {
      let jobs = await ListJobs
      setJobs({
        jobs: jobs,
      })
    } catch (er) {
      console.log(er)
    }
  }

  async function postJob(title, description, closeModal) {
    let data = {
      ...miscData,
      title: title,
      description: description,
    }
    try {
      let job = await createJob(data)
      if (job?.id) {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return <JobsExamsPage {...miscData} createJob={postJob} />
}
