// material
import React, { useEffect, useState, useContext,  } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Container, Typography, Grid, Box } from '@mui/material';
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { EditTextDialog } from 'components/dialog/EditTextDialog';
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper';
import BackgroundImageWithFallback  from 'components/crm/image/BackgroundImageWithFallback';
import ImageWithFallback from 'components/crm/image/ImageWithFallback';
import SecureLS from 'secure-ls';

import cover from '../../assets/wavy-one.png'
import defaultBackgroundImage1 from '../../assets/service-one.jpg'
import defaultBackgroundImage2 from '../../assets/service-two.jpg'
import defaultBackgroundImage3 from '../../assets/service-three.jpg'
import defaultBackgroundImage4 from '../../assets/service-four.jpg'
import ImageUploadDialog from '../../../../image/ImageWithDialog';
//
import { motion } from 'framer-motion';

const ls = new SecureLS({ encodingType: 'aes' });

export default function AboutServices({ data }) {
  const theme = useTheme();
  const context = useContext(EditModeContext);
  const [textFields, setTextFields] = useState(['Our','Services']);
  const [openDialogIndex, setOpenDialogIndex] = useState(null);
  const [dialogValue, setDialogValue] = useState('');

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [backgroundImageDialogOpen, setBackgroundImageDialogOpen] = useState(false);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState([]);

  const [parentHover, setParentHover] = useState(false);
  const [childHover, setChildHover] = useState(null);

  const { isEditMode } = context || {};

  const handleDialogOpen = (index) => {
    setDialogValue(textFields[index]);
    setOpenDialogIndex(index);
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(null);
  };

  const handleDialogSave = () => {
    const newValues = [...textFields];
    newValues[openDialogIndex] = dialogValue;
    setTextFields(newValues);

    if (openDialogIndex === 0) {
      ls.set('crmAboutTextOurServices', newValues[0]);
    }

  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true);
  };

  const handleBackgroundImageClick = (index) => {
    setSelectedImageIndex(index);
    setBackgroundImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
    setBackgroundImageDialogOpen(false);
  };

  const handleImageUpload = (newImageUrl) => {
    const updatedImages = [...imageUrls];
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrls(updatedImages);
    ls.set('crmAboutUsServicesImages', updatedImages);
  };

  const handleBackgroundImageUpload = (newImageUrl) => {
      const updatedImages = [...backgroundImageUrl];
      updatedImages[selectedImageIndex] = newImageUrl;
      setBackgroundImageUrl(updatedImages);
      ls.set('crmAboutUsServicesBackgroundImages', newImageUrl);
  };

  useEffect(() => {
    const passedData = data || {}; 
  
    const storedText = ls.get('crmAboutTextOurServices');
    const heroTitle = storedText || passedData?.title || 'Our Services'; 
  
    const storedServices = ls.get('crmAboutUsServicesImages');

    const parsedImages = storedServices || passedData?.services;
  
    setTextFields([heroTitle]);
    setImageUrls(parsedImages || [defaultBackgroundImage1, defaultBackgroundImage2, defaultBackgroundImage3, defaultBackgroundImage4]);

    const storedBackgroundImages = ls.get('crmAboutUsServicesBackgroundImages');
    const parsedBackgroundImages = storedBackgroundImages || passedData?.backgroundImage || cover;
  
    setBackgroundImageUrl([parsedBackgroundImages]);
  }, [data]);

  return (
    <>
    <EditTextDialog
        open={openDialogIndex !== null}
        value={dialogValue}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onChange={setDialogValue}
      />
      <ImageUploadDialog
        open={imageDialogOpen}
        onClose={handleImageDialogClose}
        onUpload={handleImageUpload}
        currentImage={imageUrls[selectedImageIndex]}
      />
      <ImageUploadDialog
        open={backgroundImageDialogOpen}
        onClose={handleImageDialogClose}
        onUpload={handleBackgroundImageUpload}
        currentImage={backgroundImageUrl[selectedImageIndex]}
      />
    <EditableItemWrapper onClick={() => !!isEditMode && handleBackgroundImageClick(0)} hover={parentHover && childHover === null } onMouseEnter={() => setParentHover(true)} onMouseLeave={() => setParentHover(false)} editType={'background'}>
    <BackgroundImageWithFallback
      src={backgroundImageUrl[0]}
      component={'aboutServices'}
      fallbackSrc={cover}
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: '130% 130%',
        backgroundPosition: '100% 100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        pb: { xs: 5, md: 10 }
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{ width: '100%', my: { md: 5, xs: 5 }, ml: { xs: 0 } }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pl: { xs: '0 !important', md: '40px !important' }
            }}
          >
            <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.55 }} style={{ marginTop: 0 }}>
             <EditableItemWrapper onClick={() => !!isEditMode && handleDialogOpen(0)} hover={childHover === 5} onMouseEnter={() => setChildHover(5)} onMouseLeave={() => setChildHover(null)} editType={'text'}>
             <Typography
                variant="h3"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: theme.typography.fontWeightBold,
                }}
                component="h2"               
              >
                {textFields[0].split(' ').slice(0, -1).join(' ')}{' '}
                <Typography
                  component="span"
                  variant="h3"
                  sx={{
                    color: theme.palette.secondary.main || '#FFA500',
                    fontWeight: theme.typography.fontWeightBold,
                  }}
                >
                  {textFields[0].split(' ').slice(-1)[0]}
                </Typography>
              </Typography>
             </EditableItemWrapper>
            </motion.div>
          </Grid>
        </Grid>
        <Grid container spacing={5} sx={{ width: '100%', ml: { xs: 0 } }}>
          {imageUrls.map((imageUrl, index) => (
            <Grid
              key={index}
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 1,
                pl: { xs: '0 !important', md: '40px !important' }
              }}
            >
              <EditableItemWrapper onClick={() => !!isEditMode && handleImageClick(index)} hover={childHover === index} onMouseEnter={() => setChildHover(index)} onMouseLeave={() => setChildHover(null)} editType={'image'}>
                <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.55 }} style={{ marginTop: 0, width: '100%' }}>
                  <ImageWithFallback
                    src={imageUrl}
                    fallbackSrc={imageUrl === "service1" ? defaultBackgroundImage1 : imageUrl === "service2" ? defaultBackgroundImage2 : imageUrl === "service3" ? defaultBackgroundImage3 : defaultBackgroundImage4}
                    sx={{ 
                      height: { xs: 250, sm: 300, md: 350 }, 
                      width: { xs: 350, sm: 450, md: 500 }, 
                      objectFit: 'cover' 
                    }}
                  />
                </motion.div>
              </EditableItemWrapper>
            </Grid>
          ))}
        </Grid>
      </Container>
      </BackgroundImageWithFallback>
    </EditableItemWrapper>
    </>
  );
}
