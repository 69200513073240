import React, { useState, useEffect } from 'react';
import { Button, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, TextField, FormHelperText } from '@mui/material';

export const EditTextDialog = ({ open, value, onClose, onSave, onChange, textLimit }) => {
  const [error, setError] = useState(null);
  const charLimit = textLimit !== undefined ? textLimit : 255;

  const handleChange = (e) => {
    const newValue = e.target.value;
    const newError = newValue.trim().length === 0 ? 'Text can\'t be empty' : newValue.length > charLimit  ? `Text is too long, exceeds ${charLimit} character limit` : null;
    setError(newError);
    onChange(newValue);
  };

  const handleSave = () => {
    if (error) return;
    onSave(value);
    onClose();
  };

  useEffect(() => {
    setError(value.trim().length === 0 ? 'Text can\'t be empty' : value.length > charLimit ? `Text is too long, exceeds ${charLimit} character limit` : null);
  }, [open]);


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Text</DialogTitle>
      <DialogContent>
        <TextField
          value={value}
          onChange={handleChange}
          fullWidth
          multiline
          rows={6} // Adjust the number of rows as needed
          variant="outlined"
          sx={{ maxWidth: '100%' }}
          error={Boolean(error)}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={Boolean(error) || value.trim().length === 0}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};


