import React from 'react'
//import { useSubheader } from "../../../_metronic/layout";
// import RecruitmentPageListItem from './RecruitmentPageListItem';
// import { Link } from 'react-router-dom';
// import { useState } from 'react'
// import ComingSoonModal from '../../modal/ComingSoonModal';
import Board from '@lourenci/react-kanban'
import '@lourenci/react-kanban/dist/styles.css'

export const RecruitmentPage = () => {
  const board = {
    columns: [
      {
        id: 1,
        title: 'Applicant Submissions',
        cards: [
          {
            id: 1,
            title: 'Service Crew - Jose Angelito',
            description: 'Recruiter: Miguel Quintos',
          },
          {
            id: 2,
            title: 'Store Manager - Anthon Page',
            description: 'Recruiter: Darlo Quintos',
          },
          {
            id: 2,
            title: 'Service Crew - Sy Miguel',
            description: 'Recruiter: Mark Ultra',
          },
        ],
      },
      {
        id: 2,
        title: 'Examination',
        cards: [
          {
            id: 2,
            title: 'Store Manager - Gia Angel',
            description: 'Recruiter: Darlo Quintos',
          },
          {
            id: 2,
            title: 'Service Crew - Emmanuel Pablito',
            description: 'Recruiter: Mark Ultra',
          },
        ],
      },
      {
        id: 3,
        title: 'For-Review',
        cards: [
          {
            id: 3,
            title: 'Service Crew - Abby Domacena',
            description: 'Recruiter: Mark Ultra',
          },
        ],
      },
      {
        id: 4,
        title: 'For Recruitment Head Interview ',
        cards: [
          {
            id: 4,
            title: 'Store Manager - JR Pogi',
            description: 'Recruiter: Mark Ultra',
          },
          {
            id: 4,
            title: 'Store Manager - Jeffrey Pareja',
            description: 'Recruiter: Mark Ultra',
          },
        ],
      },
      {
        id: 5,
        title: 'For Store Review',
        cards: [],
      },
      {
        id: 6,
        title: 'Requirements Submission',
        cards: [],
      },
      {
        id: 7,
        title: 'Hired',
        cards: [],
      },
    ],
  }

  return (
    <div className="coming-soon-bg">
      <div className="form-group row coming-soon-component">
        <div className="col-lg-2">
          <select className="form-control" name="status" placeholder="Filter by Type">
            <option value="">All</option>
            <option value="0">Service Crew</option>
            <option value="1">Store Manager</option>
          </select>
          <small className="form-text text-muted">
            <b>Filter</b> by Type
          </small>
        </div>
        <div className="col-lg-2">
          <select className="form-control" placeholder="Filter by Type" name="condition">
            <option value="">All</option>
            <option value="0">Miguel Quintos</option>
            <option value="1">Darlo Miguel</option>
            <option value="2">Mark Ultra</option>
            <option value="3">Jeffrey Pareja</option>
          </select>
          <small className="form-text text-muted">
            <b>Filter</b> by Recruiter
          </small>
        </div>
        <div className="col-lg-2">
          <input type="text" className="form-control" name="searchText" placeholder="Search" />
          <small className="form-text text-muted">
            <b>Search</b> in all fields
          </small>
        </div>
      </div>
      <div className="coming-soon-component">
        <Board initialBoard={board} />
      </div>
    </div>
  )

  // const suhbeader = useSubheader();
  // suhbeader.setTitle("New Applicants");

  // const [showAddForm, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // return (<>

  //     <div className='row'>
  //         <div className="col">
  //             <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
  //                 <thead>
  //                     <tr className="text-left text-uppercase">
  //                         <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Applicants</span></th>
  //                         <th style={{ minWidth: "100px" }}>Actions</th>
  //                     </tr>
  //                 </thead>
  //                 {/* <tbody>
  //                     <RecruitmentPageListItem applicantName="Darlo Miguel Illagan" dateCreated='February 25 2021 03:29 PM' onClick={handleShow} />
  //                     <RecruitmentPageListItem applicantName="Miguel Quintos QXXV" dateCreated='February 25 2021 03:29 PM' onClick={handleShow} />
  //                     <RecruitmentPageListItem applicantName="Jeffrey Pareja" dateCreated='February 25 2021 03:29 PM' onClick={handleShow} />
  //                     <RecruitmentPageListItem applicantName="Renz Basillio" dateCreated='February 25 2021 03:29 PM' onClick={handleShow} />
  //                     <RecruitmentPageListItem applicantName="Mark Ultra" dateCreated='February 25 2021 03:29 PM' onClick={handleShow} />
  //                 </tbody> */}
  //             </table>

  //         </div>
  //         <div className="col" style={{ backgroundColor: 'white', }}>
  //             <div className='form'>
  //                 <div style={{ display: 'flex', justifyContent: 'center' }}>
  //                     <div className="mt-5 text-center">
  //                         <div className="symbol symbol-60 symbol-xxl-100 mr-5">
  //                             <div
  //                                 className="symbol-label"
  //                                 style={{
  //                                     backgroundImage: `url(https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)`
  //                                 }}
  //                             ></div>

  //                             {/* <i className="symbol-badge bg-success"></i> */}
  //                         </div>
  //                         <div>
  //                             <span
  //                                 href="#"
  //                                 className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
  //                                 Mark Ultra
  //                         </span>
  //                             <div className="text-muted">Test</div>
  //                             <div className="mt-2">
  //                                 <Link
  //                                     to="/reviewapplicant"
  //                                     className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
  //                                 >
  //                                     Review Application
  //                                 </Link>
  //                             </div>
  //                         </div>
  //                     </div>
  //                 </div>

  //                 <div className='form-group row'>
  //                     <label className='col-xl-7 col-lg-7 col-form-label'>Reference Number: {<span className="font-weight-bold">EMQUF2F73</span>}</label>

  //                     <label className='col-xl-7 col-lg-7 col-form-label'>Email: {<span className="font-weight-bold">emquintos.developer@gmail.com</span>}</label>

  //                     <label className='col-xl-7 col-lg-7 col-form-label'>Contact Number: {<span className="font-weight-bold">09273342196</span>}</label>

  //                     <label className='col-xl-7 col-lg-7 col-form-label'>Permanent Address: {<span className="font-weight-bold">123, Sparkle Street, Starry Subd., Manila</span>}</label>

  //                     <label className='col-xl-7 col-lg-7 col-form-label'>Present Address: {<span className="font-weight-bold">123, Sparkle Street, Starry Subd., Manila</span>}</label>
  //                 </div>

  //                 <div className="separator separator-dashed my-7"></div>

  //                 <div className="card-toolbar">
  //                     <button
  //                         type="submit"
  //                         variant="contained" color="secondary"
  //                         className="btn btn-primary mr-2" onClick={handleShow}>
  //                         IQ Test
  //                         </button>
  //                     <button
  //                         type="submit"
  //                         variant="contained" color="secondary"
  //                         className="btn btn-primary mr-2" onClick={handleShow}>
  //                         Occupational Test
  //                         </button>
  //                     <button
  //                         type="submit"
  //                         variant="contained" color="secondary"
  //                         className="btn btn-primary mr-2" onClick={handleShow}>
  //                         Personality Test
  //                         </button>

  //                 </div>

  //                 <div className="separator separator-dashed my-7"></div>

  //                 <div className='row'>
  //                     <div className='col'>
  //                         <div className='form-group row'>
  //                             <label className='col-xl-7 col-lg-7 col-form-label'>Sex: {<span className="font-weight-bold">Male</span>}</label>

  //                             <label className='col-xl-7 col-lg-7 col-form-label'>Civil Status: {<span className="font-weight-bold">Single</span>}</label>

  //                             <label className='col-xl-7 col-lg-7 col-form-label'>Religion: {<span className="font-weight-bold">Test</span>}</label>

  //                             <label className='col-xl-7 col-lg-7 col-form-label'>Citizenship: {<span className="font-weight-bold">Test</span>}</label>

  //                             <label className='col-xl-7 col-lg-7 col-form-label'>Height: {<span className="font-weight-bold">Test</span>}</label>

  //                             <label className='col-xl-7 col-lg-7 col-form-label'>Weight: <span className="font-weight-bold">Test</span></label>
  //                         </div>
  //                     </div>

  //                     <div className='col'>

  //                         <div className='form-group row'>
  //                             <span className="font-weight-bold d-block">Government ID's</span>
  //                         </div>
  //                         <div className='form-group row'>
  //                             <label className='col-xl-7 col-lg-7 col-form-label'>TIN: {<span className="font-weight-bold">Test</span>}</label>

  //                             <label className='col-xl-7 col-lg-7 col-form-label'>PHILHEALTH: {<span className="font-weight-bold">Test</span>}</label>

  //                             <label className='col-xl-7 col-lg-7 col-form-label'>SSS: {<span className="font-weight-bold">Test</span>}</label>

  //                             <label className='col-xl-7 col-lg-7 col-form-label'>PAGIBIG: {<span className="font-weight-bold">Test</span>}</label>
  //                         </div>

  //                         <div className='form-group row'>
  //                             <span className="font-weight-bold d-block">Emergency Contact</span>
  //                         </div>
  //                         <div className='form-group row'>
  //                             <label className='col-xl-7 col-lg-7 col-form-label'>Name: {<span className="font-weight-bold">Test</span>}</label>

  //                             <label className='col-xl-7 col-lg-7 col-form-label'>Contact Number: {<span className="font-weight-bold">Test</span>}</label>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>

  //         </div>
  //     </div>

  //     <ComingSoonModal show={showAddForm} onClose={handleClose} />
  // </>);
}
