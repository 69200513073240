import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MainLayout from 'components/crm/templates/first-template/layout';
import { fetchAppliedLayout } from 'api/private/crm';

export const PreviewPage = () => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const renderData = async () => {
      try {
        const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {}; 
        const response = await fetchAppliedLayout(); 

        if (response && response.layout) {
          const {
            meta, header, footer, homePage, aboutPage, careerPage,
            contactPage, newsPage, primaryColor, secondaryColor,
          } = response.layout;

          const finalLayoutData = {
            primaryColor: savedLayoutData.primaryColor || primaryColor || '#000000',
            secondaryColor: savedLayoutData.secondaryColor || secondaryColor || '#FFA500',
            header, footer, meta, homePage, aboutPage, careerPage, contactPage, newsPage,
          };

          setLayoutData(finalLayoutData);
        } else {
          console.error('Layout data not found in the response.');
        }
      } catch (error) {
        console.error('Failed to fetch the applied layout', error);
      }
    };

    renderData(); 
  }, []); 

  if (!layoutData) return <div>Loading...</div>; 

  const theme = createTheme({
    palette: {
      primary: { main: layoutData.primaryColor },
      secondary: { main: layoutData.secondaryColor },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MainLayout
        headerData={layoutData.header}
        footerData={layoutData.footer}
        metaTagsData={layoutData.meta}
        homePageData={layoutData.homePage}
        careerPageData={layoutData.careerPage}
        contactPageData={layoutData.contactPage}
        newsPageData={layoutData.newsPage}
        aboutPageData={layoutData.aboutPage}
        published={false}
        applyPageData={layoutData.applyPage}
        primaryColor={layoutData.primaryColor}
        secondaryColor={layoutData.secondaryColor}
      />
    </ThemeProvider>
  );
};
