import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, {useEffect, useState} from 'react'
import {Alert} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import Loader from 'components/preloader'
import {getUserByToken} from 'api/public/auth'
import {postStoreAccounts, getSpecificStore} from 'api/private/store'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})
const useStyles1 = styled(theme => ({
  button: {},
  input: {
    display: 'none',
  },
}))

const useStyles2 = styled(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}))

const StoreDetailsPage = () => {
  const classes1 = useStyles1()
  const classes2 = useStyles2()
  const [data, setData] = useState([])
  const {id, company} = useParams()
  const [process, setProcess] = useState({
    success: null,
    loading: false,
    enableEdit: false,
  })
  const [user, setUser] = useState([])

  const getUser = async () => {
    const r = await getUserByToken()
    if (r && r.status === 200) {
      setUser({...user, ...r.data})
      setData({...data, company: ls.get('company'), created_by: user.id, type: 5, store_address_url: ''})
    }
  }

  const getStore = async () => {
    const r = await getSpecificStore(id, company)

    if (!r[0]) return console.error(`Store not found.`)

    const formatData = JSON.parse(r[0].details)
    setData({...data, ...formatData})
  }

  const handleSubmit = async e => {
    e.preventDefault()
    await setProcess({
      loading: true,
    })
    const r = await postStoreAccounts(data)
    if (r) {
      await setProcess({
        loading: false,
        success: true,
      })
      await setData([])
    } else {
      await setProcess({
        loading: false,
      })
    }
  }

  const handleEdit = () => {
    setProcess({...process, enableEdit: true})
    getStore()
  }

  useEffect(() => {
    getUser()
    getStore()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  const [store_type] = useState(['Jollibee', 'Mang Inasal', 'Zarks', 'Chowking', 'Greenwich', 'Red Ribbon', 'EBC'])
  return (
    <div className={`${process.loading ? `is-loading` : ``}`}>
      {!process.loading ? (
        <div className="row">
          <div className="card p-5">
            <div>
              <h1 id="contained-modal-title-vcenter">{process.enableEdit ? 'Edit' : ''} Store details</h1>
            </div>

            <div>
              <form onSubmit={handleSubmit}>
                <div>
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    label="-"
                    className={classes2.textField}
                    defaultValue={data ? data.store_type : ''}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes2.menu,
                      },
                    }}
                    inputProps={{readOnly: !process.enableEdit ? true : ''}}
                    helperText="Type of Store"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                    onInput={e => (e ? setData({...data, store_type: e.target.value}) : false)}
                  >
                    <option value="" disabled>
                      Store Type
                    </option>
                    {store_type && store_type.length > 0
                      ? store_type.map(d => {
                          return (
                            <option key={d} value={d}>
                              {d}
                            </option>
                          )
                        })
                      : ''}
                  </TextField>
                  <TextField
                    id="outlined-name"
                    label="Store branch"
                    className={classes2.textField}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                    inputProps={{readOnly: !process.enableEdit ? true : ''}}
                    value={data ? data.name : ''}
                    onChange={e => (e ? setData({...data, name: e.target.value}) : false)}
                  />
                  <TextField
                    id="outlined-location"
                    label="Location"
                    className={classes2.textField}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                    value={data ? data.store_address : ''}
                    inputProps={{readOnly: !process.enableEdit ? true : ''}}
                    onChange={e => (e ? setData({...data, store_address: e.target.value}) : false)}
                  />
                  <TextField
                    id="outlined-email"
                    label="Email address"
                    className={classes2.textField}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                    value={data ? data.email : ''}
                    inputProps={{readOnly: !process.enableEdit ? true : ''}}
                    onChange={e => (e ? setData({...data, email: e.target.value}) : false)}
                  />

                  <div className="mx-1">
                    {process.success !== null && process.enableEdit ? (
                      <Alert variant={process.success === true ? 'success' : 'danger'}>
                        {process.success === true ? (
                          <p className="mb-0">New store created!</p>
                        ) : (
                          <p className="mb-0">There seems to be a problem.</p>
                        )}
                      </Alert>
                    ) : (
                      ''
                    )}
                  </div>
                  {process.enableEdit ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      className={classes1.button}
                      disabled={process.loading}
                    >
                      Save Store
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </form>
              {!process.enableEdit ? (
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={handleEdit}
                  className={classes1.button}
                >
                  Edit Store details
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  )
}
StoreDetailsPage.propTypes = {}
export {StoreDetailsPage}
