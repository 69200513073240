import {getFailedApplicants, updateFailedApplicantStatus} from 'api/private/applicants'
import PreviewCard from './applicantPreview'
import React, {useEffect, useState, useMemo} from 'react'
import DataTable from 'react-data-table-component'
import 'style/_table.scss'
import {useSubheader} from '../../../_metronic/layout'
import FailApplicantList from './applicantList'
import Loader from 'components/preloader'
var dayjs = require('dayjs')
export const FailApplicantPage = () => {
  const suhbeader = useSubheader()
  suhbeader.setTitle('Failed Applicants')
  const [data, setData] = useState({
    applicants: [],
    loading: false,
  })
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [loadingPending, setLoadingPending] = useState(false);
  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const filteredItems = data.applicants.filter(
    item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  )
  const [previewData, setPreviewData] = useState()

  const capitalizeName = str => {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

  const handleUpdateStatus = async(id, status, buttonType) =>{
   if(buttonType === 'pending'){
    setLoadingPending(true);
   }else{
    setLoadingComplete(true)
   }
    const result = await updateFailedApplicantStatus(id, status)
    if(result){
      setLoadingComplete(false);
      setLoadingPending(false)
      window.location.reload();
    }
  }

  const listApplicants = async () => {
    setData({...data, loading: true})
    const r = await getFailedApplicants()
    if (r.length > 0) {
      let dt = []
      for (let index = 0; index < r.length; index++) {
        let det = r[index]
        dt.push({
          applicant_id: r[index].applicant_id,
          firstName: r[index].first_name,
          email: r[index].email,
          mobileNumber:r[index].person_contact_no_mob,
          profileImage: r[index].profile,
          details: det,
          dateApplied:r[index].date_applied,
          name: capitalizeName(
            `${det.first_name} ${
              det.middle_name.length > 0 || det.middle_name.toLowerCase() !== 'n/a'
                ? `${det.middle_name.charAt(0)}.`
                : ''
            }`,
          ),
        })
      }
      setData({...data, applicants: dt, loading: false})
    }else{
      setData({...data, loading: false})
    }
  }


  useEffect(() => {
    listApplicants()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const previewApplicant = async (id, dateApplied, applicantName, email, mobileNumber) => {
    console.log("ID ngini",id, dateApplied, applicantName, email, mobileNumber)
    
    const data = {
      id,
      dateApplied,
      applicantName,
      email,
      mobileNumber
    }
    setPreviewData(data)
  }

  const columns = [
    {
      name: 'Applicants',
      selector: 'name',
      cell: row => (
        <FailApplicantList
          image={row.profileImage}
          applicantName={row.name}
          dateApplied={row.dateApplied}
          email={row.email}
          mobileNumber={row.mobileNumber}
          id={row.applicant_id}
          action={previewApplicant}
        />
      ),
    },
  ]
  const FilterComponent = ({filterText, onFilter, onClear}) => (
    <>
      <input
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        defaultValue={filterText}
        onChange={onFilter}
        key="filterName"
        autoFocus={true}
        className={`form-control w-75 mr-5`}
      />
      <button type="button" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={onClear}>
        X
      </button>
    </>
  )

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    const handleFilter = e => {
      e.preventDefault()
      const d = e.target.value
      setFilterText(d)
    }

    return <FilterComponent onFilter={e => handleFilter(e)} onClear={handleClear} filterText={filterText} />
  }, [filterText, resetPaginationToggle])

  return (
    <div>
      {data.loading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col table">
              {data.applicants && data.applicants.length > 0 ? (
                <DataTable
                  keys="id"
                  columns={columns}
                  progressPending={data.loading}
                  data={filteredItems}
                  initialPageLength={20}
                  pagination
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  className="table"
                />
              ) : (
                ' No Data '
              )}
            </div>
            <div className="col" style={{backgroundColor: 'white'}}>
              {previewData && <PreviewCard 
              previewData={previewData} 
              handleUpdateStatus={handleUpdateStatus} 
              loadingPending={loadingPending} 
              loadingComplete={loadingComplete}/>}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
