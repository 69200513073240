import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const EditIconDialog = ({ open, onClose, stepIndex, stepValue, onIconChange, stepIconsDefault }) => {
  const [selectedIcon, setSelectedIcon] = useState(stepValue);
  const [currentStepIndex, setCurrentStepIndex] = useState(stepIndex);

  useEffect(() => {
    setSelectedIcon(stepValue);
    setCurrentStepIndex(stepIndex);
  }, [stepIndex, stepValue]);

  const handleIconChange = (index) => {
    setSelectedIcon(index);
  };

  const handleSave = () => {
    onIconChange(currentStepIndex, selectedIcon);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Step Icon</DialogTitle>
      <DialogContent>
        {stepIconsDefault.map((icon, index) => (
          <Button variant={selectedIcon === index ? 'contained' : 'outlined'} key={index} onClick={() => handleIconChange(index)}>
            {icon}
          </Button>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditIconDialog;