import {get, post} from 'api/utils/requests'
import SecureLS from 'secure-ls'
import React, {useMemo, useState, useEffect} from 'react'
const ls = new SecureLS({encodingType: 'aes'})

//const userData = ls.get('ud') ? JSON.parse(ls.get('ud')) : ''
const company = ls.get('company')
const uid = ls.get('uid')

const DOC_URL = `internal/record/documents/`
const DOC_SPECIFIC_URL = `internal/record/specific/documents/`
const DOC_GEN_URL = `https://sparkle-time-keep.herokuapp.com/api/upload`
const DOC_UPLOAD_URL = `internal/record/upload/documents`
const DOC_UPDATE_STATUS = `internal/record/document/update`
const DOC_UPDATE_PENDING = `internal/record/review_app/documents`
const DOC_UPDATE_FOR_TRAINING = `internal/record/recruitment/final`
const DOC_CREATE_SANCTIONS = `internal/personnel/sanctions`
const DOC_SANCTIONS_LIST = `internal/personnel/sanctions_list`
const DOC_TRANSFER_TRAINING = `internal/personnel/transfer`
const DOC_PERSONNEL_LOGS = `internal/personnel/logs`
const SPECIFIC_DOCS_APP = `internal/documents/specific/`
const COUNT_APP = `internal/applicants/count`
const COUNT_PERSONNEL = `internal/personnel/count`
const COUNT_COMPLETED = `internal/completed/count`
const COUNT_PENDING = `internal/pending/count`
const COUNT_EXAMINATION = `internal/examination/count`
const COUNT_STORE_REVIEW = `internal/store/review/count`
const user_details = localStorage.getItem('persist:v713-demo1-auth')
let accessLevel = 3
if (user_details !== null) {
  const {user} = JSON.parse(user_details)
  if (user !== undefined) {
    const {user_level} = JSON.parse(user) ? JSON.parse(user) : 0
    accessLevel = parseInt(user_level)  
  }
  
}
 
export const getDocuments = async status => {
  let _url = `${DOC_URL}${company}/${status}/${accessLevel}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getSpecificDocuments = async (id, status) => {
  let _url = `${DOC_SPECIFIC_URL}${id}/${status}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const generateUploadUrl = async (_blob, _file) => {
  return await fetch(`${DOC_GEN_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({file_type: _file.type}),
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

export const postUploadDocument = async (_blob, _file, _genUrl, id, company, doctype, docid) => {
  let _url = `${DOC_UPLOAD_URL}`
  let binary = atob(_blob.split(',')[1])

  let array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }

  let blobData = new Blob([new Uint8Array(array)], {
    type: _file.type,
  })

  const result = await fetch(_genUrl.uploadURL, {
    method: 'PUT',
    body: blobData,
  }).then(response => {
    return response
  })

  if (result.status === 200) {
    const _data = {
      id: id,
      docid: docid,
      company: company,
      name: _genUrl.photoFilename,
      type: doctype,
      upload: false
    }

    return await post(_data, _url)
      .then(r => {
        if (r.status === 200) {
          return r.status
        } else {
          return false
        }
      })
      .catch(err => console.error(err))
  }
}

export const postDocumentChange = async data => {
  let _url = `${DOC_UPDATE_STATUS}`
  return await post(data, _url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const postUpdateDocumentPending = async data => {
  let _url = `${DOC_UPDATE_PENDING}`

  data.id = uid
  data.company = company

  return await post(data, _url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const postUpdateDocumentTraining = async data => {
  let _url = `${DOC_UPDATE_FOR_TRAINING}`

  data.id = uid
  data.company = company

  return await post(data, _url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const postSanctions = async (_blob, _file, _genUrl, id, sanction, section, remarks, hr, hrName, hrEmail) => {
  let _url = `${DOC_CREATE_SANCTIONS}`
  let binary = atob(_blob.split(',')[1])

  let array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }

  let blobData = new Blob([new Uint8Array(array)], {
    type: _file.type,
  })

  const result = await fetch(_genUrl.uploadURL, {
    method: 'PUT',
    body: blobData,
  }).then(response => {
    return response
  })

  if (result.status === 200) {
    const _data = {
      aplid: id,
      sanction: sanction,
      section: section,
      name: _genUrl.photoFilename,
      remarks: remarks,
      hr: hr,
      hrName: hrName,
      hrEmail: hrEmail
    }

    return await post(_data, _url)
      .then(r => {
        if (r.status === 200) {
          return r.status
        } else {
          return false
        }
      })
      .catch(err => console.error(err))
  }
}

export const getSanctionsList = async (id) => {
  let _url = `${DOC_SANCTIONS_LIST}/${id}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getPersonnelLogs = async () => {
  let _url = `${DOC_PERSONNEL_LOGS}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const transferTraining = async (id, status) => {
  let _url = `${DOC_TRANSFER_TRAINING}/${id}/${status}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getSearchSpecificDocuments = async (_name, _status) => {
  let company = ls.get('company')
  let name = {name: _name}

  let _url = `${SPECIFIC_DOCS_APP}/${company}/${_status}/${accessLevel}`
  return await post(name, _url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getCountApplicant = async () => {
  let company = ls.get('company')

  let _url = `${COUNT_APP}/${company}/${accessLevel}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getCountPersonnel = async () => {
  let company = ls.get('company')

  let _url = `${COUNT_PERSONNEL}/${company}/${accessLevel}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getCountCompleted = async () => {
  let company = ls.get('company')

  let _url = `${COUNT_COMPLETED}/${company}/${accessLevel}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getCountPending = async () => {
  let company = ls.get('company')

  let _url = `${COUNT_PENDING}/${company}/${accessLevel}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getCountExamination = async () => {
  let company = ls.get('company')

  let _url = `${COUNT_EXAMINATION}/${company}/${accessLevel}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getCountStoreReview = async () => {
  let company = ls.get('company')

  let _url = `${COUNT_STORE_REVIEW}/${company}/${accessLevel}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

