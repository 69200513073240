import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormHelperText } from '@mui/material';

const EditStepDialog = ({ open, value, onClose, onSave, onChange }) => {
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const newError = newValue.length > 25 ? 'Text is too long, exceeds 25 character limit' : null;
    setError(newError);
    onChange(newValue);
  };

  useEffect(() => {
    setError(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Step Text</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Step Text"
          type="text"
          fullWidth
          value={value}
          onChange={handleChange}
          error={Boolean(error)}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(value)} disabled={Boolean(error)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditStepDialog;
