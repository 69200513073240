import React from 'react'
import { Button } from '@mui/material';
import {Modal} from 'react-bootstrap'
const ConfirmModal = ({show, save, onClose}) => {
  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Confirmation?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h2>Do you wish to re-send the examination link?</h2>
        <Button onClick={() => save()}>Yes, send It</Button>
        <Button onClick={onClose}>Nope</Button>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
