import React from 'react'
import 'style/_review.scss'

const HistorySelf = ({d}) => {
  console.log(d.last_menstruation_date)
  return (
    <div className="form-group">
      <h2>Medical History</h2>
      <div className="row mt-3">
        <div className="col-md-6">
          <table className="self-medical">
            <tbody>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_alcohol_abuse"
                    disabled={`${d.medHISTORY_alcohol_abuse !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_alcohol_abuse !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Alcohol Abuse</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_anemia"
                    disabled={`${d.medHISTORY_anemia !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_anemia !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Anemia</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_anesthetic"
                    disabled={`${d.medHISTORY_anesthetic !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_anesthetic !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />

                  <label className="control-label">Anesthetic Complication</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_anxiety"
                    disabled={`${d.medHISTORY_anxiety !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_anxiety !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Anxiety Disorder</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_asthma"
                    disabled={`${d.medHISTORY_asthma !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_asthma !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Asthma</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_autoimmuneProblems"
                    disabled={`${d.medHISTORY_autoimmuneProblems !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_autoimmuneProblems !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />

                  <label className="control-label">Autoimmune Problems</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_birthDefetchs"
                    disabled={`${d.medHISTORY_birthDefetchs !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_birthDefetchs !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Birth Defects</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_bladdeProblems"
                    disabled={`${d.medHISTORY_bladdeProblems !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_bladdeProblems ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Bladder Problems</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_bleedingDisease"
                    disabled={`${d.medHISTORY_bleedingDisease !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_bleedingDisease ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Bleeding Disease</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_bloodClots"
                    disabled={`${d.medHISTORY_bloodClots !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_bloodClots !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Blood Clots</label>
                </td>
              </tr>
              {d.last_menstruation_date !== "undefined"
                ? 
                  <tr className="form-group">
                    <td>
                      <input
                        type="checkbox"
                        name="medHISTORY_bloodClots"
                        disabled={`${d.medical_history_dysmenorrhea !== undefined ? `disabled` : `readonly`}`}
                        checked={`${d.medical_history_dysmenorrhea !== "undefined" ? `checked` : ``}`}
                        className="form-control inline-chkbox "
                      />
                      <label className="control-label">Dysmenorrhea</label>
                    </td>
                  </tr>
                :
                  ''
              }
              {d.last_menstruation_date !== "undefined" 
                ? 
                  <tr className="form-group" >
                    <td>
                      <label className="control-label">
                        Last Menstruation Date: <span style={{ fontWeight: 'bold', marginLeft: "45px" }}>{d.last_menstruation_date}</span>
                      </label>
                    </td>
                  </tr>
                :
                  ''
              }
              {d.menstrual_cycle !== "undefined" && d.last_menstruation_date !== "undefined"
                ? 
                  <tr className="form-group">
                    <td>
                      <label className="control-label">Menstrual Cycle: <span style={{ fontWeight: 'bold', marginLeft: "83px" }}>{d.menstrual_cycle}</span></label>
                    </td>
                  </tr>
                :
                  ''
              }
              {d.menstrual_cycle !== "undefined" && d.menstrual_cycle === "Irregular"
                ? 
                  <tr className="form-group">
                    <td>
                      <label className="control-label">PCOS Diagnosed: <span style={{ fontWeight: 'bold', marginLeft: "85px" }}>{d.pcos_value}</span></label>
                    </td>
                  </tr>
                :
                  ''
              }
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <table className="self-medical">
            <tbody>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_bloodTransfusion"
                    disabled={`${d.medHISTORY_bloodTransfusion !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_bloodTransfusion !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Blood Transfusion</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_bowelDisease"
                    disabled={`${d.medHISTORY_bowelDisease !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_bowelDisease !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Bowel Disease</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_depresion"
                    disabled={`${d.medHISTORY_depresion !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_depresion !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Depression</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_diabetes"
                    disabled={`${d.medHISTORY_diabetes !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_diabetes !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Diabetes</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_hearing_impairment"
                    disabled={`${d.medHISTORY_hearing_impairment !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_hearing_impairment !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Hearing Impairment</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_heartAttack"
                    disabled={`${d.medHISTORY_heartAttack !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_heartAttack !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Heart Attack</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_heartPain"
                    disabled={`${d.medHISTORY_heartPain !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_heartPain !== undefined ? true : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Heart Pain / Angina</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_hepatitisA"
                    disabled={`${d.medHISTORY_hepatitisA !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_hepatitisA !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Hepatitis A</label>
                </td>
              </tr>
              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_hepatitisB"
                    disabled={`${d.medHISTORY_hepatitisB !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_hepatitisB !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Hepatitis B</label>
                </td>
              </tr>

              <tr className="form-group">
                <td>
                  <input
                    type="checkbox"
                    name="medHISTORY_hepatitisC"
                    disabled={`${d.medHISTORY_hepatitisC !== undefined ? `disabled` : `readonly`}`}
                    checked={`${d.medHISTORY_hepatitisC !== undefined ? `checked` : ``}`}
                    className="form-control inline-chkbox "
                  />
                  <label className="control-label">Hepatitis C</label>
                </td>
              </tr>
           
              
            </tbody>
          </table>
        </div>
      </div>
      {d.menstrual_cycle_length ? (
        <>
          <h2>Menstrual Cycle</h2>
          <div className="row mt-3">
            <div className="col-md-6">
              <table className="self-medical">
                <tbody>
                  <tr className="form-group">
                    <td width="400">Period Length:</td>
                    <td className="font-weight-bold text-uppercase">{d.menstrual_period_length}</td>
                  </tr>
                  <tr className="form-group">
                    <td width="400">Cycle Length:</td>
                    <td className="font-weight-bold text-uppercase">{d.menstrual_cycle_length}</td>
                  </tr>
                  <tr className="form-group">
                    <td width="400">First Day of Period:</td>
                    <td className="font-weight-bold text-uppercase">{d.menstrual_first_day}</td>
                  </tr>
                  <tr className="form-group">
                    <td width="400">Last day of your Menstrual period:</td>
                    <td className="font-weight-bold text-uppercase">{d.menstrual_last_day}</td>
                  </tr>
                  <tr className="form-group">
                    <td width="400">I get my period regularly every month? :</td>
                    <td className="font-weight-bold text-uppercase">{d.menstrual_regular}</td>
                  </tr>
                  <tr className="form-group">
                    <td width="400">Do you usually experience period pain? :</td>
                    <td className="font-weight-bold text-uppercase">{d.menstrual_experience_pain}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      <br />
      <br />
      {d.medical_agree_no_history ? (
        <table>
          <tbody>
            <tr className="form-group" key="medical_agree_no_history">
              <td>
                <label className="control-label">
                  Agreed - I hereby declare that I do not have any related medical history, hospitalization, known
                  surgery and medication from any of above stated childhood illnesses
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        ''
      )}
      <br />
      <br />
    </div>
  )
}
export default HistorySelf
