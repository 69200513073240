import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

export const EditContactDialog = ({ open, onClose, handleDialogSaveText }) => {
  const [error, setError] = useState(null);
  const [value, setValue] = useState('')
  const handleChange = (event) => {
    const newValue = event.target.value;
    const newError = newValue.length > 15 ? 'Text is too long, exceeds 25 character limit' : null;
    setError(newError);
    setValue(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Field</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Field name"
          type="text"
          fullWidth
          value={value}
          onChange={handleChange}
          error={Boolean(error)}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => (
          handleDialogSaveText(value), setValue(''), onClose()
        )} 
        disabled={Boolean(error) || !value}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

