import {useLottie} from 'lottie-react'
import React from 'react'
import 'style/_preloader.scss'
import loader from './loader.json'
const Loader = () => {
  const options = {
    animationData: loader,
    loop: true,
    autoplay: true,
  }

  const {View} = useLottie(options)

  return (
    <div className="preloader">
      <div className="preloader-container">
        {View}
        <h2>...Oops Data still floating</h2>
      </div>
    </div>
  )
}

export default Loader
