import React from 'react'
import {Box} from '@mui/material'
import {styled} from '@mui/material/styles'
import { Helmet } from 'react-helmet';
// Components
import AboutHero from './aboutHero'
import AboutCenterText from './aboutCenterText'
import AboutContents from './aboutVisionMission'
import AboutBanner from './aboutBanner'
import AboutServices from './aboutServices'

const ContentStyle = styled('div')(({theme}) => ({
  overflow: 'visible',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}))

function MetaTags({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

export default function AboutPage({ metaData, aboutPageData}) {
  return (
    <>
    <MetaTags title={metaData.title} description={metaData.description} keywords={metaData.keywords} />
    <Box id="move_top">
      <ContentStyle>
        <AboutHero data={aboutPageData?.aboutHero} />
        <AboutCenterText data={aboutPageData?.aboutCenterText} />
        <AboutContents data={aboutPageData?.aboutContents} />
        <AboutBanner data={aboutPageData?.aboutBanner} />
        <AboutServices data={aboutPageData?.aboutServices} />
      </ContentStyle>
    </Box>
    </>
  )
}