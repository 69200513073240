import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Box,
    IconButton,
    Divider,
    Grid,
    Popover,
    FormHelperText
} from '@mui/material'
import { Icon } from '@iconify/react';
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import SecureLS from 'secure-ls'
import AddFieldDialog from './dialog/AddFieldDialog'
import EditFieldDialog from './dialog/EditFieldDialog';

const ls = new SecureLS({encodingType: 'aes'})

const DynamicForm = ({ activeStep, onSubmit, lastStep, updateFields, handleBack }) => {
  const context = useContext(EditModeContext);
  const [formLayout, setFormLayout] = useState([]);
  const { isEditMode } = context || {};
  const [addFieldDialogOpen, setAddFieldDialogOpen] = useState(null);
  const [editFieldDialogOpen, setEditFieldDialogOpen] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const popupRef = useRef(null);

  useEffect(() => {
    setFormLayout(activeStep.formLayout)
  }, [activeStep]);

  const handlePopupOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    popupRef.current = index;
  };

  const handlePopupClose = () => {
    setAnchorEl(null);
    popupRef.current = null;
  };

  const openPopup = Boolean(anchorEl);
  const popupId = openPopup ? 'simple-popover' : undefined;

  const handleAddFields = (field, index) => {
    let updatedFormLayout;
    const addedFormLayout = { ...field, id: formLayout.length };
    if (index === formLayout.length) {
      updatedFormLayout = [...formLayout, addedFormLayout];
    } else {
      updatedFormLayout = [...formLayout.slice(0, index), addedFormLayout, ...formLayout.slice(index)];
    }
    
    updateFields(updatedFormLayout)
    setFormLayout(updatedFormLayout)
  }

  const handleEditFields = (field, index) => {
    const updatedFormLayout = [...formLayout];
    updatedFormLayout[index] = field;
    setFormLayout(updatedFormLayout)
    updateFields(updatedFormLayout)
  }

  const handleRemoveFields = (index) => {
    const updatedFormLayout = formLayout.filter((field, idx) => idx !== index);
    setFormLayout(updatedFormLayout)
    updateFields(updatedFormLayout)
    setAnchorEl(null);
  }

  const handleFieldChange = (id, value) => {
    setFormLayout(
      formLayout.map((field) => {
        if (field.id === id) {
          const errors = validateField({...field, value});
          return {
            ...field,
            value,
            error: errors.length > 0,
            helperText: errors.join(', ')
          };
        }
        return field;
      })
    );
  }

  const handleSubmit = () => {
    onSubmit()
    // Here you would typically send the data to your backend
  }

  const validateField = (field) => {
    const errors = [];
    const value = field.value;
  
    if (field.validations.required && !value) {
      errors.push('This field is required');
    }
  
    if (value) {
      if (field.validations?.minLength && value.length < field.validations?.minLength) {
        errors.push(`Minimum length is ${field.validations.minLength}`);
      }
  
      if (field.validations?.maxLength && value.length > field.validations?.maxLength) {
        errors.push(`Maximum length is ${field.validations.maxLength}`);
      }
  
      if (field.validations?.pattern && !new RegExp(field.validations?.pattern).test(value)) {
        errors.push('Invalid format');
      }
  
      if (field.validations?.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        errors.push('Invalid email format');
      }
  
      if (field.validations?.numeric && !/^\d+$/.test(value)) {
        errors.push('Must be numeric');
      }
    }
  
    return errors;
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'text':
        return (
          <TextField
            fullWidth
            label={field.label}
            value={field.value}
            onChange={(e) => handleFieldChange(field.id, e.target.value)}
            required={field.validations?.required}
            error={field?.error}
            helperText={field?.helperText}
            inputProps={{
              maxLength: field.validations?.maxLength,
              minLength: field.validations?.minLength,
              pattern: field.validations?.pattern
            }}
          />
        )
      case 'label':
        return <Typography variant="body1">{field.label}</Typography>
      case 'select':
        return (
          <FormControl fullWidth error={field?.error} required={field.validations.required}>
            <InputLabel>{field.label}</InputLabel>
            <Select
              value={field.value}
              label={field.label}
              onChange={(e) => handleFieldChange(field.id, e.target.value)}
            >
              {field.options?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {field?.helperText && <FormHelperText>{field?.helperText}</FormHelperText>}
          </FormControl>
        )
      case 'image':
        return (
          <Box>
            <input
              accept={field.accept || "image/*"}
              style={{ display: 'none' }}
              id={`image-upload-${field.id}`}
              type="file"
              onChange={(e) => {
                const file = e.target.files?.[0]
                if (file) {
                  if (field.validations?.maxSize && file.size > field.validations?.maxSize) {
                    handleFieldChange(field.id, null)
                    return
                  }
                  handleFieldChange(field.id, URL.createObjectURL(file))
                }
              }}
              required={field.validations.required}
            />
            <label htmlFor={`image-upload-${field.id}`}>
              <Button variant="contained" component="span">
                Upload
              </Button>
            </label>
            {field?.helperText && <FormHelperText error={field?.error}>{field?.helperText}</FormHelperText>}
            {field.value && (
              <Box mt={2}>
                <img src={field.value} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '200px' }} />
              </Box>
            )}
          </Box>
        )
      case 'divider':
        return <Divider />
      case 'divider-label':
        return <Divider> {field.label} </Divider>
      default:
        return !!isEditMode ? (<Divider>Next Row</Divider>) : null
    }
  }

  const handleAddFieldDialogClose = () => {
    setAddFieldDialogOpen(null);
  };

  const handleAddFieldDialogOpen = (index) => {
    setAddFieldDialogOpen(index);
    setAnchorEl(null);
  };

  const handleEditFieldDialogOpen = (index) => {
    setEditFieldDialogOpen(formLayout[index]);
  }

  const handleEditFieldDialogClose = () => {
    setEditFieldDialogOpen(null);
  }

  const handleMoveFieldToRight = (index) => {
    const updatedFormLayout = [...formLayout];
    const stepToMove = updatedFormLayout[index];
    const nextIndex = (index + 1) % updatedFormLayout.length;
    
    updatedFormLayout[index] = updatedFormLayout[nextIndex];
    updatedFormLayout[nextIndex] = stepToMove;
    
    setFormLayout(updatedFormLayout);
    updateFields(updatedFormLayout);
    setAnchorEl(null);
  }

  return (
    <Box>
        <AddFieldDialog open={addFieldDialogOpen} onClose={handleAddFieldDialogClose} onSubmit={handleAddFields} />
        <EditFieldDialog field={editFieldDialogOpen} fieldIndex={popupRef.current} onClose={handleEditFieldDialogClose} onSubmit={handleEditFields} />
        <form onSubmit={handleSubmit}>
        <Grid container >
            {formLayout.map((field, index) => (
              field.type === 'divider' || field.type === 'divider-label' || field.type === 'next-row' ? (
                <Grid item xs={12} key={field.id} sx={{ mb: field.type === 'next-row' ? 2 : undefined, my: field.type !== 'next-row' ? 2 : undefined }}>
                   <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                      {renderField(field)}
                    </Box>
                    {!!isEditMode && (
                      <Box ml={2}>
                        <IconButton onClick={(e) => handlePopupOpen(e, index)}>
                          <Icon icon="mdi:dots-vertical" />
                        </IconButton>
                        <Popover
                          id={popupId}
                          open={openPopup}
                          anchorEl={anchorEl}
                          onClose={handlePopupClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Box display={"flex"} flexDirection={"column"} alignItems="flex-start">
                            <Button startIcon={<Icon icon="mdi:close" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleRemoveFields(popupRef.current)} color="error">
                              Remove Field
                            </Button>
                            <Button startIcon={<Icon icon="mdi:plus" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleAddFieldDialogOpen(popupRef.current)} color="primary">
                              Add Field After
                            </Button>
                            <Button startIcon={<Icon icon="mdi:pencil" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleEditFieldDialogOpen(popupRef.current)} color="primary">
                              Edit Field
                            </Button>
                            <Button startIcon={<Icon icon="formkit:arrowright" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleMoveFieldToRight(popupRef.current)} color="primary">
                              Move Field To Right
                            </Button>
                          </Box>
                        </Popover>
                      </Box>
                    )}
                    </Box>
                </Grid>
              ) : (
                <Box key={field.id} display="flex" alignItems="center" mr={2} mb={2}>
                  <Box flexGrow={1}>
                    {renderField(field)}
                  </Box>
                  {!!isEditMode && (
                    <Box>
                      <IconButton onClick={(e) => handlePopupOpen(e, index)}>
                        <Icon icon="mdi:dots-vertical" />
                      </IconButton>
                      <Popover
                        id={popupId}
                        open={openPopup}
                        anchorEl={anchorEl}
                        onClose={handlePopupClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <Box display={"flex"} flexDirection={"column"} alignItems="flex-start">
                          <Button startIcon={<Icon icon="mdi:close" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleRemoveFields(popupRef.current)} color="error">
                            Remove Field
                          </Button>
                          <Button startIcon={<Icon icon="mdi:pencil" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleEditFieldDialogOpen(popupRef.current)} color="primary">
                            Edit Field
                          </Button>
                          <Button startIcon={<Icon icon="mdi:plus" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleAddFieldDialogOpen(popupRef.current)} color="primary">
                            Add Field After
                          </Button>
                          <Button startIcon={<Icon icon="formkit:arrowright" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleMoveFieldToRight(popupRef.current)} color="primary">
                            Move Field To Right
                          </Button>
                        </Box>
                      </Popover>
                    </Box>
                  )}
                </Box>
              )
            ))}
            </Grid>
            <Box display="flex" flexDirection={'column'} justifyContent={'center'}>
                { formLayout.length === 0 && (
                  <Typography align="center">No field/s added.</Typography>
                )}
                {!!isEditMode && (
                  <IconButton onClick={() => handleAddFieldDialogOpen(formLayout.length - 1)} variant="contained">
                    <Icon icon="mdi:plus" />
                  </IconButton>
                )}
                <Box display="flex" mt={2} justifyContent="space-between">
                    <Button variant="contained" color="primary" onClick={handleBack}>
                        Back
                    </Button>  
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                        { lastStep ? 'Submit' : 'Next'}
                    </Button>
                </Box> 
            </Box>
        </form>
    </Box>
  );
};

export default DynamicForm;
